import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Avatar, Button,  Form, Input, message, Select} from "antd";
import api from "../../modules/api/request";
import PdfWorkplace from "../PDF/PdfWorkplace";
const {Option} = Select;
const format = 'MMM Do YYYY';

class EquipmentCreate extends React.Component   {

    state = model(this, {
        loading: false,
        structure: [],
        form: {
            code: {validate: 'required|max:191'},
            name: {validate: 'required|max:191'},
            type: {type: 'select', validate: 'required'},
            price1: {},
            price2: {},
            price3: {},
            price4: {},
            notes: {}
        }
    });

    onSubmit = (form, model) => {
        form["structure"] = this.state.structure;
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.equipmentInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.drawerChanged('equipment');
            t.props.toggleDrawer({drawer: 'equipment'});

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="equipmentDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Code" validateStatus={form.code.error && 'error'} help={form.code.error}>
                        <Input maxLength={191} type="text" {...form.code} style={{width: 160}} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Type">
                        <Select {...form.type} allowClear={true} placeholder="Select type" style={{width: 160}} getPopupContainer={trigger => document.getElementById('equipmentDrawerForm')}>
                            {this.props.types.map((item, index) => {
                                return <Option key={item.id} value={item.id}> {item.name} </Option>
                            })}
                        </Select>
                    </Form.Item>

                    {[1,2,3,4].map((item) => {
                        let name = "price" + item;
                        return  <Form.Item label={"Price "+item} key={item}
                                   validateStatus={form[name].error && 'error'}
                                    help={form[name].error}>
                            <Input type="text" {...form[name]} style={{width: 160}} autoComplete="off" />
                        </Form.Item>
                    })}

                </div>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable />
            </Form>

            <PdfWorkplace
                structure={this.state.structure}
                callback={(structure) => this.stateSet({'structure': structure})} />

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="equipmentDrawerForm"
                        loading={this.state.loading}> Save equipment </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'equipment'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentCreate);
