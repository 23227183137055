import React from 'react';
import {connect} from "react-redux";
import {toggleDrawer} from "../../store/actions/drawersActions";
import model from "../../modules/model/bind";
import {Button, Drawer, TimePicker, Select, Skeleton, message} from "antd";
import api from "../../modules/api/request";
import moment from "moment";
import update from "react-addons-update";
const {Option} = Select;

class SettingsDrawer extends React.Component   {

    state = model(this, {
        loading: false,
        status: false,
        jobs: []

    });

    change = (index, value) => {
        this.stateSet({['jobs.'+index+'.hour']: value});
        var item = this.state.jobs[index];
        api.post(api.routes.cronJobUpdate, {id: item.id, hour: value}).then(function (response) {
            message.success(`Time updated.`);
        })
    };

    delete = (index, id) => {
        if(!id)     {
            const newData = update(this.state, {jobs: {$splice: [[index, 1]] }});
            this.setState(newData);
            return;
        }
        let t = this;
        api.delete(api.routes.cronJobDelete, {data: {id: id}}).then(function (response) {
            message.success(`Deleted.`);
            const newData = update(t.state, {jobs: {$splice: [[index, 1]] }});
            t.setState(newData);
        })

    };

    addJob = () => {
        const newData = update(this.state, {jobs: {$push: [{id: '', hour: ''}] }});
        this.setState(newData);
    };

    render = () => {
        return <>
            <Button onClick={() => this.props.toggleDrawer({drawer: 'settings'})} icon="setting" theme="filled" size="large"/>
            &nbsp;
            <Drawer
                visible={this.state.status}
                width="50%"
                zIndex={980}
                title="SETTINGS"
                placement="right"
                closable={true}
                onClose={() => this.props.toggleDrawer({drawer: 'settings'})}>

                {this.state.loading
                    ? <Skeleton active />
                    : <div>
                        <h4> Email me reminders every day at: </h4>
                        {this.state.jobs.map((item, index) => {
                            return <div className="mb_15" key={index}>{index+1}. &nbsp;
                                <Select style={{width: 100}} value={item.hour} onChange={(val) => this.change(index, val)}>
                                {[
                                    {h: 1, v: '01:00'},{h: 2, v: '02:00'},{h: 3, v: '03:00'},{h: 4, v: '04:00'},{h: 5, v: '05:00'},
                                    {h: 6, v: '06:00'},{h: 7, v: '07:00'},{h: 8, v: '08:00'},{h: 9, v: '09:00'},{h: 10, v: '10:00'},
                                    {h: 11, v: '11:00'},{h: 12, v: '12:00'},{h: 13, v: '13:00'},{h: 14, v: '14:00'},{h: 15, v: '15:00'},
                                    {h: 16, v: '16:00'},{h: 17, v: '17:00'},{h: 18, v: '18:00'},{h: 19, v: '19:00'},{h: 20, v: '20:00'},
                                    {h: 21, v: '21:00'},{h: 22, v: '22:00'},{h: 23, v: '23:00'},{h: 0, v: '00:00'}
                                ].map((hour) => {
                                    return <Option value={hour.h} key={hour.h}> {hour.v} </Option>
                                })}
                            </Select> <Button onClick={() => this.delete(index, item.id)} icon="delete" /></div>
                        })}
                        <Button className="strong" onClick={this.addJob}> Add time </Button>
                        <div className="mt_15"><i>will be emailed to: {this.props.user.uemail} </i></div>
                    </div>
                }


            </Drawer>
        </>
    };

    load = () => {
        this.stateSet({status: this.props.drawer.status});
        let t = this;
        api.get(api.routes.cronJobs).then(function (response) {
           console.log(response.data);
           t.stateSet({jobs: response.data});
        })
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.settings
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsDrawer);
