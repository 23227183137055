import React from 'react';
import {Button, Select, Skeleton} from "antd";
import {connect} from "react-redux";
import api from "../../modules/api/request";
import model from "../../modules/model/bind"
import EquipmentTable from "./EquipmentTable";
import update from "react-addons-update";
import {toggleDrawer} from "../../store/actions/drawersActions";
const { Option } = Select;


class Equipment extends React.Component {

    state = model(this, {
        loading: true,
        equipment: [],
        types: [],
        params: {
            type: undefined
        }
    });


    render = () => {
        return <div>
            <div className="dashboard_title">
                Equipment
            </div>

            {this.props.user.privileges.includes(702) &&
            <div className="text-right mb_15">
                <Button onClick={() => this.props.toggleDrawer({drawer: 'equipment'})} className="success" icon="plus">
                    ADD NEW
                </Button>
            </div>}

            <div className="dashboard_block">
                <div className="m_15">
                    <Select placeholder="Type" style={{ width: 200 }} allowClear={true}
                            onChange={(val) => this.loadEquipment(val)} value={this.state.params.type}>
                        <Option value={undefined}> All </Option>
                        {this.state.types.map((type, index) => {
                            return <Option key={type.id} value={type.id}> {type.name} </Option>
                        })}
                    </Select>
                    &nbsp;
                </div>

                {this.state.loading
                    ?  <div className="m_15"><Skeleton active /></div>
                    : <EquipmentTable data={this.state.equipment} />
                }
            </div>
        </div>
    };

    loadEquipment = (type=false) => {
        let t = this;
        let params = this.state.params;
        if(!type)      {
            t.stateSet({loading: true});
        }
        else        {
            const newData = update(this.state, {params: {type: {$set: [type]}}, loading: {$set: true} });
            this.setState(newData);
            params = newData.params;
        }
        api.get(api.routes.equipment, {params: params}).then(function (response) {
            t.stateSet({loading: false, equipment: response.data});
        });
    };

    componentDidMount() {
        this.loadEquipment();
        let t = this;
        api.get(api.routes.equipmentTypes).then(function (response) {
            t.stateSet({types: response.data});
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.loadEquipment();
        }
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.equipment
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Equipment);
