import React from 'react';
import {Button, Modal, message, Form, Select, Input, Alert, Drawer} from "antd";
import update from "react-addons-update";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import folder from "../../assets/images/folder.png";
import api from "../../modules/api/request";
const {Option} = Select;

class FilesMove extends React.Component {

    state = model(this, {
        currentFolder: 1,
        folders: [],
        status: false,
        loading: false,
    });

    move = () => {
        let t = this;
        var files = this.props.selected.map((item) => {
            return item.id;
        });
        t.stateSet({loading: true});
        api.post(api.routes.filesMove, {folder: this.state.currentFolder, files: files}).then(function (response) {
            message.success(`Files moved.`);
            t.props.drawerChanged('files');
            t.stateSet({loading: false, status: false});
        });
    };

    render = () => {
        return <>
            <Button className="strong mb_5" disabled={this.props.selected.length === 0} icon="drag" onClick={() => this.stateSet({status: true})}>
            move
        </Button>
            <Modal
                title="Move files"
                zIndex={1102}
                visible={this.state.status}
                onCancel={() => this.stateSet({status: false})}>

                <Form.Item label="Move to folder">
                    <Select placeholder="Select folder" style={{width: '100%'}} value={this.state.currentFolder}
                            onChange={(value) => this.stateSet({currentFolder: value})}>
                        {this.state.folders.map((item, index) => {
                            return <Option key={index} value={item.id}> {item.name} </Option>
                        })}
                    </Select>
                </Form.Item>

                <Button className="strong" onClick={this.move} loading={this.state.loading}>Move</Button>
            </Modal>
            </>
    };
    componentDidMount() {
        let t = this;
        api.get(api.routes.filesFoldersAll).then(function (response) {
            t.stateSet({ folders: response.data});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.folder !== prevProps.folder)       {
            this.stateSet({currentFolder: this.props.folder});

        }
    }

}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(FilesMove);
