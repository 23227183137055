import React from 'react';
import { connect } from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import routes from "../routes";
import Layout from "../../../layout/Layout";

const Auth = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        rest.user === false
            ? <Redirect to={routes.login} />
            : <Layout {...{ component: Component, ...props }} />
    )} />
);

const mapStateToProps = state =>    ({
    user: state.user
});

export default connect(mapStateToProps)(Auth);
