import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import VideoShow from "./VideoShow";
import VideoCreate from "./VideoCreate";


class VideoDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        video: false,
        create: false,
    });

    render = () => {
        return  <Drawer
                    visible={this.state.status}
                    width="50%"
                    zIndex={997}
                    title="VIDEO"
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'video'})}>

            {!this.state.loading
                ? <div>
                    {this.state.video && <VideoShow video={this.state.video}/>}
                    {this.state.create && <VideoCreate />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.video + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, video: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, video: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, video: false, create: false});
        }
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.video
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoDrawer);
