import React from 'react';
import {connect} from "react-redux";
import model from "../../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar, {Alert, Button, Form, Input, message, Select} from "antd";
import PersonTreeSelect from "../../../drawers/partials/PersonTreeSelect";
import api from "../../../modules/api/request";
const {Option} = Select;

class DepartmentCreate extends React.Component   {

    state = model(this, {
        loading: false,
        form: {
            name: {validate: 'required|max:191'},
            parent: {type: 'select', default: 1},
            employees: {type: 'select', default: []},
        }
    });

    onSubmit = (form, model) => {
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.departmentInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.toggleDrawer({drawer: 'department'});
            t.props.drawerChanged('department');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 4 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="departmentsDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>
                </div>

                <Form.Item label="Parent Department">
                    <Select {...form.parent} getPopupContainer={trigger => document.getElementById('departmentsDrawerForm')}>
                        <Option value=""> Root </Option>
                        {this.props.departments.map((item) => {
                            return <Option key={item.id} value={item.id}> {item.name} </Option>
                        })}
                    </Select>
                </Form.Item>


                <Form.Item label="Employees">
                    <PersonTreeSelect field={form.employees} parent="departmentsDrawerForm" />
                </Form.Item>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable/>
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="departmentsDrawerForm" loading={this.state.loading}> Save department </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'project'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user,
    departments: state.departments
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentCreate);
