import React from 'react';
import {Button, message, Modal, Select} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import EditField from "../partials/EditField";
import Avatar from "../../layout/Avatar";

const { confirm } = Modal;
const { Option } = Select;

class CountryShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit
    });

    changeFlag = (e) => {
        e.preventDefault();
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                var file = files[0];
                this.save('flag', file.id);
            }});
    };

    changeRegion = (code) => {
        var name = '';
        this.props.regions.map((item, index) => {
            if(item.code === code)     {
                name = item.name;
            }
        });
        this.save('region', name);
        this.save('region_code', code);
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.countryUpdate, {id: this.props.country.id, field: field, value: value}).then(function (response) {
            message.success(`Country updated.`);
            t.props.drawerChanged('country');
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this country?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.countryDelete, {data: {id: t.props.country.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Country deleted.`);
                        t.props.toggleDrawer({drawer: 'country'});
                        t.props.drawerChanged('country');
                    });

                });
            },
            onCancel() {},
        });
    };

    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    render = () => {
        let country = this.props.country;
        var edit = this.props.user.privileges.includes(408) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT COUNTRY'}
                </Button>
            </div>

            <div style={{position: 'relative'}} id="countryDrawerShow">
                <div className="marginless row">
                    <div style={{width: 110}}>
                        {edit
                            ? <div>
                                <a href="/" onClick={this.changeFlag}>
                                    <Avatar name={country.name} avatar={country.flag_name} size={80} />
                                </a>
                                <i>Click to change</i>
                            </div>
                            : <Avatar name={country.name} avatar={country.flag_name} size={80} />}
                    </div>
                    <div className="col">
                        <EditField
                            title="Name"
                            edit={edit}
                            form={{validate: "required|max:191", default: country.name}}
                            field="input"
                            onSubmit={(value) => this.save('name', value)}
                            show={<h4>{country.name}</h4>} />

                        <EditField
                            title="General info"
                            edit={edit}
                            form={{validate: "max:191", default: country.info}}
                            field="input"
                            onSubmit={(value) => this.save('info', value)}
                            show={<h4>
                                {country.info ? <a href={country.info} target="_blank"> {country.info} </a> : <i> not set</i>}
                            </h4>} />

                        <EditField
                            title="Region"
                            edit={edit}
                            form={{type: "select", default: country.region}}
                            field="select"
                            idField="code"
                            options={this.props.regions}
                            onSubmit={(value) => this.changeRegion(value)}
                            parent="countryDrawerShow"
                            show={this.notSet(country.region)} />

                        <EditField
                            title="Language"
                            edit={edit}
                            form={{validate: "max:191", default: country.language}}
                            field="input"
                            onSubmit={(value) => this.save('language', value)}
                            show={this.notSet(country.language)} />

                        <EditField
                            title="Currency"
                            edit={edit}
                            form={{validate: "max:191", default: country.currency}}
                            field="input"
                            onSubmit={(value) => this.save('currency', value)}
                            show={this.notSet(country.currency)} />

                        <EditField
                            title="Dialing code"
                            edit={edit}
                            form={{validate: "max:191", default: country.dial}}
                            field="input"
                            onSubmit={(value) => this.save('dial', value)}
                            show={this.notSet(country.dial)} />

                        <EditField
                            title="Driving side"
                            edit={edit}
                            form={{type: "select", default: country.drive}}
                            field="select"
                            options={['right', 'left']}
                            onSubmit={(value) => this.save('drive', value)}
                            parent="countryDrawerShow"
                            show={this.notSet(country.drive)} />

                        <EditField
                            title="Time zone"
                            edit={edit}
                            form={{type: "select", default: country.timezone}}
                            field="select"
                            options={this.props.timezones}
                            onSubmit={(value) => this.save('timezone', value)}
                            parent="countryDrawerShow"
                            show={this.notSet(country.timezone)} />

                        <EditField
                            title="General notes"
                            edit={edit}
                            form={{default: country.notes}}
                            field="editor"
                            onSubmit={(value) => this.save('notes', value)}
                            show={<div className="light_bcg p_15" dangerouslySetInnerHTML={{__html: country.notes}}/>} />
                    </div>
                </div>
            </div>
            <div className="drawer_footer">
                {edit && <><Button className="strong" onClick={this.delete}>Delete country</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'country'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(CountryShow);
