import React from "react";
import {Button, Dropdown, Menu, Empty} from "antd";
import {Link} from "react-router-dom";

class TemplatesDropdown extends React.Component {

    state = {
        templates: []
    };

    render = () => {
        return (
            <Dropdown placement="bottomRight" overlay={
                <Menu>
                    {this.state.templates.map((item, index) => {
                        return <Menu.Item key={item.id}>
                            <Button type="link" size="small">
                                {item.name}
                            </Button>
                        </Menu.Item>
                    })}
                    <Menu.Divider/>
                    <Menu.Item><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Menu.Item>
                </Menu>
            } trigger={['click']}>
                <Button icon="down"><strong> TEMPLATES </strong></Button>
            </Dropdown>
        );
    }
}

export default TemplatesDropdown;