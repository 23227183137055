import React from 'react';
import {Input, Select, Table} from "antd";
import Avatar from "../../../layout/Avatar";
import model from "../../../modules/model/bind"
import {connect} from "react-redux";
import {toggleDrawer} from "../../../store/actions/drawersActions";
const { Option } = Select;

class EmployeesTable extends React.Component {

    state = model(this, {
        form: {
            search: {},
            active: {value: 'all', type: 'select'}
        }
    });

    columns = [
        {title: 'Image', dataIndex: 'avatar', key: 'avatar',
            render: (text, record) => <Avatar avatar={record.avatar_name} name={record.name} size="large" />},

        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => <a href="/" className="black"
                 onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: record.uid})}>{text}</a>},

        {title: 'Email', dataIndex: 'uemail', key: 'uemail'},
        {title: 'Position', dataIndex: 'position', key: 'position'},

        {title: 'Status', dataIndex: 'active', key: 'active',
            render: (text, record) => text === 1 ? <span className="green"><strong>Activated</strong></span> : <span className="red"><strong>Not Activated</strong></span>},

        {title: 'Settings', dataIndex: '', key: 'privileges',
            render: (values, record) => {
                if(!this.props.user.privileges.includes(303)) return false;
                return <div><a href="/" onClick={(e) => this.props.eStateSetParent(e, {uid: record.uid})}> Edit </a></div>
            }
        }
    ];

    filtered = () => {
        return this.props.data.filter((item) => {
            var filters = this.state.form;
            if(filters.search.value)       {
                if(!item.name.toLowerCase().includes(filters.search.value.toLowerCase()))        {
                    return false;
                }
            }
            if(filters.active.value && filters.active.value !== 'all')       {
                var status = filters.active.value === 'activated' ? 1 : null;
                if(item.active !== status)        {
                    return false;
                }
            }
            return true;
        });
    };

    render = () => {
        return <div>
            <div className="m_15">
                <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="search by name" />
                &nbsp; Status: &nbsp;
                <Select {...this.state.form.active} style={{ width: 140 }}>
                    <Option value="all">All</Option>
                    <Option value="activated">Activated</Option>
                    <Option value="not activated">Not Activated</Option>
                </Select>
            </div>

            <Table
                className="hide_3 hide_4"
                dataSource={this.filtered()}
                pagination={{pageSize: 50}}
                columns={this.columns}
                size="small"
                rowKey="uid" />
        </div>
    };

}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeesTable);
