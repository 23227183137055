import React from 'react';
import {connect} from "react-redux";
import {toggleDrawer} from "../../store/actions/drawersActions";
import model from "../../modules/model/bind";
import {Badge, Button, Drawer, message, Tooltip} from "antd";
import api from "../../modules/api/request";
import moment from "moment";
import Avatar from "../../layout/Avatar";
import update from "react-addons-update";

class NotificationsDrawer extends React.Component   {

    state = model(this, {
        status: false,
        seen: [],
        unseen: []
    });

    delete = (id) => {
        let t = this;
        api.delete(api.routes.notificationsDelete, {data: {id: id}}).then(function (response) {
            t.state.seen.map((item, index) => {
                if(item.id === id)  {
                    const newData = update(t.state, {seen: {$splice: [[index, 1]] }});
                    t.setState(newData);
                }
            });
            t.state.unseen.map((item, index) => {
                if(item.id === id)  {
                    const newData = update(t.state, {unseen: {$splice: [[index, 1]] }});
                    t.setState(newData);
                }
            });
            message.success(`Notification deleted.`);
        });
    };

    render = () => {
        return <>
            <Badge count={this.state.unseen.length} size="small">
                <Button onClick={() => this.props.toggleDrawer({drawer: 'notifications'})} icon="bell" theme="filled" size="large"/>
            </Badge>
            <Drawer
                visible={this.state.status}
                width="50%"
                zIndex={990}
                title="NOTIFICATIONS"
                placement="right"
                closable={true}
                onClose={() => this.props.toggleDrawer({drawer: 'notifications'})}>

                {[...this.state.seen, ...this.state.unseen].length > 0
                    ? <div>
                        {[...this.state.unseen, ...this.state.seen].map((item) => {
                            return  <div key={item.id} className={!item.seen ? 'light_bcg ' : ''}>
                                <div className="border mb_15 p_15">
                                    <div className="mb_5">
                                        <Tooltip title="Delete">
                                            <Button className="pull-right" onClick={(e) => this.delete(item.id)} icon="delete" />
                                        </Tooltip>
                                        <a href="/" className="black"
                                           onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: item.sender_uid})}>
                                            <Avatar name={item.sender_name} avatar={item.sender_avatar} size="small"/>
                                            {item.sender_name}
                                        </a>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: item.content}} />
                                    {item.project_id &&
                                    <div className="mb_5">
                                        <a href="/" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'project', project: item.project_id})}>
                                            <strong>{item.project_name}</strong>
                                        </a>
                                    </div>
                                    }
                                    {item.task_id &&
                                    <div className="mb_5">
                                        <a href="/" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'task', task: item.task_id})}>
                                            <strong>{item.task_name}</strong>
                                        </a>
                                    </div>
                                    }
                                    <div>
                                        <Tooltip title={moment.utc(item.created_at).format('MMM Do YYYY HH:mm')}>
                                            {moment.utc(item.created_at).fromNow()}
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    : <div className="light_bcg p_15"><i> No notifications. </i></div>
                }
            </Drawer>
            </>
    };

    load = () => {
        let t = this;
        api.get(api.routes.notifications).then(function (response) {
           console.log(response.data);
            var seen = [];
            var unseen = [];
            response.data.map((item) => {
                if(item.seen)   seen.push(item);
                else            unseen.push(item);
            });
            t.stateSet({seen: seen, unseen: unseen});
        })
    };

    puller = () =>    {
        let t = this;
        window.setInterval(function()   {
          t.load();
        }, 30000);
    };

    componentDidMount() {
        this.load();
        this.puller();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.stateSet({status: this.props.drawer.status});
            if(!this.props.drawer.status && this.state.unseen.length > 0)   {
                let t = this;
                api.post(api.routes.notificationsSeen).then(function (response) {
                    t.stateSet({unseen: [], seen: [...t.state.unseen, ...t.state.seen]});
                });
            }
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.notifications
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDrawer);
