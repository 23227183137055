import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import ReportCreate from "./ReportCreate";
import ReportShow from "./ReportShow";


class ReportDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        report: false,
        create: false,
    });

    render = () => {
        return  <Drawer
                    visible={this.state.status}
                    width={this.state.create ? "80%" : "50%"}
                    zIndex={997}
                    title="REPORT"
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'report'})}>

            {!this.state.loading
                ? <div>
                    {this.state.report && <ReportShow report={this.state.report}/>}
                    {this.state.create && <ReportCreate />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.report + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, report: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, report: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, report: false, create: false});
        }
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.report
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportDrawer);
