import Wall from '../views/wall/Wall'

export default [
    {
        id: 101,
        code: 101,
        page: "Wall",
        url: "/wall",
        icon: "pic-center",
        children: [],
        privileges: [
            {code: 101, title: "Access to WALL tab (reading comments)"},
            {code: 102, title: "Leaving comments on the wall"},
        ]
    },
    {
        id: 501,
        code: 501,
        page: "Assets",
        url: "/assets",
        icon: "file",
        children: [
            {id: 502, code: 502, page: "Files", url: "/files"},
            {id: 503, code: 503, page: "Videos", url: "/videos"},
        ],
        privileges: [
            {code: 501, title: "Access to DATA tab"},
            {code: 502, title: "Access to FILES tab"},
            {code: 503, title: "Access to VIDEOS tab"},
            {code: 504, title:  "Adding, editing, deleting videos"},
        ]
    },
    {
        id: 201,
        code: 201,
        page: "Project Manager",
        url: "/",
        icon: "form",
        children: [
            {id: 202, code:202, page: "Tasks", url: "/tasks"},
            {id: 204, code: 204, page: "Projects", url: "/projects"},
            {id: 205, page: "Calendar", url: "/calendar"},
        ],
        privileges: [
            {code: 201, title: "Access to PROJECT MANAGER tab"},
            {code: 202, title: "Access to TASKS tab"},
            {code: 203, title: "Creating new TASK"},
            {code: 204, title:  "Access to PROJECTS tab"},
            {code: 205, title: "Creating new PROJECT"},
            {code: 206, title: "Can create GLOBAL calendar REMINDERS"},
        ]
    },
    {
        id: 301,
        code: 301,
        page: "Company",
        url: "/",
        icon: "appstore",
        children: [
            {id: 302, code: 302, page: "Employees", url: "/employees"},
            {id: 304, code: 304, page: "Departments", url: "/departments"},
            {id: 306, code: 306, page: "Trip Reports", url: "/reports"},
        ],
        privileges: [
            {code: 301, title: "Access to COMPANY tab"},
            {code: 302, title: "Access to EMPLOYEES tab"},
            {code: 303, title: "Adding, editing, deleting employees"},
            {code: 304, title:  "Access to DEPARTMENTS tab"},
            {code: 305, title: "Adding, editing, deleting departments"},
            {code: 306, title:  "Access to TRIP REPORTS tab"},
            {code: 307, title:  "Adding, editing, deleting reports"},
        ]
    },
    {
        id: 401,
        code: 401,
        page: "CRM",
        url: "/crm",
        icon: "contacts",
        children: [
            {id: 403, code: 403, page: "Contacts", url: "/contacts"},
            {id: 404, code: 404, page: "Companies", url: "/companies"},
            {id: 405, code: 405, page: "Countries", url: "/countries"},
            {id: 409, code: 409, page: "Visits", url: "/visits"},
        ],
        privileges: [
            {code: 401, title: "Access to CRM tab"},
            {code: 403, title: "Access to CONTACTS tab"},
            {code: 404, title: "Access to COMPANIES tab"},
            {code: 405, title: "Access to COUNTRIES tab"},
            {code: 409, title: "Access to VISITS tab"},
            {code: 406, title: "Adding, editing, deleting contacts"},
            {code: 407, title: "Adding, editing, deleting company"},
            {code: 408, title: "Adding, editing, deleting country"},
            {code: 410, title: "Adding, editing, deleting visits"},

        ]
    },
    {
        id: 601,
        code: 601,
        page: "Sales",
        url: "/sales",
        icon: "snippets",
        children: [
            {id: 602, code: 602, page: "Offers", url: "/offers"},
            {id: 603, code: 603, page: "Qualifying offers", url: "/qualifying-offers"},
            {id: 604, code: 604, page: "Bonus", url: "/bonus"},
            {id: 605, code: 605, page: "Group total", url: "/group-total"},
        ],
        privileges: [
            {code: 601, title: "Access to SALES tab"},
            {code: 602, title: "Access to OFFERS tab"},
            {code: 603, title: "Access to QUALIFYING OFFERS tab"},
            {code: 606, title: "Creating NEW offer"},
        ]
    },
    {
        id: 701,
        code: 701,
        page: "Equipment",
        url: "/equipment",
        icon: "pic-center",
        children: [],
        privileges: [
            {code: 701, title: "Access to Equipment tab"},
            {code: 702, title: "Creating NEW Equipment"}
        ]
    },
];