import React from 'react';
import {connect} from 'react-redux';
import {Button, Drawer, Input, Select, Skeleton, Spin} from "antd";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import ContactsTable from "./ContactsTable";
import update from "react-addons-update";
const { Option } = Select;

class Contacts extends React.Component {

    state = model(this, {
        loading: true,
        status: false,
        data: [],
        sort: ["Latest", "First", "By Name", "By Lastname"],
        filters: {roles: [], countries: [], regions: []},
        params: {
            page: 1,
            search: "",
            role: undefined,
            region: undefined,
            country: undefined,
            sort: undefined
        }
    });

    filter = (field, value) => {
        this.stateSet({[field]: value});
        this.load();
    };

    render = () => {
        return <div>
            <div className="dashboard_title">
                CONTACTS
            </div>

            {this.props.user.privileges.includes(406) &&
            <div className="row mb_5">
                <div className="col-6">
                    <Button onClick={() => this.load(false, false, true)} className="success">
                        EXPORT SELECTED CONTACTS AS EXCEL
                    </Button>
                </div>
                <div className="col-6">
                    <div className="text-right mb_15">
                        <Button onClick={() => this.props.toggleDrawer({drawer: 'contact'})} className="success" icon="plus">
                            ADD CONTACT
                        </Button>
                    </div>
                </div>
            </div>}


            <div className="dashboard_block">
                <div className="m_15">
                    <Input size="small" style={{ width: 200 }} value={this.state.params.search}
                           onChange={(e) => this.load('search', e.target.value)} placeholder="Search by name" />
                    &nbsp;
                    <Select placeholder="Role" style={{ width: 160 }} allowClear={true}
                        onChange={(val) => this.load('role', val)}  value={this.state.params.role}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.roles.map((role, index) => {
                            return <Option key={index} value={role.name}> {role.name} ({role.count}) </Option>
                        })}
                    </Select>
                    &nbsp;
                    <Select placeholder="Region" style={{ width: 200 }} allowClear={true}
                        onChange={(val) => this.load('region', val)} value={this.state.params.region}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.regions.map((region, index) => {
                            return <Option key={index} value={region.name}> {region.name} ({region.count}) </Option>
                        })}
                    </Select>
                    &nbsp;
                    <Select placeholder="Country" style={{ width: 160 }} allowClear={true}
                            onChange={(val) => this.load('country', val)} value={this.state.params.country}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.countries.map((country, index) => {
                            return <Option key={index} value={country.name}> {country.name} ({country.count}) </Option>
                        })}
                    </Select>
                    &nbsp;
                    <Select placeholder="Sort" style={{ width: 160 }} allowClear={true}
                            onChange={(val) => this.load('sort', val)} value={this.state.params.sort}>
                        <Option value={undefined}>  </Option>
                        {this.state.sort.map((sort, index) => {
                            return <Option key={index} value={sort}> {sort} </Option>
                        })}
                    </Select>
                    &nbsp;
                </div>

                {this.state.loading
                    ? <div className="m_15"><Skeleton active={true} /></div>
                    : <ContactsTable changePage={(page) => this.load('page', page)} data={this.state.data} /> }
            </div>
        </div>
    };

    load = (search = false, value = false, excel = false) => {
        if(excel)       {
            params = this.state.params;
            var str = "";
            for (var key in params) {
                let val = params[key];
                if(!val) continue;
                if (str !== "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(params[key]);
            }
            str += "&excel=" + true;
            str += "&token=SUD89876kls8k2ojsdofj";
            window.location.href = api.routes.contacts + "?" + str;
            return false;
        }
        let t = this;
        var params;
        if(!search)     {
            t.stateSet({loading: true});
            params = this.state.params;
        }
        else    {
            if(value === false) value = undefined;
            var setParams;
            setParams = (search === 'page') ? {page: {$set: value}} : {[search]: {$set: value }, page: {$set: 1}};
            const newData = update(this.state, {params: setParams, loading: {$set: true} });
            this.setState(newData);
            params = newData.params;
        }
        api.get(api.routes.contacts, {params: params}).then(function (response) {
           t.stateSet({loading: false, status: t.props.drawer.status, data: response.data});
        });
    };

    filters = () => {
        let t = this;
        api.get(api.routes.contactsFilters).then(function (response) {
            t.stateSet({filters: response.data});
        });
    };

    componentDidMount() {
       this.load();
       this.filters();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.contact
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
