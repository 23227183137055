import React from 'react';
import {List, message, Modal} from "antd";
import Avatar from "../../layout/Avatar";
import Editor from "../../modules/editor/Editor";
import {connect} from "react-redux";
import api from "../../modules/api/request";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import moment from "moment";
import update from "react-addons-update";
const { confirm } = Modal;

class Comments2 extends React.Component {

    state = model(this, {
        comments: this.props.comments
    });

    deleteComment = (e, id, index, subindex) =>  {
        e.preventDefault();
        let t = this;
        confirm({
            title: 'Do you want to delete this comment?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.commentDelete,{data: {id: id, subject: t.props.subject}}).then(function (response) {
                        setTimeout(resolve, 10);
                        message.success(`Deleted.`);
                        if(subindex !== undefined)       {
                            const newData = update(t.state, {comments: {[index]: {subcomments: {$splice: [[subindex, 1]] }}}});
                            t.setState(newData);
                        }
                        else    {
                            const newData = update(t.state, {comments: {$splice: [[index, 1]] }});
                            t.setState(newData);
                        }
                    });
                });
            },
            onCancel() {},
        });
    };

    saveComment = (content, id) => {
        let t = this;
        api.post(api.routes.commentInsert, {parent: id, content: content, subject: this.props.subject, id: this.props.id}).then(function (response) {
            var comment = response.data;
            comment.user_name = t.props.user.name;
            comment.avatar_name = t.props.user.avatar_name;
            comment.user_uid = t.props.user.uid;
            comment.subcomments = [];
            if(!id)     {
                const newData = update(t.state,{comments: {$unshift: [response.data]}} );
                t.setState(newData);
            }
            else        {
                var index;
                t.state.comments.map((item, ind) => {
                   if(item.id === id)       {
                       index = ind;
                   }
                });
                const newData = update(t.state,{comments: {[index] : {subcomments: {$push: [response.data]}} }});
                t.setState(newData);
            }
            message.success(`Comment saved.`);
        });

    };

    subComment = (comments, index) => {
        if (comments.length === 0) {
            return false;
        }
        return <List itemLayout="vertical" size="small" dataSource={comments}
                     renderItem={(item, subindex) => (
                         <List.Item key={item.id}>
                             <div  className="subcomment_block">
                                 <List.Item.Meta
                                     avatar={<Avatar name={item.user_name} avatar={item.avatar_name} size="small"/>}
                                     title={<a href="/" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: item.user_uid})}>{item.user_name}</a>}
                                     description={moment.utc(item.created_at).utcOffset('+02:00').fromNow()}
                                 />
                                 <div dangerouslySetInnerHTML={{__html: item.content}}/>

                                 {this.props.user.uid === item.user_uid &&
                                 <div className="text-right">
                                     <a href="/" onClick={(e) => this.deleteComment(e, item.id, index, subindex)}>delete</a>
                                 </div>}
                             </div>
                         </List.Item>
                     )}
        />
    };

    render = () => {
        return <div>

            {this.props.privilege &&
            <div className="dashboard_block mb_15">
                <div className="m_15">
                    <div className="row marginless">
                        <div>
                            <Avatar name={this.props.user.name} avatar={this.props.user.avatar_name} size="large"/>
                        </div>
                        <div className="col">
                            <Editor onSave={this.saveComment} />
                        </div>
                    </div>
                </div>
            </div>}

            <List itemLayout="vertical" size="large" pagination={{pageSize: 50}} dataSource={this.state.comments}
                  renderItem={(item, index) => (

                      <div className="dashboard_block comment_block">
                          <List.Item key={item.id}>
                              <List.Item.Meta
                                  avatar={<Avatar name={item.user_name} avatar={item.avatar_name} size="medium"/>}
                                  title={<a href="/" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: item.user_uid})}>{item.user_name}</a>}
                                  description={moment.utc(item.created_at).utcOffset('+02:00').fromNow()} />

                              <div dangerouslySetInnerHTML={{__html: item.content}}/>

                              {this.props.user.uid === item.user_uid &&
                              <div className="text-right">
                                  <a href="/" onClick={(e) => this.deleteComment(e, item.id, index)}>delete</a>
                              </div>}

                              {this.props.sub && this.subComment(item.subcomments, index)}

                              {this.props.privilege && this.props.sub &&
                              <div className="comment_editor">
                                  <div className="row marginless">
                                      <div>
                                            <Avatar name={this.props.user.name} avatar={this.props.user.avatar_name} size="medium"/>
                                      </div>
                                      <div className="col">
                                            <Editor onSave={this.saveComment} parent={item.id} />
                                      </div>
                                  </div>
                              </div>}
                          </List.Item>
                      </div>
                  )} />
        </div>
    };

}

const mapStateToProps = state =>    ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Comments2);
