import React from 'react';
import {Button, Modal, Upload, Icon, Skeleton, Empty, Spin, Progress, message, Form, Select, Input} from "antd";
import update from "react-addons-update";
import {toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import folder from "../../assets/images/folder.png";
import api from "../../modules/api/request";
import FileIcon from "./FileIcon";
import FolderCreate from "./FolderCreate";
import FolderEdit from "./FolderEdit";
import FilesMove from "./FilesMove";
const { confirm } = Modal;
const {Option} = Select;

class FilesContent extends React.Component {

    state = model(this, {
        folder: 1,
        folders: [],
        files: [],
        loading: false,
        preloader: [],
        selected: [],
        history: [{id:1, name: 'all'}]
    });

    beforeUpload = (file, fileList) => {
        fileList.map((item) => {
            if(!item.type.startsWith('image'))   {
                item.preview = api.image(item);
                return item;
            }
            return this.setPreview(item);
        });
        this.stateSet({preloader: fileList});
    };
    setPreview = (file) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            file.preview = reader.result;
        });
        reader.readAsDataURL(file);
        return file;
    };
    onChange = (info) => {
        this.stateSet({preloader: info.fileList});

        if (info.file.status === 'done') {
            const newData = update(this.state, { files: {$unshift: [info.file.response]} });
            this.setState(newData);
        }
        else if (info.file.status === 'error')  {
            alert(info.file.response)
        }
    };

    selected = (item) => {
        var newData;
        var index = false;
        for(var i=0; i<this.state.selected.length; i++)     {
            if(item.id === this.state.selected[i].id)      {
                index = i;
            }
        }
        if (index === false) {
            newData = update(this.state, {selected: {$push: [item]} });
        } else {
            newData = update(this.state, {selected: {$splice: [[index, 1]]} });
        }
        this.setState(newData);
    };

    delete = () => {
        let t = this;
        confirm({
            title: 'Do you want to delete files ('+this.state.selected.length+') ?',
            onOk() {
                return new Promise((resolve, reject) => {
                    var ids = t.state.selected.map((item) => {
                        return item.id ;
                    });
                    api.delete(api.routes.filesDelete,{data: {id: ids}}).then(function (response) {
                        setTimeout(resolve, 10);
                        var files = t.state.files.filter((item, index) => {
                            return !ids.includes(item.id);
                        });
                        message.success(`Deleted.`);
                        const newData = update(t.state, {files: {$set: files}});
                        t.setState(newData);
                    });
                });
            },
            onCancel() {},
        });
    };

    select = () => {
        if(this.props.drawer.callback)      {
            this.props.drawer.callback(this.state.selected);
            this.props.toggleDrawer({drawer: 'files'});
            this.setState({...this.state, selected: []});
        }
    };

    search = (e) => {
        e.preventDefault();
        var val = e.target.value;
        this.stateSet({loading: true});
        this.load(this.state.folder, val);
    };

    changeFolder = (id, name) => {
        var history = [];
        for(var i=0; i<this.state.history.length; i++)      {
            if(this.state.history[i].id !== id)      {
                history.push(this.state.history[i]);
                continue;
            }
            break;
        }
        history.push({id: id, name: name});
        const newData = update(this.state, {history: {$set: history}, loading: {$set: true}, folder: {$set: id} });
        this.setState(newData);
        this.load(id);
    };

    render = () => {
        return   <div  style={{minHeight: 400}}>
            <div>
                <FolderCreate folder={this.state.folder} />
                &nbsp;
                <FolderEdit folder={this.state.folder} onDelete={() => this.changeFolder(1, 'all')} />
                &nbsp;
                <Upload
                    name='file'
                    multiple={true}
                    action={api.routes.filesUpload}
                    data={{folder: this.state.folder}}
                    showUploadList={false}
                    headers={{authorization: 'Bearer ' + this.props.user.jwt}}
                    beforeUpload={this.beforeUpload}
                    onChange={this.onChange} >
                    <Button className="strong mb_5">
                        <Icon type="upload" /> upload
                    </Button>
                </Upload>
                &nbsp;
                <Button className="strong mb_5" disabled={this.state.selected.length === 0} icon="close" onClick={this.delete}>
                    delete
                </Button>
                &nbsp;
                <FilesMove selected={this.state.selected} folder={this.state.folder} />
                &nbsp;
                {this.props.modal &&
                <Button className="strong mb_5" disabled={this.state.selected.length === 0} onClick={this.select}>
                    select
                </Button>}
                &nbsp;
                <Input onChange={this.search} style={{width: 240}}
                       maxLength={191} type="text" autoComplete="off" size="small" placeholder="search..." />

               <div>
                   {this.state.history.map((item, index) => {
                       return <strong key={index}>/ <a href="#" onClick={() => this.changeFolder(item.id, item.name)}> {item.name} </a></strong>
                   })}

               </div>
            </div>

            {!this.state.loading
                ? <div className="">
                    {this.state.preloader.map((item) => {
                        if(item.status === 'done')  return;
                        return <div key={item.uid} className="folder preloader">
                            {item.originFileObj && <img src={item.originFileObj.preview} />}
                            <Progress type="circle" percent={Math.ceil(item.percent)} width={80} />
                        </div>
                    })}

                    {this.state.folders.map((item) => {
                        return <div key={item.id} className="folder" onClick={() => this.changeFolder(item.id, item.name)}>
                            <div className="folder_img_container">
                                <img src={folder} />
                                {item.user_uid && <Icon type="lock" className="folder_lock_icon" />}
                            </div>
                             <div className="folder_title"> {item.name} </div>
                         </div>
                    })}

                    {this.state.files.map((file) => {
                        return <FileIcon
                            key={'f' + file.id}
                            item={file}
                            selected={this.selected}
                        />
                    })}

                </div>
                : <Skeleton active={true} />
            }
            </div>
    };

    load = (folder = 1, search = '') => {
        let t = this;
        api.get(api.routes.filesFolders, {params: {folder: folder, search: search}}).then(function (response) {
            t.stateSet({loading: false, preloader: [], selected: [], folders: response.data.folders, files: response.data.files});
        });
    };

    componentDidMount() {
        this.stateSet({loading: true});
        this.load(this.state.folder);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)       {
            this.stateSet({loading: true});
            this.load(this.state.folder);
        }
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.files
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(FilesContent);
