import React from 'react';
import {connect} from 'react-redux';
import {Table, Icon} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar from "../../../layout/Avatar";
const format = 'MMM Do YYYY';

class ContactsTable extends React.Component {

    columns = [
        {title: 'Photo', dataIndex: 'photo', key: 'photo', width: 60,
            render: (text, record) => {
                var name = record.name + ' ' + record.middlename + ' ' + record.lastname;
                return <Avatar avatar={record.photo_name} name={name} size="large"  />
            }},

        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'contact', id: record.id})}>
                    {record.prefix} {text} {record.middlename} {record.lastname}
                </a>
            }
        },

        {title: 'Role', dataIndex: 'role', key: 'role',
            render: (text, record) => {
                return <div> {text} <div> {record.status} </div> </div>
            }
        },

        {title: 'Company', dataIndex: 'company', key: 'company',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: record.company_id})}>
                    {record.company_name}
                </a>
            }
        },

        {title: 'Address', dataIndex: 'address', key: 'address',
            render: (text, record) => {
                return <div>{record.street} {record.city} </div>
            }
        },

        {title: 'Country', dataIndex: 'country', key: 'country',
            render: (text, record) => {
            var region = (record.country_region) ? '(' + record.country_region + ')' : '';
            return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: record.country_id})}>
                {record.country_name} {region} <div>{record.country_timezone}</div>
                </a>
            }
        },

        {title: 'Verified', dataIndex: 'verified', key: 'verified',
            render: (text, record) => {
                return <div>{record.verified ? <div className="green">Verified</div> : <div className="red">Unverified</div>} </div>
            }
        },
    ];

    handleTableChange = (e) => {
        console.log(e)
    }

    render = () => {
        return <Table
            className="td_1_100"
            dataSource={this.props.data.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            onChange={(obj) => this.props.changePage(obj.current)}
            pagination={{pageSize: this.props.data.per_page, current: this.props.data.current_page, total: this.props.data.total}} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactsTable);
