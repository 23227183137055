import React from 'react';
import {Button, Input, Skeleton} from "antd";
import {connect} from "react-redux";
import api from "../../../modules/api/request";
import ProjectsTable from "./ProjectsTable";
import model from "../../../modules/model/bind";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import {Link} from "react-router-dom";


class Projects extends React.Component   {

    state = model(this, {
       loading: false,
       projects: [],
       form: {
          search: {}
       }
    });

    links = [
        {link: '/projects', name: 'Created by me', title: 'Created by me', role: 1},
        {link: '/projects/participant', name: 'Participate in', title: 'Participate in', role: 2},
        {link: '/projects/observer', name: 'Observed by me', title: 'Observed by me', role: 3},
        {link: '/projects/all', name: 'All projects', title: 'All projects'},
    ];

    filtered = () => {
        var filtered = this.state.projects.filter((item) => {
            if(this.state.form.search.value)       {
                if(!item.name.toLowerCase().includes(this.state.form.search.value.toLowerCase()))        {
                    return false;
                }
            }
            return true;
        });
        return filtered;
    };

    render = () => {
        var pathname = this.props.pathname;
        return <div>
            <div className="tasks_header dashboard_block">
                {this.links.map(function (link) {
                    return <Link to={link.link} className={pathname === link.link ? 'active' : ''} key={link.link}> {link.name} </Link>
                })}
            </div>
            <div className="dashboard_title">
                {this.links.map(function (link) {
                    return pathname === link.link && link.title
                })}
            </div>

            {this.props.user.privileges.includes(205) &&
            <div className="text-right mb_15">
                <Button onClick={() => this.props.toggleDrawer({drawer: 'project'})} className="success" icon="plus">
                    CREATE PROJECT
                </Button>
            </div>}


            <div className="dashboard_block">
                <div className="m_15">

                    <div className="mb_15">
                        <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="search by name" /> &nbsp;
                    </div>

                    {!this.state.loading
                        ? <ProjectsTable data={this.filtered()}/>
                        : <Skeleton  className="p_15" active/>
                    }
                </div>
            </div>
        </div>
    };

    load = () => {
        let t = this;
        var url = api.routes.projects;
        this.links.map((item) => {
            if(item.link === this.props.pathname) {
                if(item.role) url += '/' + item.role;
            }
        });
        t.stateSet({loading: true, projects: []});
        api.get(url).then(function (response) {
            t.stateSet({loading: false, projects: response.data});
        });
    };

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.pathname !== prevProps.pathname)      {
            this.load();
        }
        if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.project
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Projects);
