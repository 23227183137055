import React from 'react';
import {connect} from 'react-redux';
import {Button, Empty, Select, Skeleton, Spin} from "antd";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import OffersTable from "./OffersTable";
const { Option } = Select;

class Offers extends React.Component {

    state = model(this, {
        loading: true,
        status: false,
        data: [],
        qualified: false,
        filters: {regions: [], owners: []},
        form: {
            search: {},
            region: {type: 'select', default: 'undefined'},
            owner: {type: 'select', default: 'undefined'},
        }
    });

    filtered = () => {
        var filtered = this.state.data.filter((item) => {
            if(this.state.form.search.value)       {
                var name = item.name.toLowerCase();
                var search = this.state.form.search.value.toLowerCase();
                if(!name.includes(search))        {
                    return false;
                }
            }
            if(this.state.form.region.value)       {
                if(!item.company)      {
                    return false;
                }
                if(item.company.region !== this.state.form.region.value)     {
                    return false;
                }
            }
            if(this.state.form.owner.value)       {
                if(!item.owner)      {
                    return false;
                }
                if(item.owner.name !== this.state.form.owner.value)     {
                    return false;
                }
            }
            return true;
        });
        if(this.state.qualified)        {
            /* var data = [
                {offers: [], name: 'SOLD'},
                {offers: [], name: 'Focused activity'},
                {offers: [], name: 'Additional activity'},
                {offers: [], name: 'Speculation projects'},
                {offers: [], name: 'LOST'},
            ];
            filtered.map((item) => {
                for(var i=0; i<data.length; i++) {
                    if(item.qualified === data[i].name) data[i].offers.push(item);
                }
            });
            var sorted = [];
            data.map((item, index) => {
                if(item.offers.length > 0)  sorted = sorted.concat(item.offers);
            });
            return sorted; */
        }
        return filtered;
    };

    renderQualified = () => {
        var data = [
            {offers: [], name: 'SOLD'},
            {offers: [], name: 'Focused activity'},
            {offers: [], name: 'Additional activity'},
            {offers: [], name: 'Speculation projects'},
            {offers: [], name: 'LOST'},
        ];
        this.filtered().map((item) => {
            for(var i=0; i<data.length; i++) {
                if(item.qualified === data[i].name) data[i].offers.push(item);
            }
        });
        var got = false;
        return <div>
            {data.map((item, index) => {
                return item.offers.length > 0 &&
                <div key={index}>
                    {got=true}
                    <h2 className="p_15"> {item.name} </h2> <OffersTable qualified={true} data={item.offers} />
                </div>
            })}
            {!got && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
    };

    render = () => {
        return <div>
            <div className="dashboard_title">
                OFFERS
            </div>

            {this.props.user.privileges.includes(606) &&
                <div className="text-right mb_15">
                    <Button onClick={() => this.props.toggleDrawer({drawer: 'offer'})} className="success" icon="plus">
                        CREATE OFFER
                    </Button>
                </div>}



            <div className="dashboard_block">
                <div className="m_15">
                    <Select placeholder="Users" style={{ width: 160 }} allowClear={true} {...this.state.form.owner}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.owners.map((owner, index) => {
                            return <Option key={index} value={owner.name}> {owner.name} ({owner.count})  </Option>
                        })}
                    </Select>
                    &nbsp;
                    <Select placeholder="Region" style={{ width: 160 }} allowClear={true} {...this.state.form.region}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.regions.map((region, index) => {
                            return <Option key={index} value={region.name}> {region.name} ({region.count}) </Option>
                        })}
                    </Select>
                </div>

                {this.state.loading
                    ? <div className="m_15"><Skeleton active={true} /></div>
                    : this.state.qualified
                        ? this.renderQualified()
                        : <OffersTable data={this.filtered()} />}
            </div>
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        var route = api.routes.offers;
        var qualified = false;
        if(this.props.pathname === '/qualifying-offers')    {
            route += '/qualified';
            qualified = true;
        }
        api.get(route).then(function (response) {
            var regions = {}, owners = {};
            response.data.map((item) => {
                if(item.company)       {
                    if(!regions[item.company.region]) {
                        regions[item.company.region] ={name: item.company.region, count: 1};
                    }
                    else    regions[item.company.region].count ++;
                }
                if(item.owner)       {
                    if(!owners[item.owner.uid]) {
                        owners[item.owner.uid] = {name: item.owner.name, count: 1};
                    }
                    else    owners[item.owner.uid].count ++;
                }
            });
            var filters = {regions: Object.values(regions), owners: Object.values(owners)};
            t.stateSet({loading: false, status: t.props.drawer.status, filters: filters, qualified:qualified, data: response.data});
        });
    };

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
        if(this.props.pathname !== prevProps.pathname)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.offer
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Offers);
