import React from 'react';
import {Button, message, Modal, Select} from "antd";
import api from "../../../modules/api/request";
import {connect} from "react-redux";
import model from "../../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../../store/actions/drawersActions";
import EditField from "../../../drawers/partials/EditField";
import Users from "../../../drawers/partials/Users";
const { confirm } = Modal;
const { Option } = Select;

class DepartmentShow extends React.Component   {

    state = model(this, {

    });

    addUsers = (ids) => {
        if(ids.length === 0) return;
        let t = this;
        api.post(api.routes.departmentUserAdd, {ids: ids, department: this.props.department.id}).then(function (response) {
            message.success(`User added.`);
            t.props.drawerChanged('department');
        });
    };

    deleteUser = (id) => {
        let t = this;
        confirm({
            title: 'Do you want to remove this user?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.departmentUserDelete, {data: {id: id, department: t.props.department.id}}).then(function (response) {
                        message.success(`User deleted.`);
                        t.props.drawerChanged('department');
                        setTimeout(resolve, 10);
                    });
                });
            },
            onCancel() {},
        });
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.departmentUpdate, {id: this.props.department.id, field: field, value: value}).then(function (response) {
            message.success(`Department updated.`);
            t.props.drawerChanged('department');
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this department?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.departmentDelete, {data: {id: t.props.department.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Department deleted.`);
                        t.props.toggleDrawer({drawer: 'department'});
                        t.props.drawerChanged('department');
                    });

                });
            },
            onCancel() {},
        });
    };

    render = () => {
        let department = this.props.department;
        let parent = this.props.departments.filter((item) => { return item.id === department.parent })[0];
        return <div>
            <div style={{position: 'relative'}} id="departmentsDrawerShow">
                <div className="light_bcg p_15 mb_15">

                    <EditField
                        field="input"
                        title="Name"
                        default={department.name}
                        edit={this.props.user.privileges.includes(305)}
                        form={{validate: 'required|max:191', default: department.name}}
                        onSubmit={(value) => this.save('name', value)}
                        show={<h3>{department.name}</h3>} />

                    <EditField
                        field="select"
                        title="Parent"
                        options={this.props.departments}
                        edit={this.props.user.privileges.includes(305)}
                        form={{type: 'select', default: department.parent}}
                        onSubmit={(value) => this.save('parent', value)}
                        show={<h3>{parent ? parent.name : 'not set'}</h3>} />
                </div>

                <div className="field_label">
                    <span> Employees: </span>
                    <Users
                        users={department.employees}
                        edit={this.props.user.privileges.includes(305)}
                        parent="departmentsDrawerShow"
                        addUsers={(value) => this.addUsers(value)}
                        deleteUser={(id) => this.deleteUser(id)} />
                </div>
            </div>
            <div className="drawer_footer">
                {this.props.user.privileges.includes(305) &&
                <><Button className="strong" onClick={this.delete}>Delete department</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'department'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user,
    departments: state.departments
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentShow);
