import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {Button, message, Modal, Select, Spin, Tabs, Checkbox, Form, Input} from "antd";
import {toggleDrawer} from "../../store/actions/drawersActions";
import PdfRenderer from "../PDF/PdfRenderer";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import PersonTreeSelect from "../partials/PersonTreeSelect";
import api from "../../modules/api/request";
const {TextArea} = Input;

class OfferExport extends React.Component   {

    state = model(this, {
        loading: true,
        structure: [
            {element: 'text', html: `Offer code <div>${this.props.offer.code}</div>`},
            {element: 'text', html: `Owner <div>${this.props.offer.owner.name}</div>`},
        ],
        form: {
            mailers: {type: 'select', default: []},
        }
    });

    editPdf = (e) => {
        this.props.toggleDrawer({e: e, drawer: 'pdf', title: this.props.offer.code, structure: this.state.structure});
    };

    downloadPdf = () => {
        let t = this;
        const blob = pdf(<PdfRenderer title={this.props.offer.code} structure={this.state.structure} />).toBlob();
        blob.then(function(data) {
            FileSaver.saveAs(data, `offer-${t.props.offer.code}.pdf`);
        });
    };

    zipAll = () => {
        let t = this;
        api.get(api.url + '/file/stream',{responseType: 'blob'}).then(function (response) {
            var imgData = response.data;
            const blob = pdf(<PdfRenderer title={t.props.offer.code} structure={t.state.structure} />).toBlob();
            blob.then(function(data) {
                var zip = new JSZip();
                zip.file(`offer-${t.props.offer.code}.pdf`, blob);
                zip.file("stax-logotype.png", imgData);
                zip.generateAsync({type: "blob"}).then(function(content) {
                    FileSaver.saveAs(content, `offer-${t.props.offer.code}.zip`);
                });
            });

        });
    };
    render = () => {
        var offer = this.props.offer;
        return <div>
            {this.state.loading
                ?  <div className="spin_loader"><Spin tip="Compiling to PDF"/></div>
                : <div>
                    <div className="p_15 light_bcg mb_15">
                        <h3>FILES:</h3>
                        <div  style={{marginLeft: 30}}>
                            <div className="mb_15">
                                <Checkbox defaultChecked={true}> offer-{offer.code}.pdf </Checkbox>
                                &nbsp; &nbsp;
                                <a href="#" onClick={this.editPdf}>edit</a>
                                &nbsp; &nbsp;
                                <a href="#" onClick={this.downloadPdf}>download</a>
                            </div>

                            <div className="mb_15">
                                <Checkbox disabled={true} defaultChecked={true}> project.JPEG </Checkbox>
                                &nbsp; &nbsp;
                                <span style={{color: 'lightgray'}}>view</span>
                                &nbsp; &nbsp;
                                <span style={{color: 'lightgray'}}>download</span>
                                &nbsp; &nbsp;
                                <i className="main">not uploaded</i>
                            </div>

                            <div className="mb_15">
                                <Checkbox disabled={true} defaultChecked={true}> project.DWG </Checkbox>
                                &nbsp; &nbsp;
                                <span style={{color: 'lightgray'}}>view</span>
                                &nbsp; &nbsp;
                                <span style={{color: 'lightgray'}}>download</span>
                                &nbsp; &nbsp;
                                <i className="main">not uploaded</i>
                            </div>
                        </div>
                    </div>

                    <Button onClick={this.zipAll} className="strong" style={{marginBottom: 30}}>
                        Download checked files in a ZIP
                    </Button>

                    <div className="mb_15">
                        <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                            style={{position: "relative"}} id="pdfTabForm" onSubmit={this.state.form.onSubmit}>
                            <Form.Item  {...{wrapperCol: {sm: { span: 24 }, md: { span: 24, offset: 6 }}}}>
                                Email attached files to selected persons:
                            </Form.Item>
                            <Form.Item label="Select employees">
                                <PersonTreeSelect field={this.state.form.mailers} parent="pdfTabForm" />
                            </Form.Item>
                            <Form.Item label="Add email body">
                                <TextArea placeholder="Body..." />
                            </Form.Item>
                            <Form.Item {...{wrapperCol: {sm: { span: 24 }, md: { span: 24, offset: 6 }}}}>
                                <Button disabled={true}> Send emails </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            }
        </div>
    };

    componentDidMount() {
        let t = this;
        setTimeout(function () {
            t.stateSet({loading: false})
        }, 1)

    }
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferExport);
