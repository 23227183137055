import React from 'react';
import {connect} from 'react-redux';
import {Table} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar from "../../../layout/Avatar";

class OffersTable extends React.Component {

    constructor(props)      {
        super(props);
        this.columns = [
            {title: 'Code', dataIndex: 'code', key: 'code',
                render: (text, record) => {
                    if(!text) return false;
                    return <div>
                        <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'offer', id: record.id})}>
                            <strong>{text}</strong>
                        </a>
                    </div>;
                }
            },
            {title: 'Owner', dataIndex: 'owner', key: 'owner',
                render: (text, record) => {
                    if(!record.owner) return false;
                    return <div>
                        <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: record.owner.uid})}>
                            <Avatar avatar={record.owner.avatar_name} name={record.owner.name} size="small" /> {record.owner.name}
                        </a>
                    </div>;
                }
            },
            {title: 'Customer', dataIndex: 'Customer', key: 'Customer',render: (text, record) => {
                    if(!record.company) return false;
                    return <div>
                        <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: record.company.id})}>
                            <Avatar name={record.company.name} avatar={record.company.logo_name} />
                            {record.company.name}
                        </a>
                    </div>;
                }},

            {title: 'Country', dataIndex: 'country', key: 'country',render: (text, record) => {
                    if(!record.company) return false;
                    return <div>
                        <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: record.company.country_id})}>
                            {record.company.country_name}
                        </a>
                    </div>;
                }},

            {title: 'Region', dataIndex: 'region', key: 'region', render: (text, record) => {
                    if(!record.company) return false;
                    return <div> {record.company.region} {record.company.region_code}</div>;
                }},

            {title: 'Type', dataIndex: 'type', key: 'type', render: (text, record) => {
                    return <div> {record.equipment_type} </div>;
                }},
            {title: 'Connecting to', dataIndex: 'Connecting to', key: 'Connecting to'},
            {title: 'Price', dataIndex: 'price', key: 'price'},

            {title: 'Follow-up date', dataIndex: 'follow', key: 'follow'},
            {title: 'Comment', dataIndex: 'comment', key: 'comment',render: (text, record) => {
                    return <div className="pre_wrap">{text}</div>;
                }},
        ];
        if(props.qualified)        {
            this.columns.splice(8, 0,
                {title: 'Funded', dataIndex: 'funded', key: 'funded'},
                {title: 'Stax', dataIndex: 'stax', key: 'stax'}
                )
        }
        else  {
            this.columns.splice(9, 0,
                {title: 'Status', dataIndex: 'status', key: 'status', render: (text, record) => {
                        if(record.qualified) return record.qualified;
                        return text;
                    }},
                )
        }
    }

    render = () => {
        return <Table
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            pagination={false} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(OffersTable);
