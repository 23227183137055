import React from 'react';
import {Button, Input, Tooltip, Icon, InputNumber, message, Modal, Select, Switch, Form} from "antd";
import api from "../../modules/api/request";
import model from "../../modules/model/bind";
import moment from "moment"
import {connect} from "react-redux";
import {drawerChanged} from "../../store/actions/drawersActions";
const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

class RemindersTask extends React.Component   {

    constructor(props)  {
        super(props);
        this.state = model(this, {
            edit: !this.props.item.id,
            form: {
                id: {value: this.props.item.id},
                title: {validate: 'required|max:191', value: this.props.item.title},
                content: {validate: 'max:2000', value: this.props.item.content},
                notification: {type: 'select', value: this.props.item.notification},
                type: {type: 'select',  value: this.props.item.type},
                global: {type: 'select',  value: this.props.item.global},
                date: {value: this.props.item.date}
            }
        });
    }


    onSubmit = (form, model) => {
        let t = this;
        api.post(api.routes.reminderUpdate, form).then(function (response) {
            message.success(`Reminder saved.`);
            t.props.drawerChanged('reminders');
        });
    };

    delete = () => {
        let t = this;
        confirm({
            title: 'Do you want to delete reminder?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.post(api.routes.reminderDelete, {id: t.props.item.id}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Reminder deleted.`);
                        t.props.drawerChanged('reminders');
                    });

                });
            },
            onCancel() {},
        });
    };

    closeEdit = () => {
        if(this.props.item.id)      {
            this.stateSet({edit: false});
        }
        else    {
            this.props.removeReminder(this.props.index);
        }
    };

    renderTitle = (item) => {
        switch (item.type) {
            case 'info':
                return  <span className="info"><Icon type="info-circle" /> {item.title} </span>
            case 'alert':
                return <span className="alert"><Icon type="exclamation-circle" /> {item.title} </span>
            case 'warning':
                return <span className="warning"><Icon type="warning" /> {item.title} </span>
            default:
                return <span> {item.title} </span>
        }
    };

    show = () => {
        var item = this.props.item;
        return <div className="row marginless">
            <div className="col">

                <h4> {this.renderTitle(item)} </h4>

                <p>{item.content}</p>

                {item.notification &&
                <Tooltip title={"Notification set " + item.notification + " day(s) before date."}>
                    <Icon type="bell" /> {moment(item.date).subtract(item.notification, 'days').format('MMM Do YYYY')}
                </Tooltip>}

                {(item.global && item.user !== this.props.user) &&
                    <div className="text-right">
                        <i>This reminder is global.</i>
                    </div>}

            </div>
            {item.global
                ? item.user_uid === this.props.user.uid
                    ? <div>
                        <Tooltip title="Edit">
                            <Button onClick={() => this.stateSet({edit: true})} icon="edit" />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Button icon="delete" onClick={this.delete} />
                        </Tooltip>
                    </div>
                    : ''
                : <div>
                    <Tooltip title="Edit">
                        <Button onClick={() => this.stateSet({edit: true})} icon="edit" />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button icon="delete" onClick={this.delete} />
                    </Tooltip>
                </div>
            }
        </div>
    };

    edit = () => {
        var item = this.state.form;
        return <form action="" onSubmit={item.onSubmit}>

                <Form.Item validateStatus={item.title.error ? 'error' : 'success'} help={item.title.error}>
                    <Input maxLength={191} {...item.title} placeholder="Title" />
                </Form.Item>

                <TextArea {...item.content} placeholder="Content" />

                <div className="mb_5">
                    <Icon type="bell" /> Set notification &nbsp;
                    <InputNumber min={0} max={30} {...item.notification} placeholder="0" />
                    &nbsp; days before date.
                </div>

                Type: &nbsp;
                <Select {...item.type} style={{ width: 120 }}>
                    <Option value=""> &nbsp; </Option>
                    <Option value="info" className="info"><Icon type="info-circle" /> Info</Option>
                    <Option value="alert" className="alert"><Icon type="exclamation-circle" /> Alert</Option>
                    <Option value="warning" className="warning"><Icon type="warning" /> Warning</Option>
                </Select>

                {this.props.user.privileges.includes(206) &&
                <div className="text-right">
                    Set as global (all employees can see it): &nbsp;
                    <Switch value="1" {...item.global} checked={!!item.global.value} size="small" />
                </div>}

                <div>
                    <Button className="success" icon="check" htmlType="submit"> Save</Button> &nbsp;
                    <Button icon="close" onClick={this.closeEdit}> Cancel </Button>
                </div>
            </form>
    };

    render = () => {
        return   <div className="light_bcg p_15 mb_15">
            {this.state.edit
                ? this.edit()
                : this.show()
            }
        </div>
    };
}
const mapStateToProps = state =>    ({
    user: state.user,
});
const mapDispatchToProps = {
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(RemindersTask);
