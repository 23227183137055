import React from 'react';
import {Button, Drawer, Modal} from "antd";
import {toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import FilesContent from "./FilesContent";

class FilesModal extends React.Component {

    state = model(this, {
        status: false,
    });

    render = () => {
        return <Modal
            className="stretch"
            width="920px"
            title="Files"
            zIndex={1101}
            visible={this.state.status}
            onCancel={() => this.props.toggleDrawer({drawer: 'files'})}>

            <FilesContent modal={true} />

        </Modal>
    };

    componentDidMount() {
        this.stateSet({status: this.props.drawer.status});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.stateSet({status: this.props.drawer.status});
        }
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.files
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(FilesModal);
