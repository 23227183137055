import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import ContactCreate from "./ContactCreate";
import ContactShow from "./ContactShow";


class ContactDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        contact: false,
        create: false,

        prefixes: ["Mr'", "Ms'", "Miss'", "Mrs'", "Sir'", "Madam'", "Lord'", "Dr'", "Prof'"],
        roles: ["Client", "Potential client", "Potencial agent", "Agent", "Supplier", "Partner", "Competitor", "Project manager"],
        contacts: ["over Website", "Email", "Partner", "Exibition", "Social media", "Commercial visit", "Phone"],
        religions: ["Atheists", "Buddhists", "Christians Ortodox", "Christians Catolics", "Hindus", "Jews", "Muslims Sunni", "Muslims Shia]"],

    });

    render = () => {
        return  <Drawer
                    visible={this.state.status}
                    width="80%"
                    zIndex={997}
                    title="CONTACT"
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'contact'})}>

            {!this.state.loading
                ? <div>
                    {this.state.contact &&
                    <ContactShow
                        contact={this.state.contact}
                        prefixes={this.state.prefixes}
                        roles={this.state.roles}
                        contacts={this.state.contacts}
                        religions={this.state.religions}
                    />}
                    {this.state.create &&
                    <ContactCreate
                        prefixes={this.state.prefixes}
                        roles={this.state.roles}
                        contacts={this.state.contacts}
                        religions={this.state.religions}
                    />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.contact + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, contact: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, contact: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, contact: false, create: false});
        }
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.contact
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactDrawer);
