import React from 'react';
import {Button, Form, Input, DatePicker, Select, message, Modal} from "antd";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
import ItemEdit from "./ItemEdit";
import update from "react-addons-update";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
const { confirm } = Modal;

class ItemsEdit extends React.Component   {

    state = model(this, {
        items: this.props.items
    });

    add = () => {
        var obj = {id: ''};
        this.props.elements.map((el, i) => {
            obj[el.name] = '';
        });
        const newData = update(this.state,{items: {$push: [obj]}} );
        this.setState(newData);
    };

    delete = (e, index) => {
        e.preventDefault();
        var id = this.state.items[index].id;
        if(id === '')       {
            const newData = update(this.state, {items: {$splice: [[index, 1]]} });
            this.setState(newData);
            return false;
        }
        this.props.delete(id);
    };

    render = () => {
        if(!this.state.items) return;
        return <div className="light_bcg p_15 mb_15">
            <h4>{this.props.title}</h4>
            {this.state.items.map((item, index) => {
                return <ItemEdit
                    key={index}
                    index={index}
                    item={item}
                    elements={this.props.elements}
                    edit={this.props.edit}
                    delete={this.delete}
                    save={this.props.save}
                />
                })}
            {this.props.edit && <Button icon="plus" size="small" onClick={this.add}> Add item </Button>}
        </div>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemsEdit);
