import React from 'react';
import {Select, Avatar} from "antd";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
const {Option} = Select;

class ContactSelect extends React.Component   {

    state = model(this, {
        contacts: [],
        loading: false,
    });

    render = () => {
       return <Select loading={this.state.loading} placeholder="Select contact" {...this.props.field}
                      allowClear={true}
                      showSearch={true}
                      filterOption={false}
                      onSearch={(input) => this.load(input)}
                  getPopupContainer={trigger => document.getElementById(this.props.parent)}>

           {this.state.contacts.map((item, index) => {
                var avatar = item.photo_name ? <Avatar size="small" src={api.avatar(item.photo_name)} /> : '';
                return <Option key={index} value={item.id}>
                    {avatar} {item.prefix} {item.name} {item.middlename} {item.lastname}
                </Option>
            })}

        </Select>
    };



    load = (search = "") => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.contacts, {params: {search: search}}).then(function (response) {
            t.stateSet({contacts: response.data.data, loading: false});
        });
    };


    componentDidMount() {
      this.load();
    }
}

export default ContactSelect;
