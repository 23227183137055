import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {Button, Spin, Checkbox, Modal, DatePicker, Form, message, Input} from "antd";
import api from "../../modules/api/request";
import moment from "moment";
import User from "../partials/User";
import {toggleDrawer, drawerChanged} from "../../store/actions/drawersActions";
import TaskProtected from "./TaskProtected";
import ProtectedMiddleware from "../partials/ProtectedMiddleware";
import EditField from "../partials/EditField";
const { confirm } = Modal;
const format = 'MMM Do YYYY';
const sqlFrmat = 'YYYY-MM-DD';

class TaskShow extends React.Component   {

    state = model(this, {
        role: false,
        user_accept: false,
        owner_accept: false,
        edit: false
    });

    delete = () => {
        let t = this;
        confirm({
            title: 'Do you want to delete this task?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.taskDelete, {data: {id: t.props.task.id}}).then(function (response) {
                        setTimeout(resolve, 10);
                        t.props.drawerChanged('task');
                        t.props.toggleDrawer({drawer: 'task'});
                        message.success(`Task deleted.`);
                    });
                });
            },
            onCancel() {},
        });
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.taskUpdate, {id: this.props.task.id, field: field, value: value}).then(function (response) {
            message.success(`Task updated.`);
            t.props.drawerChanged('task');
        });
    };

    addUsers = (object) => {
        var role = object.extra;
        var value = object.value;
        if(value.length === 0) return;
        let t = this;
        api.post(api.routes.taskUsersAdd, {ids: value, task: this.props.task.id, role: role}).then(function (response) {
            message.success(`Task updated.`);
            t.props.drawerChanged('task');
        });
    };

    render = () => {
        var task = this.props.task;
        var status = <i>
            Task pending... ({task.depend
            ? `depending on "${task.depend_name}"`
            : task.start ? 'start on ' + moment(task.start).format(format) : 'start not set'})
        </i>;
        if(task.finish) status = <i className="green"> Task finished. (on {moment(task.finish).format(format)}) </i>
        else if(task.start && moment(task.start).startOf('day').isSameOrBefore(moment(), 'day')) status = <i> in progress... </i>

        return task
                ? <div>
                    <div className="row marginless" style={{position: 'relative'}} id="tasksDrawerShow">
                        <div className="col">
                            <div className="light_bcg p_15 mb_15">
                                <EditField
                                    edit={this.state.edit}
                                    default={task.name}
                                    form={{validate: "required|max:191", default: task.name}}
                                    field="input"
                                    onSubmit={(value) => this.save('name', value)}
                                    show={<h4>{task.name}</h4>} />
                                {status}
                            </div>

                            {this.state.role && <div className="mb_15">
                             <EditField
                                edit={this.state.edit}
                                default={task.description}
                                field="editor"
                                form={{validate: "max:2000", default: task.description}}
                                onSubmit={(value) => this.save('description', value)}
                                show={<div dangerouslySetInnerHTML={{__html: task.description}}/>} />
                            </div>}

                            <ProtectedMiddleware
                                role={this.state.role}
                                task={this.props.task.id}
                                owner_accept={this.state.owner_accept}
                                user_accept={this.state.user_accept}>
                                <TaskProtected id={this.props.task.id} role={this.state.role} />
                            </ProtectedMiddleware>
                        </div>




                        {this.state.owner_accept && this.state.user_accept &&
                            <div style={{width: 300}}>

                                <EditField
                                    title="Start date"
                                    edit={this.state.edit}
                                    default={task.start}
                                    form={{type: "select", default: task.start ? moment(task.start) : null}}
                                    field="date"
                                    onSubmit={(value) => this.save('start', value ? value.format(sqlFrmat) : value)}
                                    show={<h4>{task.start ? moment(task.start).format(format) : 'not set'}</h4>} />


                                <EditField
                                    title="End date"
                                    edit={this.state.edit}
                                    default={task.end}
                                    form={{type: "select", default: task.end ? moment(task.end) : null}}
                                    field="date"
                                    onSubmit={(value) => this.save('end', value ? value.format(sqlFrmat) : value)}
                                    show={<h4>{task.end ? moment(task.end).format(format) : 'not set'}</h4>} />

                                {task.finish &&
                                <EditField
                                    title="Finish date"
                                    edit={this.state.edit}
                                    default={task.finish}
                                    form={{type: "select", default: task.finish ? moment(task.finish) : null}}
                                    field="date"
                                    onSubmit={(value) => this.save('finish', value ? value.format(sqlFrmat) : value)}
                                    show={<h4>{task.finish ? moment(task.finish).format(format) : 'not set'}</h4>}/>
                                }

                                <div className="field_label">
                                    <span> Project: </span>
                                    <strong>{task.project
                                        ? task.project
                                        : 'not set'}
                                    </strong>
                                </div>

                                {[{name: 'Created by', role: 1}, {name: 'Responsible person', role: 4}, {name: 'Participants', role: 2}, {name: 'Observers', role: 3}].map((group) => {
                                        return <div key={group.name} className="field_label">
                                            <span> {group.name}: </span>

                                            {this.props.task.users.map((item) => {
                                                if(item.pivot.role === group.role)       {
                                                    return <User
                                                        key={item.uid}
                                                        user={item}
                                                        owner={this.state.role === 1 || this.state.role === 4} />
                                                }
                                            })}

                                            <EditField
                                                edit={this.state.edit}
                                                field="persons"
                                                extra={group.role}
                                                form={{type: 'select', default: []}}
                                                onSubmit={(value) => this.addUsers(value)}
                                                parent="tasksDrawerShow" />
                                        </div>
                                })}
                            </div>}
                    </div>


                    {this.state.edit &&
                    <div className="drawer_footer">
                        {!task.finish && !task.start &&
                        <span><Button className="strong" onClick={(e) => this.save('start', moment().format(sqlFrmat))}>
                                        Start task
                                    </Button>&nbsp;</span>}

                        {!task.finish &&
                        <span>
                                    <Button className="strong" onClick={(e) => this.save('finish', moment().format(sqlFrmat))}>
                                        Finish task
                                    </Button>&nbsp;</span>}
                        <Button className="strong" onClick={this.delete}>Delete task</Button>
                    </div>}
                </div>
                : <Spin spinning={true} />
    };

    componentDidMount() {
        var role = false, user_accept = false, owner_accept = false, edit = false;
        this.props.task.users.map((item) => {
            if(item.uid === this.props.user.uid)       {
                if(item.pivot.role)         role = item.pivot.role;
                if(item.pivot.owner_accept) owner_accept = item.pivot.owner_accept;
                if(item.pivot.user_accept)  user_accept = item.pivot.user_accept;
                if(item.pivot.role === 1 || item.pivot.role === 4) {
                    if(item.pivot.owner_accept || item.pivot.user_accept)       {
                        edit = true;
                    }
                }
            }
        });
        this.stateSet({role: role, user_accept: user_accept, owner_accept: owner_accept, edit: edit});
    }

}

const mapStateToProps = state =>    ({
    user: state.user,
    drawer: state.drawers.task
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskShow);
