import React from 'react';
import {Button, Modal, Input, Tooltip} from "antd";
import {toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import api from "../api/request";
const { confirm } = Modal;

class Tools extends React.Component {

    constructor(props) {
        super(props);
        this.videoInput = React.createRef();
        this.linkInput = React.createRef();
    }


    upload = (e) => {
        e.preventDefault();
        var range = this.saveSelection();
        this.props.toggleDrawer({drawer: 'files', callback: (items) => {
            var file = items[0];
            if(file.type.startsWith('image'))       {
                var f = `<a href="${api.image(file, true)}"  class="popup" target="_blank"><img src="${api.image(file)}" width="80" /></a>`;
            }
            else    {
                var f = `<a href="${api.url}/files/${file.name}" target="_blank"><img src="${api.image(file)}" width="30" /> ${file.alias || file.name} </a>`;
            }
            this.restoreSelection(range);
            document.execCommand("insertHTML", false, f);
        }})
    };
    video = (e) =>  {
        e.preventDefault();
        let t = this;
        var range = this.saveSelection();
        confirm({
            title: 'Add youtube url.',
            content: (
                <div>
                    <Input ref={t.videoInput} placeholder="Youtube..." />
                </div>
            ),
            onOk() {
                var value = t.videoInput.current.state.value;
                if(!value) return false;
                var arr = value.split("=");
                var code = arr.pop();
                if(!code) return false;
                var url = '//www.youtube.com/embed/' + code;
                var iframe = "<iframe width='100%' height='350' src='"+ url +"' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
                t.restoreSelection(range);
                document.execCommand("insertHTML", false, iframe);
            },
            onCancel() {

            }
        });
    };

    link = (e) => {
        e.preventDefault();
        let t = this;
        var range = this.saveSelection();
        confirm({
            title: 'Add link.',
            content: (
                <div>
                    <Input ref={t.linkInput} placeholder="Url" />
                </div>
            ),
            onOk() {
                var value = t.linkInput.current.state.value;
                if(!value) return false;
                t.restoreSelection(range);
                document.execCommand("createLink", false, value);
                window.getSelection().anchorNode.parentElement.target = '_blank';
            },
            onCancel() {

            }
        });
    };
    bold = (e) => {
        e.preventDefault();
        document.execCommand("bold", false, null);
    };
    italic = (e) => {
        e.preventDefault();
        document.execCommand("italic", false, null);
    };
    orderedList = (e) => {
        e.preventDefault();
        document.execCommand("insertOrderedList", false, null);
    };

    saveSelection = () =>   {
        if (window.getSelection) {
            var sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                return sel.getRangeAt(0);
            }
        } else if (document.selection && document.selection.createRange) {
            return document.selection.createRange();
        }
        return null;
    };
    restoreSelection = (range) =>    {
        if (range) {
            if (window.getSelection) {
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (document.selection && range.select) {
                range.select();
            }
        }
    };

    render = () => {
        return <div className="editor_icons">
            <Tooltip placement="top" title="Upload file">
                <Button icon="upload" onClick={this.upload} />
            </Tooltip>
            <Tooltip placement="top" title="Create link">
                <Button icon="link" onClick={this.link} />
            </Tooltip>
            <Tooltip placement="top" title="Link video">
                <Button icon="video-camera" onClick={this.video} />
            </Tooltip>
            <Tooltip placement="top" title="Bold font">
                <Button icon="bold" onClick={this.bold} />
            </Tooltip>
            <Tooltip placement="top" title="Italic font">
                <Button icon="italic" onClick={this.italic} />
            </Tooltip>
            <Tooltip placement="top" title="Insert ordered list">
                <Button icon="ordered-list" onClick={this.orderedList} />
            </Tooltip>
        </div>
    };
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Tools);
