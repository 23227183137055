import React from 'react';
import '../../../assets/frappe-gantt.css'
import Gantt from  '../../../assets/frappe-gantt';
import moment from 'moment';
const format = 'MMM Do YYYY';

class GanttTable extends React.Component {

    gantt = false;

    state ={
        mode: 'Day',
        empty: false
    }

    render = () => {
        return <div>
            <svg id="gantt"> </svg>
        </div>
    };

    setGantt = (ganttArr) => {
        this.gantt = new Gantt("#gantt", ganttArr, {
            custom_popup_html: function(item) {
                return `<div class="details-container">
                          <h5>${item.name}</h5>
                          <p> Destination: ${item.departing} - ${item.destination} </p>
                          <p> Date: ${moment.utc(item.start).format(format)} - ${moment.utc(item.end).format(format)} </p>
                         
                        </div>`;
            },
        });
    };

    componentDidMount() {
        this.setGantt(this.props.data);
        this.gantt.change_view_mode(this.props.mode);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data !== prevProps.data)      {
            const ganttArr = this.props.data;
            if(ganttArr.length > 0) {
                if(!this.gantt) {
                    this.setGantt(ganttArr);
                }
                else    {
                    this.gantt.refresh(ganttArr);
                }
            }
            else    {
                if(this.gantt) {
                    this.gantt.clear();
                }
            }
        }
        if(this.props.mode !== prevProps.mode)      {
            this.gantt.change_view_mode(this.props.mode)
        }
    }
}

export default GanttTable;
