import React from 'react';
import {Button, message, Modal, Select, Tabs} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import EditField from "../partials/EditField";
import pdf from "../../assets/images/pdf.png";
import PdfWorkplace from "../PDF/PdfWorkplace";
const { confirm } = Modal;

class EquipmentShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit,
        structure: this.props.equipment.structure ? JSON.parse(this.props.equipment.structure) : []
    });

    save = (field, value) => {
        let t = this;
        api.post(api.routes.equipmentUpdate, {id: this.props.equipment.id, field: field, value: value}).then(function (response) {
            message.success(`Updated.`);
            t.props.drawerChanged('equipment');
        });
    };

    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.equipmentDelete, {data: {id: t.props.equipment.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Deleted.`);
                        t.props.toggleDrawer({drawer: 'equipment'});
                        t.props.drawerChanged('equipment');
                    });

                });
            },
            onCancel() {},
        });
    };

    pdf = () => {
        this.props.toggleDrawer({drawer: 'pdf',
            title: "EQUIPMENT", structure: this.state.structure, save: this.save})
    };

    render = () => {
        let equipment = this.props.equipment;
        var edit = this.props.user.privileges.includes(702) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={this.pdf}>
                    <img src={pdf} width={15} style={{marginTop: -4}} /> &nbsp; PDF
                </Button>
                &nbsp;
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT EQUIPMENT'}
                </Button>
            </div>

            <div style={{position: 'relative'}} id="equipmentDrawerShow">

                <div className="row marginless">
                    <div className="col-md-6">
                        <EditField
                            title="Name"
                            edit={edit}
                            form={{validate: "required|max:191", default: equipment.name}}
                            field="input"
                            onSubmit={(value) => this.save('name', value)}
                            show={<h4>{equipment.name}</h4>} />

                        <EditField
                            title="Code"
                            edit={edit}
                            form={{validate: "required|max:191", default: equipment.code}}
                            field="input"
                            onSubmit={(value) => this.save('code', value)}
                            show={<h4>{equipment.code}</h4>} />

                        {[1,2,3,4].map((item) => {
                            let name = 'price'+item;
                            return  <EditField key={item}
                                title={"Price " + item}
                                edit={edit}
                                form={{default: equipment[name]}}
                                field="input"
                                onSubmit={(value) => this.save(name, value)}
                               show={this.notSet(equipment[name])} />
                        })}
                    </div>
                </div>

                <PdfWorkplace
                    noEdit={!edit}
                    structure={this.state.structure}
                    callback={(structure) => this.stateSet({'structure': structure})} />

            </div>
            <div className="drawer_footer">
                {edit && <>
                    <Button className="strong" onClick={() => this.save('structure', this.state.structure)}>SAVE CHANGES</Button>&nbsp;
                    <Button className="strong" onClick={this.delete}>Delete equipment</Button>&nbsp;
                </>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'equipment'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentShow);
