import React from 'react';
import {Menu, Icon, Badge} from "antd";
import {Link} from "react-router-dom";
import privileges from "../store/privileges"
import {connect} from "react-redux";
const {SubMenu} = Menu;

class LeftMenu extends React.Component {

    single = (item) =>  {
        if(item.code && !this.props.user.privileges.includes(item.code))     {
            return;
        }
        return <Menu.Item key={item.id}>
            <Link to={item.url}>
                {item.icon && <Icon type={item.icon} />} {item.page} {item.new &&<Badge count=" &nbsp; new &nbsp; " />}
            </Link>
        </Menu.Item>
    };

    sub = (item) => {
        if(item.code && !this.props.user.privileges.includes(item.code))     {
            return;
        }
        return <SubMenu key={item.id}
                 title={<span>{item.icon && <Icon type={item.icon} />}<span> {item.page} </span></span>}>
            {item.children.map((sub, i) => {
                return this.single(sub)
            })}
        </SubMenu>
    };

    render = () => {
        return <Menu className={this.props.open ? "dashboard_menu dashboard_menu_open" : "dashboard_menu"} mode="inline">
            {privileges.map((item, index) => {
                return item.children.length === 0
                    ? this.single(item)
                    : this.sub(item)
            })}
        </Menu>
    }
}

const mapStateToProps = state => ({
    user: state.user
});
export default connect(mapStateToProps)(LeftMenu);
