import React from 'react';
import {Button, message, Modal, Select} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import Avatar from "../../layout/Avatar";
import EditField from "../partials/EditField";
import moment from "moment";
import pdf from "../../assets/images/pdf.png";
const { confirm } = Modal;
const sqlFrmat = 'YYYY-MM-DD';
const format = 'MMM Do YYYY';


class VisitShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit
    });

    save = (field, value) => {
        let t = this;
        api.post(api.routes.visitUpdate, {id: this.props.visit.id, field: field, value: value}).then(function (response) {
            message.success(`Visit updated.`);
            t.props.drawerChanged('visit');
        });
    };

    notSet = (value) => {
        return <h4 className="pre_wrap">{value ? value : <i> not set </i>}</h4>
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this visit?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.visitDelete, {data: {id: t.props.visit.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Deleted.`);
                        t.props.toggleDrawer({drawer: 'visit'});
                        t.props.drawerChanged('visit');
                    });

                });
            },
            onCancel() {},
        });
    };

    pdf = () => {
        let visit = this.props.visit;
        let contact = visit.contact ? (visit.contact.prefix || '') + ' ' + (visit.contact.name || '') + ' ' + (visit.contact.middlename || '') + ' ' + (visit.contact.lastname || '') : '';
        var structure = [
            {element: 'table', data: [
                    [{type: "text", value: "Contact"}, {type: "text", value: contact}],
                    [{type: "text", value: "Responsible person"}, {type: "text", value: visit.user ? visit.user.name : ''}]
                ]},
            {element: 'table', header: 'Arrival - Departure', data: [
                      [{type: "text", value: "By"}, {type: "text", value: visit.arrival_by ? visit.arrival_by : ''},  {type: "text", value: visit.departure_by ? visit.departure_by : ''}],
                      [{type: "text", value: "Flight number"}, {type: "text", value: visit.arrival_flight_number ? visit.arrival_flight_number : ''},  {type: "text", value: visit.departure_flight_number ? visit.departure_flight_number : ''}],
                      [{type: "text", value: "Country"}, {type: "text", value: visit.arrival_from ? visit.arrival_from : ''},  {type: "text", value: visit.departure_to ? visit.departure_to : ''}],
                      [{type: "text", value: "City"}, {type: "text", value: visit.arrival_city ? visit.arrival_city : ''},  {type: "text", value: visit.departure_city ? visit.departure_city : ''}],
                      [{type: "text", value: "Date"}, {type: "text", value: visit.arrival_date ? moment(visit.arrival_date).format(format) : ''},  {type: "text", value: visit.departure_date ? moment(visit.departure_date).format(format) : ''}],
                      [{type: "text", value: "Time"}, {type: "text", value: visit.arrival_time ? moment(visit.arrival_time, "HH:mm:ss").format("HH:mm") : ''},  {type: "text", value: visit.departure_time ? moment(visit.departure_time, "HH:mm:ss").format("HH:mm") : ''}],
                      [{type: "text", value: "Details"}, {type: "text", value: visit.arrival_details ? visit.arrival_details : ''}, {type: "text", value: visit.departure_details ? visit.departure_details : ''}],
                ]},
            {element: 'table', header: 'Accommodation', data: [
                    [{type: "text", value: "Hotel"}, {type: "text", value: visit.hotel ? visit.hotel : ''}],
                    [{type: "text", value: "Hotel at"}, {type: "text", value: visit.hotel_in ? visit.hotel_in : ''}],
                    [{type: "text", value: "Hotel paid by"}, {type: "text", value: visit.hotel_paid ? visit.hotel_paid : ''}],
                    [{type: "text", value: "Food comment"}, {type: "text", value: visit.food ? visit.food : ''}],
                ]}
        ];
        this.props.toggleDrawer({drawer: 'pdf', title: 'Visit', structure: structure})
    };

    render = () => {
        let visit = this.props.visit;
        var edit = this.props.user.privileges.includes(410) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={this.pdf}>
                    <img src={pdf} width={15} style={{marginTop: -4}} /> &nbsp; PDF
                </Button>
                &nbsp;
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT VISITS'}
                </Button>
            </div>
            <div style={{position: 'relative'}} id="visitDrawerShow">

                <div style={{marginBottom: 10}}> Contact: </div>
                <h3>
                    <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'contact', id: visit.contact.id})}>
                    <Avatar avatar={visit.contact.photo_name} name={visit.contact.name} size="large"  />
                    {visit.contact.prefix} {visit.contact.name}  {visit.contact.middlename}  {visit.contact.lastname}
                    </a>
                </h3>

                <div className="mb_15">
                <EditField
                    title="Responsible person"
                    edit={edit}
                    form={{type: 'select', default: visit.user ? visit.user.uid : []}}
                    field="persons"
                    onSubmit={(value) => this.save('responsible', value)}
                    parent="visitDrawerShow"
                    show={visit.user
                        ? <h3><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: visit.user.uid})}>
                            <Avatar name={visit.user.name} avatar={visit.user.avatar_name} />
                            {visit.user.name}</a></h3>
                        : <h3><i>not set</i></h3>}
                />
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <h3 className="text-center">Arrival</h3>
                        <EditField
                            title="Arrival by"
                            edit={edit}
                            form={{type: 'select', default: visit.arrival_by}}
                            field="select"
                            options={['plane', 'car']}
                            onSubmit={(value) => this.save('arrival_by', value)}
                            parent="visitDrawerShow"
                            show={this.notSet(visit.arrival_by)} />

                        {visit.arrival_by === 'plane' && <EditField
                            title="Flight number"
                            edit={edit}
                            form={{validate: 'max:191', default: visit.arrival_flight_number}}
                            field="input"
                            onSubmit={(value) => this.save('arrival_flight_number', value)}
                            parent="visitDrawerShow"
                            show={this.notSet(visit.arrival_flight_number)} />}

                        <EditField
                            title="Coming from"
                            edit={edit}
                            form={{type: "select", default: visit.arrival_from}}
                            field="country"
                            parent="visitDrawerShow"
                            onSubmit={(value) => this.save('arrival_from', value)}
                            show={visit.country
                                ? <h3><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: visit.country.id})}>
                                    {visit.country.flag_name && <Avatar name={visit.country.name} avatar={visit.country.flag_name} />}
                                    {visit.country.name}
                                </a></h3>
                                : <h3><i>not set</i></h3>} />

                        <EditField
                            title="City"
                            edit={edit}
                            form={{validate: 'max:191', default: visit.arrival_city}}
                            field="input"
                            onSubmit={(value) => this.save('arrival_city', value)}
                            show={this.notSet(visit.arrival_city)} />

                        <EditField
                            title="Expected arrival date"
                            edit={edit}
                            form={{type: 'select', default: visit.arrival_date ? moment(visit.arrival_date) : null}}
                            field="date"
                            onSubmit={(value) => this.save('arrival_date', value ? value.format(sqlFrmat) : value)}
                            show={<h4>{visit.arrival_date ? moment(visit.arrival_date).format(format) : 'not set'}</h4>} />

                        <EditField
                            title="Expected arrival time"
                            edit={edit}
                            form={{type: 'select', default: visit.arrival_time ? moment(visit.arrival_time, "HH:mm:ss") : null}}
                            field="time"
                            onSubmit={(value) => this.save('arrival_time', value ? value.format("HH:mm") : value)}
                            show={<h4>{visit.arrival_time ? moment(visit.arrival_time, "HH:mm:ss").format("HH:mm") : 'not set'}</h4>} />

                        <EditField
                            title="Visit details"
                            edit={edit}
                            form={{default: visit.arrival_details}}
                            field="textarea"
                            onSubmit={(value) => this.save('arrival_details', value)}
                            show={this.notSet(visit.arrival_details)} />
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-center">Departure</h3>
                        <EditField
                            title="Departure by"
                            edit={edit}
                            form={{type: 'select', default: visit.departure_by}}
                            field="select"
                            options={['plane', 'car']}
                            onSubmit={(value) => this.save('departure_by', value)}
                            parent="visitDrawerShow"
                            show={this.notSet(visit.departure_by)} />

                        {visit.departure_by === 'plane' && <EditField
                            title="Flight number"
                            edit={edit}
                            form={{validate: 'max:191', default: visit.departure_flight_number}}
                            field="input"
                            onSubmit={(value) => this.save('departure_flight_number', value)}
                            parent="visitDrawerShow"
                            show={this.notSet(visit.departure_flight_number)} />}

                        <EditField
                            title="Departure to"
                            edit={edit}
                            form={{type: "select", default: visit.departure_to}}
                            field="country"
                            parent="visitDrawerShow"
                            onSubmit={(value) => this.save('departure_to', value)}
                            show={visit.country2
                                ? <h3><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: visit.country2.id})}>
                                    {visit.country2.flag_name && <Avatar name={visit.country2.name} avatar={visit.country2.flag_name} />}
                                    {visit.country2.name}
                                </a></h3>
                                : <h3><i>not set</i></h3>} />

                        <EditField
                            title="City"
                            edit={edit}
                            form={{validate: 'max:191', default: visit.departure_city}}
                            field="input"
                            onSubmit={(value) => this.save('departure_city', value)}
                            show={this.notSet(visit.departure_city)} />

                        <EditField
                            title="Expected departure date"
                            edit={edit}
                            form={{type: 'select', default: visit.departure_date ? moment(visit.departure_date) : null}}
                            field="date"
                            onSubmit={(value) => this.save('departure_date', value ? value.format(sqlFrmat) : value)}
                            show={<h4>{visit.departure_date ? moment(visit.departure_date).format(format) : 'not set'}</h4>} />

                        <EditField
                            title="Expected departure time"
                            edit={edit}
                            form={{type: 'select', default: visit.departure_time ? moment(visit.departure_time, "HH:mm:ss") : null}}
                            field="time"
                            onSubmit={(value) => this.save('departure_time', value ? value.format("HH:mm") : value)}
                            show={<h4>{visit.departure_time ? moment(visit.departure_time, "HH:mm:ss").format("HH:mm") : 'not set'}</h4>} />

                    </div>
                </div>

                <h3 className="text-center">Accommodation</h3>

                <EditField
                    title="Hotel reservation required"
                    edit={edit}
                    form={{type: 'select', default: visit.hotel}}
                    field="select"
                    options={['Yes', 'No']}
                    onSubmit={(value) => this.save('hotel', value)}
                    parent="visitDrawerShow"
                    show={this.notSet(visit.hotel)} />

                {visit.hotel === "Yes" && <EditField
                    title="Hotel at"
                    edit={edit}
                    form={{type: 'select', default: visit.hotel_in}}
                    field="select"
                    options={['Belgrade', 'Čačak']}
                    onSubmit={(value) => this.save('hotel_in', value)}
                    parent="visitDrawerShow"
                    show={this.notSet(visit.hotel_in)} />}

                {visit.hotel === "Yes" && <EditField
                    title="Hotel paid by"
                    edit={edit}
                    form={{type: 'select', default: visit.hotel_paid}}
                    field="select"
                    options={['Stax', 'client']}
                    onSubmit={(value) => this.save('hotel_paid', value)}
                    parent="visitDrawerShow"
                    show={this.notSet(visit.hotel_paid)} />}

                <EditField
                    title="Food comment"
                    edit={edit}
                    form={{default: visit.food}}
                    field="textarea"
                    onSubmit={(value) => this.save('food', value)}
                    show={this.notSet(visit.food)} />
            </div>


            <div className="drawer_footer">
                {edit && <><Button className="strong" onClick={this.delete}>Delete visit</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'visit'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(VisitShow);
