import React from 'react';
import {Button, message, Modal} from "antd";
import api from "../../modules/api/request";
import Avatar from "../../layout/Avatar";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
const { confirm } = Modal;

class User extends React.Component   {

   delete = (e) => {
       e.preventDefault();
       let t = this;
       confirm({
           title: 'Do you want to remove this user?',
           onOk() {
               return new Promise((resolve, reject) => {

                   if(t.props.user.pivot.task_id)       {
                       api.delete(api.routes.taskUserDelete, {data: {id: t.props.user.pivot.id, task: t.props.user.pivot.task_id}}).then(function (response) {
                           t.props.drawerChanged('task');
                           message.success(`Removed.`);
                           setTimeout(resolve, 10);
                       });
                   }
                   if(t.props.user.pivot.project_id)       {
                       api.delete(api.routes.projectUserDelete, {data: {id: t.props.user.pivot.id, project: t.props.user.pivot.project_id}}).then(function (response) {
                           t.props.drawerChanged('project');
                           message.success(`Removed.`);
                           setTimeout(resolve, 10);
                       });
                   }


               });
           },
           onCancel() {},
       });
   };

   accept = (e) => {
       e.preventDefault();
       let t = this;
       if(t.props.user.pivot.task_id)       {
           api.post(api.routes.taskUserAccept, {user: t.props.user.uid, id: t.props.user.pivot.id, task: t.props.user.pivot.task_id}).then(function (response) {
               t.props.drawerChanged('task');
               message.success(`Accepted.`);
           });
       }
       if(t.props.user.pivot.project_id) {
           api.post(api.routes.projectUserAccept, {user: t.props.user.uid, id: t.props.user.pivot.id, project: t.props.user.pivot.project_id})
               .then(function (response) {
                   t.props.drawerChanged('project');
                   message.success(`Accepted.`);
           });
       }
   };

    render = () => {
        var user = this.props.user;
        var owner = this.props.owner;
        if(!user.pivot.user_accept) {
            if(owner)     {
                return <div className="mb_15">
                    <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                        <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
                    </a>
                    <Button type="link" className="pull-right" icon="close" size="small" onClick={this.delete} />
                    <br />
                    <i className="main">pending accept...</i>
                </div>
            }
            return false;
        }
        if(!user.pivot.owner_accept) {
            if(owner)     {
                return <div key={user.uid} className="mb_15">
                    <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                        <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
                    </a>
                    <Button type="link" className="pull-right" icon="close" size="small" onClick={this.delete} /><br />
                    <i className="main">asked to join: </i>
                    <Button className="strong" size="small" onClick={this.accept} style={{marginRight: 3}}>
                        Accept
                    </Button>
                    <Button className="strong" size="small" onClick={this.delete}>Reject</Button>
                </div>
            }
            return false;
        }
        return <div key={user.uid} className="mb_15">
            <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
            </a>
            {owner && <Button type="link" className="pull-right" icon="close" size="small" onClick={this.delete} />}
        </div>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    drawerChanged: drawerChanged,
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
