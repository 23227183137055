import React from 'react';
import {Button, Form, Input, DatePicker, Select, message, Modal, Alert} from "antd";
import update from "react-addons-update";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
import {Gmaps, Marker} from "react-gmaps";
const { TextArea } = Input;
const {Option} = Select;
const format = 'MMM Do YYYY';


class ItemEdit extends React.Component   {

    constructor(props)      {
        super(props);
        this.map = null;
        var form = {id: {value: props.item.id}};
        props.elements.map((el, i) => {
            var validate = 'max:191';
            form[el.name] = {value: props.item[el.name], validate: validate}
        });
        var edit = false;
        if(props.item.id === '')        {
            edit = true;
        }
        this.state = model(this, {
            editItem: edit,
            form: form
        });
    }

    changeEdit = (e) => {
        e.preventDefault();
        this.stateSet({editItem: !this.state.editItem});
    };

    onSubmit = (form, model) => {
        this.props.save(form);
    };

    onClickMap = (e) => {
        this.stateSet({'form.lat.value': e.latLng.lat(), 'form.lng.value': e.latLng.lng()})
        var latLng = {lat: e.latLng.lat(), lng: e.latLng.lng()};
        this.map.panTo(latLng);
    };

    renderEdit = (el) => {
        if(el.type === 'textarea')      {
            return <TextArea {...this.state.form[el.name]} placeholder={el.name} autoComplete="off" />
        }
        if(el.type === 'gmap' && el.name === 'lat')      {
            var lat = 44.787197, lng = 20.457273;
            if(this.props.item.lat && this.props.item.lng) {
                lat = this.props.item.lat;
                lng = this.props.item.lng;
            }
            return <Gmaps
                width={'100%'}
                height={'150px'}
                lat={lat}
                lng={lng}
                zoom={16}
                onMapCreated={(map) => this.map = map}
                loadingMessage={'Loading...'}
                params={{v: '3.exp', key: 'AIzaSyBEiSYtxrrCDlI6cobIM1bu81stEhA79hs'}}
                onClick={(e) => this.onClickMap(e) }
            >
                <Marker
                    lat={this.state.form.lat.value}
                    lng={this.state.form.lng.value}
                />
            </Gmaps>
        }
        if(el.type === 'gmap' && el.name === 'lng')      {
            return '';
        }
        return  <Input {...this.state.form[el.name]} placeholder={el.name} autoComplete="off" />
    };

    renderShow = (el) => {
        if(el.type === 'gmap'&& el.name === 'lat')      {
            if(!this.props.item.lat || !this.props.item.lng) return '';
            return <Gmaps
                width={'100%'}
                height={'150px'}
                lat={this.props.item.lat}
                lng={this.props.item.lng}
                zoom={16}
                loadingMessage={'Loading...'}
                params={{v: '3.exp', key: 'AIzaSyBEiSYtxrrCDlI6cobIM1bu81stEhA79hs'}}
            >
                <Marker
                    lat={this.props.item.lat}
                    lng={this.props.item.lng}
                />
            </Gmaps>
        }
        if(el.type === 'gmap' && el.name === 'lng')      {
            return '';
        }
        if(el.type === 'company')      {
            return <div>asdfsdfasdadsfdsf</div>
        }
        return <div className="pre_wrap">{this.props.item[el.name]}</div>
    };

    render = () => {
        const form = this.state.form;
        return <div>

            {this.props.edit && <div className='text-right'>
                <a href="/" onClick={(e) => this.changeEdit(e)}>
                    {this.state.editItem ? 'close' : 'edit'}
                </a>
            </div>}

            {this.state.editItem
                ? <Form  onSubmit={form.onSubmit} className="mb_15">
                    {this.props.elements.map((el, i) => {
                        return <div key={i}>
                            <Form.Item validateStatus={form[el.name].error && 'error'} help={form[el.name].error}>
                                {this.renderEdit(el)}
                            </Form.Item>
                        </div>
                    })}
                    <Alert className={form.error ? 'show' : 'hide'} message={form.error} type="error" closable/>

                    <Button icon="check" htmlType="submit"> Save </Button> &nbsp;
                    <Button icon="delete" onClick={(e) => this.props.delete(e, this.props.index)}> Delete </Button>
                </Form>
                : <div className="mb_15">
                    {this.props.elements.map((el, i) => {
                        return <div key={i}>
                            {i===0
                                ? <strong>{this.props.item[el.name]}</strong>
                                : this.renderShow(el)
                            }
                        </div>
                    })}
                </div>
            }

        </div>
    };
}
export default ItemEdit;
