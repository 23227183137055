import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Table, Icon} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar from "../../../layout/Avatar";
const format = 'MMM Do YYYY';

class TasksTable extends React.Component {

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                var past = moment().startOf('day').isAfter(record.end, 'day');
                return <a href="/" className={record.finish ? "passed" : "black"}
                          onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'task', task: record.id})}>
                    <span className={past ? "red" : "black"}> {text} </span>
                    {!record.owner_accept || !record.user_accept && <i className="main">&nbsp; pending accept...</i> }
                </a>
            }
        },
        {
            title: 'Start date',
            dataIndex: 'start',
            key: 'start',
            render: (text) => {
                if(text) return <a href="/" className="black"
                                   onClick={(e)=>this.props.toggleDrawer({e:e, drawer: 'reminders', date: text})}>
                        <Icon type="clock-circle" /> {moment(text).format(format)}
                    </a>
            }
        },
        {
            title: 'End date',
            dataIndex: 'end',
            key: 'end',
            render: (text, record) => {
                if(record.finish) return <div className="green">
                    <a href="/" className="black" onClick={(e)=>this.props.toggleDrawer({e:e, drawer: 'reminders', date: record.finish})}>
                    <Icon type="clock-circle" /> {moment(record.finish).format(format)}</a> </div>;
                if(text) return <a href="/" className="black"
                                   onClick={(e)=>this.props.toggleDrawer({e:e, drawer: 'reminders', date: text})}>
                    <Icon type="clock-circle" /> {moment(text).format(format)}
                </a>
            }
        },
        {
            title: 'Created by',
            dataIndex: 'created_by',
            key: 'created_by',
            render: (text, record) => {
                return record.users.map((user) => {
                   if(user.pivot.role === 1) {
                       return <div key={user.uid}>
                           <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                               <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
                           </a>
                       </div>;
                   }
                })
            }
        },
        {
            title: 'Responsible Person',
            dataIndex: 'responsible',
            key: 'responsible',
            render: (text, record) => {
                return record.users.map((user) => {
                    if(user.pivot.role === 4) {
                        return <div key={user.uid}>
                            <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                                <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
                            </a>
                        </div>;
                    }
                })
            }
        },
        {
            title: this.props.last,
            dataIndex: this.props.last,
            key: this.props.last,
            render: (text, record) => {
                if(this.props.last === 'Price')       {
                    var total = 0;
                    record.checklist.map((item) => {
                        if(item.price)  {
                            total += parseInt(item.price);
                        }
                    });
                    if(!total) return <i>not set</i>;
                    return total;
                }
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'project', project: record.project})}>
                   {record.project}
                </a>
            }
        }
    ];

    render = () => {
        return <Table
            className="hide_2 hide_4 hide_5"
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            pagination={false} />
    };
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.task
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(TasksTable);
