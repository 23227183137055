import React from 'react';
import {Button, message, Modal, Spin} from "antd";
import api from "../../modules/api/request";
import model from "../../modules/model/bind";
import EquipmentTreeSelect from "../partials/EquipmentTreeSelect";
import PdfWorkplace from "../PDF/PdfWorkplace";
import pdf from "../../assets/images/pdf.png";
import EditField from "../partials/EditField";
const { confirm } = Modal;


class OfferContent extends React.Component   {

    state = model(this, {
        loading: false,
        edit: false,
        collapsed: false,
        addEquipment: false,
        equipment: [],
        form: {
            equipment: {type: 'select', default: []}
        }
    });

    updateEquipment = (id, field, value) => {
        let t = this;
        api.post(api.routes.offerEquipmentSingleUpdate, {id: id, field: field, value: value}).then(function (response) {
            message.success(`Updated.`);
            t.load();
        });
    };

    saveEquipment = () =>        {
        let t = this;
        api.post(api.routes.offerEquipmentUpdate, {equipment: this.state.equipment}).then(function (response) {
            message.success(`Updated.`);
            t.load();
        });
    };

    addEquipment = () => {
        let t = this;
        t.stateSet({loading: true});
        api.post(api.routes.offerEquipmentInsert, {id: this.props.offer.id, equipment: this.state.form.equipment.value}).then(function (response) {
            t.stateSet({addEquipment: false, 'form.equipment.value': []});
            message.success(`Equipment added.`);
            t.load();
        });
    };

    deleteEquipment = (id) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.offerEquipmentDelete, {data: {id: id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Deleted.`);
                        t.load();
                    });

                });
            },
            onCancel() {},
        });
    }

    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    render = () => {
        var form = this.state.form;
        const row = 'row mb_15', col1 = 'col-sm-2 text-right', col2 = 'col-sm-10';
        return <div>
            {this.state.loading
                ? <div className="spin_loader"><Spin tip="Loading data..."/></div>
                :   <div style={{position: "relative"}} id="offerContent">
                    <div>
                        <div className="row mb_15">
                            <div className="col-6">
                                <div className="mt_15">
                                    {!this.state.collapsed &&
                                    <a href="#" onClick={(e) => this.stateSet({e: e, collapsed: true})}>
                                        collapse all
                                    </a>}
                                    {this.state.collapsed &&
                                    <a href="#" onClick={(e) => this.stateSet({e: e, collapsed: false})}>
                                        open all
                                    </a>}
                                </div>
                            </div>
                            <div className="col-6 text-right">
                                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                                    {this.state.edit ? 'CANCEL EDIT' : 'EDIT OFFER'}
                                </Button>
                            </div>
                        </div>

                        {this.state.equipment.map((item, i) => {
                            return <div key={item.id} className="mb_15">
                                <div className="light_bcg border p_15">
                                    <div className="row">
                                        <div className="col-6">
                                            <h2 className="mb_5">
                                                <strong>{item.name}</strong>
                                            </h2>

                                            <div>
                                                <EditField
                                                    title="Price"
                                                    edit={this.state.edit}
                                                    form={{default: item.price}}
                                                    field="input"
                                                    onSubmit={(value) => this.updateEquipment(item.id,'price', value)}
                                                    show={this.notSet(item.price)} />
                                            </div>

                                            <div>
                                                <EditField
                                                    title="QTY"
                                                    edit={this.state.edit}
                                                    form={{default: item.qty}}
                                                    field="input"
                                                    onSubmit={(value) => this.updateEquipment(item.id, 'qty', value)}
                                                    show={this.notSet(item.qty)} />
                                            </div>
                                        </div>
                                        <div className="col-6 text-right">
                                            <Button className="strong"
                                                    onClick={() => this.deleteEquipment(item.id)}>
                                                DELETE EQUIPMENT
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                {!this.state.collapsed &&
                                <PdfWorkplace
                                    noEdit={!this.state.edit}
                                    structure={item.structure}
                                    callback={(structure) => this.stateSet({[`equipment.${i}.structure`]: structure})}
                                />}
                            </div>
                        })}
                    </div>
                    <div className="p_15 light_bcg">
                        {!this.state.addEquipment &&
                        <div className="text-center">
                        <Button className="strong" onClick={()=>this.stateSet({addEquipment: true})}>Add new products</Button>
                        </div>}
                        {this.state.addEquipment && <div>
                            <EquipmentTreeSelect field={form.equipment} parent="offerContent" />
                            <Button
                                disabled={form.equipment.value.length === 0}
                                className="strong mt_15"
                                onClick={this.addEquipment}>Add</Button>
                            <Button className="strong" onClick={()=>this.stateSet({addEquipment: false})}>Close</Button>
                        </div>}
                    </div>
                </div>}

            {this.state.edit &&
            <Button className="strong success"
                    style={{position: "fixed", bottom: 10, zIndex: 1000, left: "47%"}}
                    onClick={this.saveEquipment}>
                SAVE CHANGES
            </Button>}
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.offerEquipment + '/' + this.props.offer.id).then(function (response) {
            const eq = response.data.map((item) => {
                item.structure = item.structure ? JSON.parse(item.structure) : [];
                return item;
            })
            t.stateSet({loading: false, equipment: response.data});
        });
    };

    componentDidMount() {
        this.load();
    }
}

export default OfferContent;
