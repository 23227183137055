import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Avatar, Button, DatePicker, Form, Input, message, Select} from "antd";
import api from "../../modules/api/request";
import image from "../../assets/images/image.png";
import Items from "../partials/Items";
import FormEditor from "../../modules/editor/FormEditor";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import CompaniesSelect from "../partials/CompaniesSelect";
import CountriesSelect from "../partials/CountriesSelect";
const {Option} = Select;
const format = 'MMM Do YYYY';

class ContactCreate extends React.Component   {

    state = model(this, {
        loading: false,
        photoName: '',
        fairs: [],
        form: {
            photo: {},
            prefix: {type: 'select', validate: 'max:191', default: 'undefined'},
            prefix_new: {},
            name: {validate: 'required|max:191'},
            middlename: {validate: 'max:191'},
            lastname: {validate: 'required|max:191'},
            role: {type: 'select', default: 'undefined'},
            status: {type: 'select', default: 'Regular'},
            country_id: {type: 'select', default: 'undefined'},
            position: {validate: 'max:191'},
            company_id: {type: 'select', default: 'undefined'},
            street: {validate: 'max:191'},
            city: {validate: 'max:191'},
            postal: {validate: 'max:191'},
            birthday: {type: 'select', default: null},
            religion: {type: 'select', default: 'undefined'},
            responsible: {type: 'select', default: []},
            religion_new: {},
            notes: {},
            contact: {type: 'select', validate: 'max:191', default: 'undefined'},
            contact_new: {},

            emails: {default: []},
            phones: {default: []},
            networks: {default: []},
            fairs: {default: []},
        }
    });

    changePhoto = (e) => {
        e.preventDefault();
        let t  = this;
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                let t = this;
                var file = files[0];
                t.stateSet({photoName: file.name, 'form.photo.value': file.id});
            }});
    };

    onSubmit = (form, model) => {
        if(form.birthday)        {
            form.birthday = form.birthday.format('YYYY-MM-DD');
        }
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.contactInsert, form).then(function (response) {
            console.log(response.data)
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.toggleDrawer({drawer: 'contact'});
            t.props.drawerChanged('contact');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="contactDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Photo">
                        <a href="/" onClick={this.changePhoto}>
                            <Avatar size={90} src={this.state.photoName ? api.avatar(this.state.photoName) : image} className="avatar" />
                        </a>
                        <i>Click image to change</i>
                    </Form.Item>

                    <Form.Item label="Prefix">
                        <Select {...form.prefix} allowClear={true} placeholder="Select prefix" style={{width: 160}} getPopupContainer={trigger => document.getElementById('contactDrawerForm')}>
                            {this.props.prefixes.map((item, index) => {
                                return <Option key={index} value={item}> {item} </Option>
                            })}
                        </Select> &nbsp; or &nbsp;
                        <Input  {...form.prefix_new} placeholder="type in new" size="small"  style={{width: 160}} />
                    </Form.Item>

                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Middle name" validateStatus={form.middlename.error && 'error'} help={form.middlename.error}>
                        <Input maxLength={191} type="text" {...form.middlename} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Lastname" validateStatus={form.lastname.error && 'error'} help={form.lastname.error}>
                        <Input maxLength={191} type="text" {...form.lastname} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Birhday" layout="horizontal">
                        <DatePicker {...form.birthday} format={format} />
                    </Form.Item>

                    <Form.Item label="Religion">
                        <Select placeholder="Select religion" {...form.religion} allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('contactDrawerForm')}>
                            {this.props.religions.map((item, index) => {
                                return <Option key={index} value={item}> {item} </Option>
                            })}
                        </Select> &nbsp; or &nbsp;
                        <Input  {...form.religion_new} placeholder="add new" size="small"  style={{width: 160}} />
                    </Form.Item>
                </div>

                <Form.Item label="Role">
                    <Select {...form.role} allowClear={true} placeholder="Select role" style={{width: 160}} getPopupContainer={trigger => document.getElementById('contactDrawerForm')}>
                        {this.props.roles.map((item, index) => {
                            return <Option key={index} value={item}> {item} </Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Status">
                    <Select placeholder="Select status" {...form.status} style={{width: 160}} getPopupContainer={trigger => document.getElementById('contactDrawerForm')}>
                        <Option value="Regular"> Regular </Option>
                        <Option value="VIP"> VIP </Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Company">
                    <CompaniesSelect field={form.company_id} parent="contactDrawerForm" />
                </Form.Item>

                <Form.Item label="Position (job title)" validateStatus={form.position.error && 'error'} help={form.position.error}>
                    <Input maxLength={191} type="text" {...form.position} autoComplete="off" size="small" />
                </Form.Item>


                <Items title="E-mails"  elements={[{name: 'email'}, {name: 'description', type: 'textarea'}]}
                       return={(items) => this.stateSet({'form.emails.value': items})} />

                <Items title="Phones"  elements={[{name: 'phone'}, {name: 'description', type: 'textarea'}]}
                       return={(items) => this.stateSet({'form.phones.value': items})} />

                <Items title="Social networks"  elements={[{name: 'name'}, {name: 'link'}]}
                       return={(items) => this.stateSet({'form.networks.value': items})} />


                <Form.Item label="Street address" validateStatus={form.street.error && 'error'} help={form.street.error}>
                    <Input maxLength={191} type="text" {...form.street} autoComplete="off" size="small" />
                </Form.Item>

                <Form.Item label="City" validateStatus={form.city.error && 'error'} help={form.city.error}>
                    <div className="row">
                        <div className="col-sm-3">
                            <Input maxLength={191} placeholder="Postal code" type="text" {...form.postal} autoComplete="off" size="small" />
                        </div>
                        <div className="col-sm-9">
                            <Input maxLength={191} placeholder="City name" type="text" {...form.city} autoComplete="off" size="small" />
                        </div>
                    </div>
                </Form.Item>

                <Form.Item label="Country">
                    <CountriesSelect field={form.country_id} parent="contactDrawerForm" />
                </Form.Item>


                <Form.Item label="General notes" validateStatus={form.notes.error && 'error'} help={form.notes.error}>
                    <FormEditor getValue={(value) => this.stateSet({['form.notes.value']: value})} />
                </Form.Item>

                <Form.Item label="Responsible person" layout="horizontal">
                    <PersonTreeSelect field={form.responsible} parent="contactDrawerForm" />
                </Form.Item>

                <Form.Item label="First contact">
                    <Select  placeholder="Select" {...form.contact} allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('contactDrawerForm')}>
                        {this.props.contacts.map((item, index) => {
                            return <Option key={index} value={item}> {item} </Option>
                        })}
                    </Select> &nbsp; or &nbsp;
                    <Input  {...form.contact_new} placeholder="add new" size="small"  style={{width: 160}} />
                </Form.Item>

                <Items
                    title="Attended fairs"
                    elements={[{name: 'fair', type: 'select', options: this.state.fairs, placeholder: 'Select fair', default: 'undefined'},
                        {name: 'create', placeholder: 'or type in new'}, {name: 'description', type: 'textarea'}]}
                    return={(items) => this.stateSet({'form.fairs.value': items})} />

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable/>
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="contactDrawerForm" loading={this.state.loading}> Save contact </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'contact'})}>Close</Button>
            </div>
        </div>
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.fairs).then(function (response) {
            t.stateSet({fairs: response.data});
        });
    }
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactCreate);
