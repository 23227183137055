import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox, Icon, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import AuthView from './authView';
import { Row, Col } from 'antd';
import Google from '../controllers/GoogleController';
import Facebook from '../controllers/FacebookController';
import routes from "../routes";


function loginView({onSubmit, email, password, remember, error, success, loading})   {
    return (
        <AuthView>
            <form action="#" onSubmit={onSubmit}>
                <Form.Item validateStatus={email.error ? 'error' : 'success'} help={email.error}>
                    <Input maxLength={191} prefix={<Icon type="mail" />} type="email" {...email} placeholder="email" />
                </Form.Item>

                <Form.Item validateStatus={password.error ? 'error' : 'success'} help={password.error}>
                    <Input.Password prefix={<Icon type="lock" />} {...password} placeholder="password" />
                </Form.Item>

                <Row>
                    <Col md={12}>
                        <Checkbox className="mb_15" {...remember} value={1}>Remember me</Checkbox>
                    </Col>
                    <Col md={12} className="text-right">
                        <Link to={routes.password}>Forgot password?</Link>
                    </Col>
                </Row>
                <div className="mb_15">
                    <Button type="primary" htmlType="submit" loading={loading} className="mb_15" block> Log in </Button>
                    <Alert className={error ? 'show' : 'hide'} message={error} type="error" closable/>
                    <Alert className={success ? 'show' : 'hide'} message={success} type="success" closable/>
                </div>
                <Google />
                <Facebook />

                <Link to={routes.register}>Register new account?</Link>
            </form>
        </AuthView>
    )
}

loginView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    email: PropTypes.object.isRequired,
    password: PropTypes.object.isRequired,
    remember: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
};

export default loginView;
