import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import CountryCreate from "./CountryCreate";
import CountryShow from "./CountryShow";


class CountryDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        country: false,
        create: false,
        regions: [{name: "Europe", code: "EU"}, {name: "Asia and Pacific", code: "APAC"},
            {name: "Middle East & North Africa", code: "MENA"},{name: "Latin America", code: "LA"},
            {name: "North America", code: "NA"},{name: "Commonwealth of Independent States", code: "CIS"}, {name: "Africa", code: "AF"}],
        timezones: [
            {id: "GMT", name: "GMT Greenwich Mean Time"},
            {id: "GMT+1:00", name: "GMT+1:00 European Central Time"},
            {id: "GMT+2:00", name: "GMT+2:00 Eastern European Time"},
            {id: "GMT+3:00", name: "GMT+3:00 Eastern African Time"},
            {id: "GMT+3:30", name: "GMT+3:30 Middle East Time"},
            {id: "GMT+4:00", name: "GMT+4:00 Near East Time"},
            {id: "GMT+5:00", name: "GMT+5:00 Pakistan Lahore Time"},
            {id: "GMT+5:30", name: "GMT+5:30 India Standard Time"},
            {id: "GMT+6:00", name: "GMT+6:00 Bangladesh Standard Time"},
            {id: "GMT+7:00", name: "GMT+7:00 Vietnam Standard Time"},
            {id: "GMT+8:00", name: "GMT+8:00 China Taiwan Time"},
            {id: "GMT+9:00", name: "GMT+9:00 Japan Standard Time"},
            {id: "GMT+9:30", name: "GMT+9:30 Australia Central Time"},
            {id: "GMT+10:00", name: "GMT+10:00 Australia Eastern Time"},
            {id: "GMT+11:00", name: "GMT+11:00 Solomon Standard Time"},
            {id: "GMT+12:00", name: "GMT+12:00 New Zealand Standard Time"},
            {id: "GMT-11:00", name: "GMT-11:00 New Zealand Standard Time"},
            {id: "GMT-10:00", name: "GMT-10:00 Hawaii Standard Time"},
            {id: "GMT-9:00", name: "GMT-9:00 Alaska Standard Time"},
            {id: "GMT-8:00", name: "GMT-8:00 Pacific Standard Time"},
            {id: "GMT-7:00", name: "GMT-7:00 Phoenix Standard Time"},
            {id: "GMT-6:00", name: "GMT-6:00 Central Standard Time"},
            {id: "GMT-5:00", name: "GMT-5:00 Eastern Standard Time"},
            {id: "GMT-4:00", name: "GMT-4:00 Puerto Rico and US Virgin Islands Time"},
            {id: "GMT-3:30", name: "GMT-3:30 Canada Newfoundland Time"},
            {id: "GMT-3:00", name: "GMT-3:00 Argentina Standard Time"},
            {id: "GMT-1:00", name: "GMT-1:00 Central African Time"},
        ],
    });

    render = () => {
        return  <Drawer
                    visible={this.state.status}
                    width="50%"
                    zIndex={997}
                    title="COUNTRY"
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'country'})}>

            {!this.state.loading
                ? <div>
                    {this.state.country &&
                    <CountryShow
                        country={this.state.country}
                        regions={this.state.regions}
                        timezones={this.state.timezones}
                    />}
                    {this.state.create &&
                    <CountryCreate
                        regions={this.state.regions}
                        timezones={this.state.timezones}
                    />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.country + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, country: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, country: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, country: false, create: false});
        }
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.country
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(CountryDrawer);
