import React from 'react';
import {Calendar, Icon, Spin} from "antd";
import {connect} from "react-redux";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind";
import {toggleDrawer} from "../../../store/actions/drawersActions";
const dateFormat = 'YYYY-MM-DD';

class CalendarComponent extends React.Component {

    state = model(this, {
        loading: true,
        reminders: [],
        tasks: []
    });

    onSelect = value => {
        this.props.toggleDrawer({drawer: 'reminders', date: value.format(dateFormat)});
    };

    renderTitle = (item) => {
        switch (item.type) {
            case 'info':
                return  <span className="info"><Icon type="info-circle" /> {item.title} </span>;
            case 'alert':
                return <span className="alert"><Icon type="exclamation-circle" /> {item.title} </span>;
            case 'warning':
                return <span className="warning"><Icon type="warning" /> {item.title} </span>;
            default:
                return <span><Icon type="right-circle" /> {item.title} </span>
        }
    };

    dateCellRender = (value) => {
        var date = value.format(dateFormat);
        return (
            <div>
                {this.state.reminders.map(item => (
                    item.date === date &&
                    <div key={item.id}>
                        {this.renderTitle(item)}
                    </div>
                ))}
                {this.state.tasks.map(item => (
                    item.end === date &&
                    <div key={item.id}>
                        <span className="info"><Icon type="info-circle" /> TASK ENDS: {item.name} </span>
                    </div>
                ))}
            </div>
        );
    };

    render = () => {
        return <div className="dashboard_block">
                <div className="m_15">
                    <Spin spinning={this.state.loading}>
                        <Calendar
                            onSelect={this.onSelect}
                            dateCellRender={this.dateCellRender} />
                    </Spin>
                </div>
            </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.reminders).then(function (response) {
            t.stateSet({loading: false, reminders: response.data.reminders, tasks: response.data.tasks});
        });
    };

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.reminders
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
