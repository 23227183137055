import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Button, Input, Radio, Select, Skeleton} from "antd";
import TemplatesDropdown from "./TemplatesDropdown";
import GanttTable from "./GanttTable";
import model from "../../../modules/model/bind";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import api from "../../../modules/api/request";
import TasksTable from "./TasksTable";
import {Link} from "react-router-dom";
const { Option } = Select;
const format = 'MMM Do YYYY';

class Tasks extends React.Component {


    state = model(this, {
        loading: false,
        tasks: [],
        form: {
            search: {},
            user: {type: 'select', default: 'all'},
            status: {type: 'select', default: 1},
            mode: {default: 'list'},
            gantt: {type: 'select', default: 'Day'}
        }
    });

    links = [
        {link: '/tasks', name: 'Created by me', title: 'Created by me', role: 1},
        {link: '/tasks/responsible', name: 'Responsible for', title: 'Responsible for', role: 4},
        {link: '/tasks/participant', name: 'Participate in', title: 'Participate in', role: 2},
        {link: '/tasks/observer', name: 'Observed by me', title: 'Observed by me', role: 3},
        {link: '/tasks/all', name: 'All tasks', title: 'All tasks'},
    ];

    filtered = () => {
        var filtered = this.state.tasks.filter((item) => {
            if(this.state.form.search.value)       {
                if(!item.name.toLowerCase().includes(this.state.form.search.value.toLowerCase()))        {
                    return false;
                }
            }
            if(this.state.form.user.value !== 'all')       {
                var ret = false;
                item.users.map((user) => {
                    if(user.uid === this.state.form.user.value) ret = true;
                });
                if(!ret) return false;
            }
            if(this.state.form.status.value === 4)       {
                return !!item.finish;
            }
            else if(this.state.form.status.value === 1)       {
                return true;
            }
            else if(this.state.form.status.value === 2)       {
                if(item.finish)     return false;
                if(!item.start)     return true;
                return moment().startOf('day').isBefore(item.start, 'day');
            }
            else if(this.state.form.status.value === 3)       {
                if(item.finish)     return false;
                if(!item.start)     return false;
                return moment().startOf('day').isBetween(item.start, item.end, 'day', []);
            }
            return true;
        });
        var users = [];
        this.state.tasks.map((item) => {
            if(item.role === 1 || item.role === 4)  {
                item.users.map((user) => {
                    if(!users[user.uid])     {
                        users[user.uid] = {uid: user.uid, name: user.name, count: 1};
                    }
                    else    {
                        users[user.uid].count = users[user.uid].count + 1;
                    }
                });
            }
        });
        return {users: users, table: filtered};
    };

    render = () => {
        var filtered = this.filtered();
        var pathname = this.props.pathname;
        return <div>
            <div className="tasks_header dashboard_block">
                {this.links.map(function (link) {
                    return <Link to={link.link} className={pathname === link.link ? 'active' : ''} key={link.link}> {link.name} </Link>
                })}
            </div>
            <div className="dashboard_title">
                {this.links.map(function (link) {
                    return pathname === link.link && link.title
                })}
            </div>

            {this.props.user.privileges.includes(203) &&
            <div className="text-right mb_15">
                <TemplatesDropdown /> &nbsp;
                <Button onClick={() => this.props.toggleDrawer({drawer: 'task'})} className="success" icon="plus">
                    ADD TASK
                </Button>
            </div>}

            <div className="dashboard_block">
                <div className="m_15">
                    <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="search by name" />

                    &nbsp; Tasks: &nbsp;
                    <Select style={{ width: 120 }} {...this.state.form.status}>
                        <Option value={1}>all</Option>
                        <Option value={2}>pending</Option>
                        <Option value={3}>in progress</Option>
                        <Option value={4}>finished</Option>
                    </Select>

                    {filtered.users.length > 0 &&
                    <span> &nbsp; for: &nbsp;
                        <Select style={{ width: 160 }} {...this.state.form.user}>
                            <Option value="all"> all </Option>
                            {filtered.users.map((user) => {
                                return <Option key={user.uid} value={user.uid}> {user.name} ({user.count}) </Option>
                            })}
                        </Select>
                    </span>}


                    {this.state.form.mode.value === 'gantt'
                    && <label>
                        &nbsp; &nbsp; Gantt: &nbsp;
                        <strong>
                            <Select {...this.state.form.gantt} style={{ width: 100 }}>
                                <Option value="Day">Days</Option>
                                <Option value="Week">Weeks</Option>
                                <Option value="Year">Years</Option>
                            </Select>
                        </strong>
                    </label>}

                    <div className="pull-right">
                        <Radio.Group {...this.state.form.mode} buttonStyle="solid">
                            <Radio.Button value="list">List</Radio.Button>
                            <Radio.Button value="gantt">Gantt</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>

                {!this.state.loading
                   ? this.state.form.mode.value === 'gantt'
                        ? <GanttTable tasks={filtered.table} mode={this.state.form.gantt.value}  />
                        : <TasksTable last="Project" data={filtered.table}  />
                    : <Skeleton className="p_15" active={true} />
                }
            </div>
        </div>
    };

    load = () => {
        let t = this;
        var url = api.routes.tasks;
        this.links.map((item) => {
           if(item.link === this.props.pathname) {
               if(item.role) url += '/' + item.role;
           }
        });
        t.stateSet({loading: true, tasks: [], 'form.user.value': 'all'});
        api.get(url).then(function (response) {
            console.log(response.data)
            t.stateSet({loading: false, tasks: response.data});
        });
    };
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.pathname !== prevProps.pathname)      {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.task
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
