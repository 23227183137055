import React from 'react';
import Tools from "./Tools";

class FormEditor extends React.Component {

    render = () => {
        return <div className="editor_editable">
                <div contentEditable={true}
                     className="mb_15"
                     onBlur={(e) => this.props.getValue(e.target.innerHTML)}
                     dangerouslySetInnerHTML={{__html: this.props.value}} />

                <Tools />
            </div>
    };

}

export default FormEditor;
