import React from 'react';
import {Button, message, Modal, Select, Tabs, Timeline, Icon, Input, Spin} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import Avatar from "../../layout/Avatar";
import moment from "moment";
import update from "react-addons-update";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { confirm } = Modal;
const format = 'MMM Do YYYY, HH:mm:ss';

class OfferHistory extends React.Component   {

    state = model(this, {
        add: false,
        history: [],
        loading: false,
        form: {
            comment: {}
        }
    });

    save = () => {
        let t = this;
        api.post(api.routes.offerHistoryInsert, {id: this.props.offer.id, comment: this.state.form.comment.value}).then(function (response) {
            message.success(`Comment inserted.`);
            const newData = update(t.state,{history: {$push: [response.data]}, add: {$set: false}, form: {comment: {value: {$set: ''}}}}  );
            t.setState(newData);
        });
    };

    render = () => {
        var offer = this.props.offer;
        return <div>
            {this.state.loading
                ? <div className="spin_loader"><Spin tip="Loading history..."/></div>
                : <Timeline mode="alternate" reverse={true}>
                    {this.state.history.map((item, index) => {
                        var pos = item.user_uid !== this.props.offer.owner.uid ? 'left' : 'right';
                        return <Timeline.Item
                            position={pos}
                            key={index}
                            color="green"
                            dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
                            <div className="mb_15">
                                <i>{moment.utc(item.created_at).utcOffset('+02:00').format(format)}</i>
                            </div>
                            {item.user &&
                            <div>
                                <Avatar name={item.user.name} avatar={item.user.avatar_name} /> {item.user.name}
                            </div>}
                            <div className="pre_wrap">{item.comment}</div>
                        </Timeline.Item>
                    })}

                    <Timeline.Item
                        position={offer.owner.uid !== this.props.user.uid ? 'left' : 'right'}
                        dot={<Button
                            icon="edit"
                            onClick={() => this.stateSet({add: !this.state.add})}
                            style={{ fontSize: '18px' }} />}>
                        {this.state.add &&
                        <div>
                            <TextArea autosize={{minRows: 3}} {...this.state.form.comment} className="mb_5" placeholder="Insert message..." />
                            <Button onClick={this.save}>Save</Button> &nbsp;
                            <Button onClick={() => this.stateSet({add: !this.state.add})}>Close</Button>
                        </div>}
                        &nbsp;
                        &nbsp;
                    </Timeline.Item>
                </Timeline>}
        </div>
    };

    componentDidMount() {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.offerHistory + '/' + this.props.offer.id).then(function (response) {
            t.stateSet({loading: false, history: response.data});
        });
    }
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(OfferHistory);
