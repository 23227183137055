import React from 'react';
import '../../../assets/frappe-gantt.css'
import Gantt from  '../../../assets/frappe-gantt';
import moment from 'moment';
const format = 'MMM Do YYYY';

class GanttTable extends React.Component {

    gantt = false;

    state ={
        mode: 'Day',
        empty: false
    }

    render = () => {
        return <div>
            <svg id="gantt"> </svg>
        </div>
    };

    getTasks = () => {
        var ganttArr = [];
        this.props.tasks.map((item) => {
            var obj = {};
            obj.id = String(item.id);
            obj.name = item.name;
            obj.progress = 0;
            if(item.checklist && item.checklist.length > 0)      {
                var all = item.checklist.length;
                var checked = 0;
                item.checklist.map((chck) => {
                    if(chck.checked) checked++;
                });
                obj.progress = Math.ceil((checked * 100) / all);
                obj.name += ' ('+ obj.progress +'%)';
            }
            if(item.depend) obj.dependencies = String(item.depend);
            if(item.start) obj.start = item.start;
            else return;
            if(item.end) obj.end = item.end;
            else return;
            if(item.finish) obj.end = item.finish;

            ganttArr.push(obj);
        });
        return ganttArr;
    };

    setGantt = (ganttArr) => {
        this.gantt = new Gantt("#gantt", ganttArr, {
            custom_popup_html: function(task) {
                return `<div class="details-container">
                          <h5>${task.name}</h5>
                          <p>Expected to finish by ${moment.utc(task.end).format(format)}</p>
                          <p>${task.progress}% completed!</p>
                        </div>`;
            },
        });
    };

    componentDidMount() {
        const ganttArr = this.getTasks();
        if(ganttArr.length === 0) return;
        this.setGantt(ganttArr);
        this.gantt.change_view_mode(this.props.mode);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.tasks !== prevProps.tasks)      {
            const ganttArr = this.getTasks();
            if(ganttArr.length > 0) {
                if(!this.gantt) {
                    this.setGantt(ganttArr);
                }
                else    {
                    this.gantt.refresh(ganttArr);
                }
            }
            else    {
                if(this.gantt) {
                    this.gantt.clear();
                }
            }
        }
        if(this.props.mode !== prevProps.mode)      {
            const ganttArr = this.getTasks();
            if(ganttArr.length > 0 && this.gantt) this.gantt.change_view_mode(this.props.mode)
        }
    }
}

export default GanttTable;
