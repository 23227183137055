import React from 'react';
import {connect} from "react-redux";
import {Button, message, Modal} from "antd";
import api from "../../modules/api/request";
import {drawerChanged} from "../../store/actions/drawersActions";
const { confirm } = Modal;

class ProtectedMiddleware extends React.Component   {

    request = (e, role) => {
        e.preventDefault();
        let t = this;
        confirm({
            title: 'Ask to join?',
            onOk() {
                return new Promise((resolve, reject) => {
                    if(t.props.project) {
                        api.post(api.routes.projectUserRequest, {id: t.props.project, role: role}).then(function (response) {
                            message.success(`Request sent.`);
                            t.props.drawerChanged('project');
                            setTimeout(resolve, 10);
                        });
                    }
                    if(t.props.task) {
                        console.log(t.props.task)
                        api.post(api.routes.taskUserRequest, {id: t.props.task, role: role}).then(function (response) {
                            message.success(`Request sent.`);
                            t.props.drawerChanged('task');
                            setTimeout(resolve, 10);
                        });
                    }
                });
            },
            onCancel() {},
        });
    };

    join = () => {
        let t = this;
        confirm({
            title: 'Do you want to join?',
            onOk() {
                return new Promise((resolve, reject) => {
                    if(t.props.project) {
                        api.post(api.routes.projectUserJoin, {id: t.props.project}).then(function (response) {
                            message.success(`Joined Project.`);
                            t.props.drawerChanged('project');
                            setTimeout(resolve, 10);
                        });
                    }
                    if(t.props.task) {
                        api.post(api.routes.taskUserJoin, {id: t.props.task}).then(function (response) {
                            message.success(`Joined Task.`);
                            t.props.drawerChanged('task');
                            setTimeout(resolve, 10);
                        });
                    }
                });
            },
            onCancel() {},
        });
    };

    render = () => {
        if(!this.props.role) {
            return <div className="text-center p_15">
                <div className="mb_5"> You dont have permissions: </div>
                <div className="mb_5"><a href="/" onClick={(e) => this.request(e, 2)}>Send request to owner for participant.</a></div>
                <a href="/" onClick={(e) => this.request(e, 3)}>Send request to owner for observer.</a>
            </div>
        }
        if(!this.props.user_accept)     {
            return <div className="text-center p_15">
                You are invited as &nbsp;
                {this.props.role === 1 && <strong>owner</strong>}
                {this.props.role === 2 && <strong>participant</strong>}
                {this.props.role === 3 && <strong>observer</strong>}
                {this.props.role === 4 && <strong>responsible person</strong>}
                <br /><br />
                <Button className="strong" onClick={this.join}> Join </Button>
            </div>
        }
        if(!this.props.owner_accept)     {
            return <div className="text-center p_15"> Owner needs to accept you. </div>
        }
        return this.props.children;
    };

}
const mapStateToProps = state => ({
    departments: state.departments
});
const mapDispatchToProps = {
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedMiddleware);
