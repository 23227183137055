import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Avatar, Button, DatePicker, Form, Input, message, Select, TimePicker} from "antd";
import api from "../../modules/api/request";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import CountriesSelect from "../partials/CountriesSelect";
import ContactSelect from "../partials/ContactSelect";
const {Option} = Select;
const {TextArea} = Input;
const timeFormat = 'HH:mm';
const format = 'MMM Do YYYY';

class VisitCreate extends React.Component   {

    state = model(this, {
        loading: false,
        form: {
            contact: {type: 'select', default: 'undefined'},
            responsible: {type: 'select', default: []},

            arrival_by: {type: 'select', default: 'undefined'},
            arrival_flight_number: {type: 'max:191'},
            arrival_from: {type: 'select', default: 'undefined'},
            arrival_city: {type: 'max:191'},
            arrival_date: {type: 'select', default: 'undefined'},
            arrival_time: {type: 'select', default: 'undefined'},
            arrival_details: {type: 'max:191'},
            hotel: {type: 'select', default: "No"},

            hotel_in: {type: 'select', default: 'undefined'},
            hotel_paid: {type: 'select', default: 'undefined'},
            food: {type: 'max:2000'},
            departure_by: {type: 'select', default: 'undefined'},

            departure_flight_number: {type: 'max:191'},
            departure_to: {type: 'select', default: 'undefined'},
            departure_city: {type: 'max:191'},
            departure_date: {type: 'select', default: 'undefined'},
            departure_time: {type: 'select', default: 'undefined'},

        }
    });

    onSubmit = (form, model) => {
        this.stateSet({loading: true});
        let t = this;
        if(form.arrival_date)        form.arrival_date = form.arrival_date.format('YYYY-MM-DD');
        if(form.arrival_time)        form.arrival_time = form.arrival_time.format(timeFormat);
        if(form.departure_date)      form.departure_date = form.departure_date.format('YYYY-MM-DD');
        if(form.departure_time)      form.departure_time = form.departure_time.format(timeFormat);
        api.post(api.routes.visitInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.toggleDrawer({drawer: 'visit'});
            t.props.drawerChanged('visit');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="visitDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Contact" layout="horizontal">
                        <ContactSelect field={form.contact} parent="visitDrawerForm" />
                    </Form.Item>


                    <Form.Item label="Responsible person" layout="horizontal">
                        <PersonTreeSelect field={form.responsible} parent="visitDrawerForm" />
                    </Form.Item>
                </div>

                <h3 className="text-center">Arrival</h3>

                <Form.Item label="Arrival">
                    <Select  placeholder="Select" {...form.arrival_by} allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('visitDrawerForm')}>
                        <Option value="plane"> by plane </Option>
                        <Option value="car"> by car </Option>
                    </Select>

                    &nbsp; &nbsp;
                    <Input maxLength={191} placeholder="Flight number" size="small" style={{width: 120}}
                          disabled={form.arrival_by.value !== 'plane'} type="text" {...form.arrival_flight_number} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Coming from">
                    <CountriesSelect field={form.arrival_from} parent="visitDrawerForm" />
                </Form.Item>


                <Form.Item label="City" validateStatus={form.arrival_city.error && 'error'} help={form.arrival_city.error}>
                    <Input maxLength={191} size="small" type="text" {...form.arrival_city} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Expected arrival">
                    <DatePicker {...form.arrival_date} format={format} /> &nbsp;
                    <TimePicker {...form.arrival_time} format={timeFormat} />
                </Form.Item>

                <Form.Item label="Visit details" validateStatus={form.arrival_details.error && 'error'} help={form.arrival_details.error}>
                    <TextArea maxLength={191} size="small" type="text" {...form.arrival_details} autoComplete="off" />
                </Form.Item>


                <h3 className="text-center"> Accomodation </h3>


                <Form.Item label="Hotel reservation required">
                    <Select  placeholder="Select" {...form.hotel} allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('visitDrawerForm')}>
                        <Option value="Yes"> Yes </Option>
                        <Option value="No"> No </Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Hotel at">
                    <Select  placeholder="Select" {...form.hotel_in} disabled={form.hotel.value !== "Yes"}
                             allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('visitDrawerForm')}>
                        <Option value="Belgrade"> Belgrade </Option>
                        <Option value="Čačak"> Čačak </Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Hotel paid by">
                    <Select  placeholder="Select" {...form.hotel_paid} disabled={form.hotel.value !== "Yes"}
                             allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('visitDrawerForm')}>
                        <Option value="Stax"> Stax </Option>
                        <Option value="client"> client </Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Food comment" validateStatus={form.food.error && 'error'} help={form.food.error}>
                    <TextArea maxLength={191} size="small" type="text" {...form.food} autoComplete="off" />
                </Form.Item>

                <h3 className="text-center">Departure</h3>

                <Form.Item label="Departure">
                    <Select  placeholder="Select" {...form.departure_by} allowClear={true} style={{width: 160}} getPopupContainer={trigger => document.getElementById('visitDrawerForm')}>
                        <Option value="plane"> by plane </Option>
                        <Option value="car"> by car </Option>
                    </Select>

                    &nbsp; &nbsp;
                    <Input maxLength={191} placeholder="Flight number" size="small" style={{width: 120}}
                           disabled={form.departure_by.value !== 'plane'} type="text" {...form.departure_flight_number} autoComplete="off" />

                </Form.Item>

                <Form.Item label="Departure to">
                    <CountriesSelect field={form.departure_to} parent="visitDrawerForm" />
                </Form.Item>


                <Form.Item label="City" validateStatus={form.departure_city.error && 'error'} help={form.departure_city.error}>
                    <Input maxLength={191} size="small" type="text" {...form.departure_city} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Expected departure">
                    <DatePicker {...form.departure_date} format={format} /> &nbsp;
                    <TimePicker {...form.departure_time} format={timeFormat} />
                </Form.Item>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable />
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="visitDrawerForm" loading={this.state.loading}> Save visit </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'visit'})}>Close</Button>
            </div>
        </div>
    };

    componentDidMount() {

    }
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(VisitCreate);
