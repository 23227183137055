import React from 'react';
import {connect} from "react-redux";
import {toggleDrawer} from "../../store/actions/drawersActions";
import model from "../../modules/model/bind";
import {Drawer, Skeleton} from "antd";
import TaskEdit from "./TaskEdit";
import TaskShow from "./TaskShow";
import api from "../../modules/api/request";

class TaskDrawer extends React.Component   {

    state = model(this, {
        loading: false,
        status: false,
        create: false,
        task: false,
        template: false
    });

    render = () => {
        return  <Drawer
            visible={this.state.status}
            zIndex={997}
            width="80%"
            title="TASK"
            placement="right"
            closable={true}
            onClose={() => this.props.toggleDrawer({drawer: 'task'})}>

            {!this.state.loading
                ? <div>
                    {this.state.task && <TaskShow task={this.state.task} />}
                    {this.state.create && <TaskEdit />}
                    {this.state.template && <TaskEdit />}
                </div>
               : <Skeleton active />
            }

        </Drawer>
    };


    load = () => {
        if(this.props.drawer.task)  {
            let t = this;
            t.stateSet({loading: true, status: t.props.drawer.status});
            api.get(api.routes.task + '/' + this.props.drawer.task).then(function (response) {
                t.stateSet({loading: false, task: response.data, create: false, template: false});
            });
        }
        else if(this.props.drawer.template)  {
            this.stateSet({status: this.props.drawer.status, loading: false, task: false, create: false, template: this.props.drawer.template});
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, task: false, create: true, template: false});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, task: false, create: false, template: false});
        }
    };

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.task
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskDrawer);
