import React from 'react';
import {Menu, Icon, Dropdown, Button} from "antd";
import {connect} from "react-redux";
import {logoutUser} from "../modules/auth/userProvider";
import Avatar from "./Avatar";
import logotype from "../assets/images/logotype.png";
import logo from "../assets/images/logo.png";
import {toggleDrawer} from "../store/actions/drawersActions";
import NotificationsDrawer from "../drawers/notifications/NotificationsDrawer";
import SettingsDrawer from "../drawers/settings/settingsDrawer";

class Header extends React.Component   {

    profileMenu = () => (
        <Menu>
            <Menu.Item key="0">
                <a href="/" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: this.props.user.uid})}>
                    My Profile
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1" onClick={logoutUser}>Logout</Menu.Item>
        </Menu>
    );

    render = () => {
        return  <header className="dashboard_header">
            <div className="row marginless">
                    <div className="col">
                        <img src={logotype} height={28} alt="logo" className="responsive_hide" />
                        <img src={logo} height={28} alt="logo" className="responsive_show" />
                    </div>
                    <div className="col">
                        <div className="dashboard_menu_user">
                            <Dropdown placement="bottomRight" overlay={this.profileMenu} trigger={['click']}>
                                <Button className="ant-dropdown-link" type="link">
                                    <Avatar avatar={this.props.user.avatar_name} name={this.props.user.name} size="small" />
                                    <span className="responsive_hide"> {this.props.user.name}</span> <Icon type="down" />
                                </Button>
                            </Dropdown>
                            &nbsp;
                            &nbsp;

                            <SettingsDrawer />
                            <NotificationsDrawer />

                        </div>
                    </div>
                </div>

            </header>
    }
}

const mapStateToProps = state =>    ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
