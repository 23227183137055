import React from 'react';
import {connect} from 'react-redux';
import {Table} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar from "../../../layout/Avatar";

class CountriesTable extends React.Component {

    columns = [
        {title: 'Flag', dataIndex: 'flag', key: 'flag', width: 60,
            render: (text, record) => <Avatar avatar={record.flag_name} name={record.name} size="small"  />},

        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: record.id})}>
                    {text}
                </a>
            }
        },

        {title: 'Region', dataIndex: 'region', key: 'region',
            render: (text, record) => {
                return <div>
                     {text} {record.region_code}
                </div>
            }},
        {title: 'Timezone', dataIndex: 'timezone', key: 'timezone'},
        {title: 'Langugae', dataIndex: 'language', key: 'language'},
        {title: 'Currency', dataIndex: 'currency', key: 'currency'},

    ];

    render = () => {
        return <Table
            className="td_1_100"
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            pagination={{pageSize: 50}} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(CountriesTable);
