import React from 'react';
import {Row, Card} from "antd";
import logo from "../../../assets/images/logo.png";

function authView(props) {
    return (
        <Row className="auth_layout" type="flex" justify="center" align="middle">
            <Card style={{width: 380}}>
                <img src={logo} className="auth_logo" alt="logo"  />
                {props.children}
            </Card>
        </Row>
    )
}

export default authView;
