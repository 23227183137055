import React from 'react';
import {Alert, Button} from "antd";
import update from "react-addons-update";
import api from "../../api/request";
import {loginUser} from "../userProvider";

class GoogleController extends React.Component   {

    constructor(props)      {
        super(props);
        this.state = {error: '', loading: false};
    }

    server = (googleUser) => {
        var idToken = googleUser.getAuthResponse().id_token;
        var userId = googleUser.getId();
        const newData = update(this.state,{loading: {$set: true} });
        this.setState(newData);
        let t = this;
        api.post(api.routes.google, {id_token: idToken, user_id: userId, register: this.props.register}).then(function (response) {
            const newData = update(t.state,{loading: {$set: false}, success: {$set: response.data}, error: {$set: ''} });
            t.setState(newData);
            loginUser(response);

        }).catch(function (error) {
            const newData = update(t.state,{loading: {$set: false}, error: {$set: error} });
            t.setState(newData);
        });
    };


    render = () => {
        return <div className="mb_15">
            <Button type="default" icon="google" id="googleBtn" className="google_btn" loading={this.state.loading} block>
                {this.props.register ? 'Register' : 'Log in'} with Google
            </Button>
            <Alert className={this.state.error ? 'show mt_15' : 'hide mt_15'} message={this.state.error} type="error" closable/>
            <Alert className={this.state.success ? 'show mt_15' : 'hide mt_15'} message={this.state.success} type="success" closable/>
        </div>
    };

    componentDidMount() {
        window.gapi.load('auth2', () => {
            const auth2 = window.gapi.auth2.init({
                client_id: '739803745067-vipqcq076lq2u4q5f03lkctoa49p8vfa.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin'
            });
            auth2.attachClickHandler(document.getElementById('googleBtn'), {},
                this.server,
                function (error) {
                console.log(JSON.stringify(error, undefined, 2))
            });
        })
    }
}

export default GoogleController;
