import React from 'react';
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
import {Alert, Button, Form, Input, message, Modal, Skeleton} from "antd";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import EditField from "../partials/EditField";
import Users from "../partials/Users";
const { confirm } = Modal;

class FolderEdit extends React.Component {

    state = model(this, {
        status: false,
        loading: false,
        folder: false
    });

    open = () => {
        let t = this;
        this.stateSet({status: true, loading: true});
        api.get(api.routes.filesFolder+'/'+this.props.folder).then(function (response) {
            t.stateSet({loading: false, folder: response.data});
        });
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.filesFolderUpdate, {id: this.props.folder, field: field, value: value}).then(function (response) {
            message.success(`Folder updated.`);
            t.props.drawerChanged('files');
        });
    };

    addUsers = (ids) => {
        if(ids.length === 0) return;
        let t = this;
        api.post(api.routes.filesFolderUserAdd, {ids: ids, folder: this.props.folder}).then(function (response) {
            message.success(`User added.`);
            t.props.drawerChanged('files');
        });
    };

    deleteUser = (id) => {
        let t = this;
        confirm({
            title: 'Do you want to remove this user?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.filesFolderUserDelete, {data: {id: id, folder: t.props.folder}}).then(function (response) {
                        message.success(`User deleted.`);
                        t.props.drawerChanged('files');
                        setTimeout(resolve, 10);
                    });
                });
            },
            onCancel() {},
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this folder?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.filesFolderDelete, {data: {id: t.props.folder}}).then(function (response) {
                        setTimeout(resolve, 1);
                        t.props.onDelete();
                        message.success(`Folder deleted.`);
                        t.props.drawerChanged('files');
                        t.stateSet({status: false});
                    });

                });
            },
            onCancel() {},
        });
    };

    render = () => {
        return <>
            <Button className="strong mb_5" onClick={this.open} icon="folder">folder</Button>

            <Modal
                title="Edit folder"
                zIndex={1102}
                visible={this.state.status}
                onCancel={() => this.stateSet({status: false})}>

                {this.state.loading
                    ? <Skeleton active />
                    : <div style={{position: 'relative'}} id="foldersDrawerShow">
                        <EditField
                            edit={true}
                            title="Name"
                            default={this.state.folder.name}
                            form={{validate: "required|max:191", default: this.state.folder.name}}
                            field="input"
                            onSubmit={(value) => this.save('name', value)}
                            show={<h4>{this.state.folder.name}</h4>} />


                        <div className="field_label mt_15 mb_15">
                            <span> Employees: </span>
                            <Users
                                users={this.state.folder.users}
                                edit={true}
                                parent="foldersDrawerShow"
                                addUsers={(value) => this.addUsers(value)}
                                deleteUser={(id) => this.deleteUser(id)} />
                        </div>

                        <Button className="strong" onClick={this.delete}> Delete folder </Button>
                </div>}
            </Modal>
        </>
    };


}
const mapStateToProps = state => ({
    drawer: state.drawers.files
});
const mapDispatchToProps = {
    drawerChanged: drawerChanged,
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(FolderEdit);
