import React from 'react';
import {connect} from 'react-redux';
import {Button, Drawer, Select, Spin} from "antd";
import DepartmentDrawer from "./DepartmentDrawer";
import api from "../../../modules/api/request";
import DepartmentsGraph from "./DepartmentsGraph";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import {setDepartments} from "../../../store/actions/departmentsActions";
const { Option } = Select;

class Departments extends React.Component {

    state = model(this, {
        loading: true,
        data: [],
        status: false
    });

    render = () => {
        return <div>
            <div className="dashboard_title">
                DEPARTMENTS
            </div>

            {this.props.user.privileges.includes(305) &&
                <div className="text-right mb_15">
                    <Button onClick={() => this.props.toggleDrawer({drawer: 'department'})} className="success" icon="plus">
                        ADD DEPARTMENT
                    </Button>
                </div>}

            <DepartmentDrawer />

            <div className="dashboard_block">
                <div className="m_15">
                    {this.state.loading
                        ?  <div className="spin_loader"><Spin spinning /></div>
                        : <DepartmentsGraph data={this.state.data} /> }
                </div>
            </div>
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.departmentsTree).then(function (response) {
            t.props.setDepartments(response.data);
            var data = response.data.filter((item) => {
                return item.id !== 'other';
            });
            t.stateSet({loading: false, status: t.props.drawer.status, data: data});
        });
    };

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.department
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    setDepartments: setDepartments
};
export default connect(mapStateToProps, mapDispatchToProps)(Departments);
