import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Avatar, Button, DatePicker, Form, Input, message, Select} from "antd";
import api from "../../modules/api/request";
import image from "../../assets/images/image.png";
import Items from "../partials/Items";
import FormEditor from "../../modules/editor/FormEditor";
const {Option} = Select;
const format = 'MMM Do YYYY';

class CountryCreate extends React.Component   {

    state = model(this, {
        loading: false,
        flagName: '',
        form: {
            flag: {},
            name: {validate: 'required|max:191'},
            info: {validate: 'max:191'},
            language: {validate: 'max:191'},
            currency: {validate: 'max:191'},
            dial: {validate: 'max:191'},
            drive: {type: 'select', default: 'right'},
            timezone: {type: 'select', default: 'undefined'},
            region: {type: 'select', default: 'undefined'},
            region_code: {},
            notes: {},
        }
    });

    changeFlag = (e) => {
        e.preventDefault();
        let t  = this;
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                let t = this;
                var file = files[0];
                t.stateSet({flagName: file.name, 'form.flag.value': file.id});
            }});
    };

    changeRegion = (name) => {
        var code = '';
        this.props.regions.map((item, index) => {
            if(item.name === name)     {
                code = item.code;
            }
        });
        this.stateSet({'form.region.value': name, 'form.region_code.value': code});
    };

    onSubmit = (form, model) => {
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.countryInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.toggleDrawer({drawer: 'country'});
            t.props.drawerChanged('country');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="countryDrawerForm" onSubmit={form.onSubmit}>


                <Form.Item label="Photo">
                    <a href="/" onClick={this.changeFlag}>
                        <Avatar size={60} src={this.state.flagName ? api.avatar(this.state.flagName) : image} className="avatar" />
                    </a>
                    <i>Click image to change</i>
                </Form.Item>

                <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                    <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                </Form.Item>

                <Form.Item label="General info" validateStatus={form.info.error && 'error'} help={form.info.error}>
                    <Input maxLength={191} type="text" {...form.info} autoComplete="off" placeholder="Wikipedia link" />
                </Form.Item>


                <Form.Item label="Region">
                    <Select value={form.region.value} onChange={this.changeRegion} placeholder="Select region"  allowClear={true}
                            getPopupContainer={trigger => document.getElementById('countryDrawerForm')}>
                        {this.props.regions.map((item, index) => {
                            return <Option key={index} value={item.name}> ({item.code}) {item.name} </Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Official language" validateStatus={form.language.error && 'error'} help={form.language.error}>
                    <Input maxLength={191} type="text" {...form.language} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Currency" validateStatus={form.currency.error && 'error'} help={form.currency.error}>
                    <Input style={{width: 160}}  maxLength={191} type="text" {...form.currency} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Dialing code" validateStatus={form.dial.error && 'error'} help={form.dial.error}>
                    <Input style={{width: 160}}  maxLength={191} type="text" {...form.dial} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Driving side">
                    <Select {...form.drive} placeholder="prefix" style={{width: 160}} getPopupContainer={trigger => document.getElementById('countryDrawerForm')}>
                        <Option value="right"> Right </Option>
                        <Option value="left"> Left </Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Time zone">
                    <Select {...form.timezone} placeholder="Select timezone" getPopupContainer={trigger => document.getElementById('countryDrawerForm')}>
                        <Option value=""> &nbsp; </Option>
                        {this.props.timezones.map((item, index) => {
                            return <Option key={index} value={item.id}> {item.name} </Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="General notes" validateStatus={form.notes.error && 'error'} help={form.notes.error}>
                    <FormEditor getValue={(value) => this.stateSet({['form.notes.value']: value})} />
                </Form.Item>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable/>
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="countryDrawerForm" loading={this.state.loading}> Save country </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'country'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(CountryCreate);
