import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {Skeleton, Tabs} from "antd";
import api from "../../modules/api/request";
import {toggleDrawer, drawerChanged} from "../../store/actions/drawersActions";
import TasksTable from "../../views/projectManager/tasks/TasksTable";
import Comments2 from "../partials/Comments2";
const { TabPane } = Tabs;


class ProjectProtected extends React.Component   {

    state = model(this, {
        tasks: [],
        comments: false
    });

    render = () => {
        var totalPrice = 0;
        return <Tabs defaultActiveKey="1">
            <TabPane tab="Tasks" key="1">
                {this.state.tasks.length > 0
                    ?  <div>
                        <TasksTable last="Price" data={this.state.tasks} inset={true} />
                        {this.state.tasks.map((task) => {
                            task.checklist.map((item) => {
                                if(item.price) totalPrice += parseInt(item.price);
                            });
                        })}
                        <h4 className="mt_15">TOTAL PRICE: {totalPrice ? totalPrice : <i>not set</i>}</h4>
                    </div>
                    : <div className="p_15 text-center"> No tasks assigned </div>
                }
            </TabPane>
            <TabPane tab="Comments" key="2">
                {this.state.comments === false
                    ? <Skeleton active={true}/>
                    : <Comments2
                        privilege={true}
                        subject="project"
                        comments={this.state.comments}
                        id={this.props.id}
                        sub={true}/>
                }
            </TabPane>
        </Tabs>
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.projectProtected + '/' + this.props.id).then(function (response) {
           t.stateSet({tasks: response.data.tasks, comments: response.data.comments});
        })
    }

}

const mapStateToProps = state =>    ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectProtected);
