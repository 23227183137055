import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import VisitCreate from "./VisitCreate";
import VisitShow from "./VisitShow";

class VisitDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        visit: false,
        create: false
    });

    render = () => {
        return  <Drawer
            visible={this.state.status}
            width="80%"
            zIndex={997}
            title="VISIT"
            placement="right"
            closable={true}
            onClose={() => this.props.toggleDrawer({drawer: 'visit'})}>

            {!this.state.loading
                ? <div>
                    {this.state.visit && <VisitShow visit={this.state.visit} />}
                    {this.state.create && <VisitCreate />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.visit + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, visit: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, visit: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, visit: false, create: false});
        }
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}


const mapStateToProps = state => ({
    drawer: state.drawers.visit
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(VisitDrawer);
