import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {Button, Spin, Modal, message, Tabs} from "antd";
import api from "../../modules/api/request";
import {toggleDrawer, drawerChanged} from "../../store/actions/drawersActions";
import User from "../partials/User";
import ProjectProtected from "./ProjectProtected";
import ProtectedMiddleware from "../partials/ProtectedMiddleware";
import EditField from "../partials/EditField";
const { confirm } = Modal;


class ProjectShow extends React.Component   {

    state = model(this, {
        role: false,
        owner_accept: false,
        user_accept: false,
        edit: false
    });

    delete = () => {
        let t = this;
        confirm({
            title: 'Do you want to delete this project?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.projectDelete, {data: {id: t.props.project.id}}).then(function (response) {
                        setTimeout(resolve, 10);
                        t.props.toggleDrawer({drawer: 'project'});
                        t.props.drawerChanged('project');
                        message.success(`Task deleted.`);
                    });
                });
            },
            onCancel() {},
        });
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.projectUpdate, {id: this.props.project.id, field: field, value: value}).then(function (response) {
            message.success(`Task updated.`);
            t.props.drawerChanged('project');
        });
    };

    addUsers = (object) => {
        var role = object.extra;
        var value = object.value;
        if(value.length === 0) return;
        let t = this;
        api.post(api.routes.projectUserAdd, {ids: value, project: this.props.project.id, role: role}).then(function (response) {
            message.success(`Project updated.`);
            t.props.drawerChanged('project');
        });
    };

    render = () => {
        var project = this.props.project;
        return project
                ? <div>
                    <div className="row marginless" style={{position: 'relative'}} id="projectsDrawerShow">
                        <div className="col">
                            <div className="light_bcg p_15 mb_15">
                                <EditField
                                    edit={this.state.edit}
                                    form={{validate: "required|max:191", default: project.name}}
                                    field="input"
                                    onSubmit={(value) => this.save('name', value)}
                                    show={<h3>{project.name}</h3>} />
                            </div>

                            <div className="mb_15">
                                <EditField
                                    edit={this.state.edit}
                                    field="editor"
                                    form={{validate: "max:2000", default: project.description}}
                                    onSubmit={(value) => this.save('description', value)}
                                    show={<div dangerouslySetInnerHTML={{__html: project.description}}/>} />
                            </div>

                            <ProtectedMiddleware
                                role={this.state.role}
                                project={project.id}
                                owner_accept={this.state.owner_accept}
                                user_accept={this.state.user_accept}>
                                <ProjectProtected id={project.id} />
                            </ProtectedMiddleware>
                        </div>

                        {this.state.owner_accept && this.state.user_accept &&
                        <div style={{width: 300}}>
                            {[
                                {name: 'Created by', role: 1},
                                {name: 'Participants', role: 2},
                                {name: 'Observers', role: 3}].map((group) => {

                                return <div key={group.name} className="field_label">
                                            <span> {group.name}: </span>
                                            {project.users.map((item, index) => {
                                                if(item.pivot.role === group.role)       {
                                                    return <User
                                                        key={item.uid + '_' + index}
                                                        user={item}
                                                        owner={this.state.role === 1} />
                                                }
                                            })}

                                    <EditField
                                        edit={this.state.edit}
                                        field="persons"
                                        extra={group.role}
                                        form={{type: 'select', default: []}}
                                        onSubmit={(value) => this.addUsers(value)}
                                        parent="projectsDrawerShow" />
                                </div>
                            })}
                        </div>}
                    </div>

                    <div className="drawer_footer">
                        {this.state.edit &&
                        <Button type="primary" onClick={this.delete}>Delete project</Button>} &nbsp;
                        <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'project'})}>Close</Button>
                    </div>
                </div>
                : <Spin spinning={true} />
    };

    componentDidMount() {
        var role = false, user_accept = false, owner_accept = false, edit = false;
        this.props.project.users.map((item) => {
            if(item.uid === this.props.user.uid)       {
                if(item.pivot.role)         role = item.pivot.role;
                if(item.pivot.owner_accept) owner_accept = item.pivot.owner_accept;
                if(item.pivot.user_accept)  user_accept = item.pivot.user_accept;
                if(item.pivot.role === 1) {
                    if(item.pivot.owner_accept || item.pivot.user_accept)       {
                        edit = true;
                    }
                }
            }
        });
        this.stateSet({role: role, user_accept: user_accept, owner_accept: owner_accept, edit: edit});
    }
}

const mapStateToProps = state =>    ({
    user: state.user,
    drawer: state.drawers.project
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectShow);
