import React from 'react';
import {Button, message, Modal, Select, Tabs} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import Avatar from "../../layout/Avatar";
import EditField from "../partials/EditField";
import ItemsEdit from "../partials/ItemsEdit";
import Comments2 from "../partials/Comments2";
import moment from "moment";
import pdf from "../../assets/images/pdf.png";
const format = 'MMM Do YYYY';
const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;

class CompanyShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit
    });

    changeLogo = (e) => {
        e.preventDefault();
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                var file = files[0];
                this.save('logo', file.id);
            }});
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.companyUpdate, {id: this.props.company.id, field: field, value: value}).then(function (response) {
            message.success(`Company updated.`);
            t.props.drawerChanged('company');
        });
    };

    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this company?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.companyDelete, {data: {id: t.props.company.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Company deleted.`);
                        t.props.toggleDrawer({drawer: 'company'});
                        t.props.drawerChanged('company');
                    });

                });
            },
            onCancel() {},
        });
    };

    saveItem = (form, table) => {
        var id = form.id;
        delete form.id;
        let t = this;
        api.post(api.routes.companyItemUpdate, {id: id, form: form, table: table, company: this.props.company.id}).then(function (response) {
            message.success(`Item updated.`);
            t.props.drawerChanged('company');
        });
    };

    deleteItem = (id, table) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this item?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.companyItemDelete, {data: {id: id, table: table}}).then(function (response) {
                        setTimeout(resolve, 10);
                        t.props.drawerChanged('company');
                        message.success(`Item deleted.`);
                    });
                });
            },
            onCancel() {},
        });
    };

    pdf = () => {
        let company = this.props.company;
        var structure = [
            {element: 'table', data: [
                    [{type: "text", value: "Name"}, {type: "text", value: company.name}],
                    [{type: "text", value: "Parent company"}, {type: "text", value: company.company ? company.company.name : ''}],
                    [{type: "text", value: "Role"}, {type: "text", value: company.role ? company.role : ''}],
                    [{type: "text", value: "Status"}, {type: "text", value: company.status ? company.status : ''}],
                    [{type: "text", value: "Country"}, {type: "text", value: company.country ? company.country.name : ''}],
                    [{type: "text", value: "Notes"}, {type: "text", value: company.notes ? company.notes : ''}],
                ]}
        ];
        var addresses = [];
        company.addresses.map((item) => {
            var a = item.address ? item.address : '';
            var d = item.description ? item.description : '';
            addresses.push([{type: "text", value: 'address'}, {type: "text", value: a + "\n" +d}])
        });
        if(addresses.length > 0)     {
            structure.push({element: 'table', header: 'Addresses', data: addresses});
        }
        var phones = [];
        company.phones.map((item) => {
            var a = item.phone ? item.phone : '';
            var d = item.description ? item.description : '';
            phones.push([{type: "text", value: 'phone'}, {type: "text", value: a + "\n" +d}])
        });
        if(phones.length > 0)     {
            structure.push({element: 'table', header: 'Phones', data: phones});
        }
        var emails = [];
        company.emails.map((item) => {
            var a = item.email ? item.email : '';
            var d = item.description ? item.description : '';
            emails.push([{type: "text", value: 'email'}, {type: "text", value: a + "\n" +d}])
        });
        if(emails.length > 0)     {
            structure.push({element: 'table', header: 'Emails', data: emails});
        }
        var networks = [];
        company.networks.map((item) => {
            var a = item.name ? item.name : '';
            var d = item.link ? item.link : '';
            networks.push([{type: "text", value: 'network'}, {type: "text", value: a + "\n" +d}])
        });
        if(networks.length > 0)     {
            structure.push({element: 'table', header: 'Networks', data: networks});
        }

        this.props.toggleDrawer({drawer: 'pdf', title: company.name, structure: structure})
    };

    render = () => {
        let company = this.props.company;
        var edit = this.props.user.privileges.includes(407) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={this.pdf}>
                    <img src={pdf} width={15} style={{marginTop: -4}} /> &nbsp; PDF
                </Button>
                &nbsp;
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT COMPANY'}
                </Button>
            </div>

            <div className="row marginless" style={{position: 'relative'}} id="companyDrawerShow">
                <div className="col">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Info" key="1">
                            <div className="row marginless">
                                <div style={{width: 90}}>
                                    {edit
                                        ? <div>
                                            <a href="/" onClick={this.changeLogo}>
                                                <Avatar shape="square" name={company.name} avatar={company.logo_name} size={80} />
                                            </a>
                                            <i style={{fontSize: 12}}>Click to change</i>
                                        </div>
                                        : <Avatar shape="square" name={company.name} avatar={company.logo_name} size={80} />}
                                </div>
                                <div className="col">
                                    <EditField
                                        title="Name"
                                        edit={edit}
                                        form={{validate: "required|max:191", default: company.name}}
                                        field="input"
                                        onSubmit={(value) => this.save('name', value)}
                                        show={<h4>{company.name}</h4>} />

                                    <EditField
                                        title="Parent company"
                                        edit={edit}
                                        form={{type: "select", default: company.parent}}
                                        field="company"
                                        parent="companyDrawerShow"
                                        onSubmit={(value) => this.save('parent', value)}
                                        show={company.company
                                            ? <h3>
                                                {company.company.logo_name && <Avatar name={company.company.name} avatar={company.company.logo_name} />}
                                                {company.company.name}
                                            </h3>
                                            : <h4><i>not set</i></h4>}
                                    />

                                    <EditField
                                        title="Role"
                                        edit={edit}
                                        form={{type: "select", default: company.role}}
                                        field="select"
                                        options={this.props.roles}
                                        parent="companyDrawerShow"
                                        onSubmit={(value) => this.save('role', value)}
                                        show={this.notSet(company.role)} />

                                    <EditField
                                        title="Status"
                                        edit={edit}
                                        form={{type: "select", default: company.status}}
                                        field="select"
                                        options={['Regular', 'VIP']}
                                        parent="companyDrawerShow"
                                        onSubmit={(value) => this.save('status', value)}
                                        show={this.notSet(company.status)} />

                                    <EditField
                                        title="Country"
                                        edit={edit}
                                        form={{type: "select", default: company.country_id}}
                                        field="country"
                                        parent="companyDrawerShow"
                                        onSubmit={(value) => this.save('country_id', value)}
                                        show={company.country
                                            ? <h3><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: company.country.id})}>
                                                {company.country.flag_name && <Avatar name={company.country.name} avatar={company.country.flag_name} />}
                                                {company.country.name}
                                            </a></h3>
                                            : <h3><i>not set</i></h3>} />

                                    <EditField
                                        title="General notes"
                                        edit={edit}
                                        form={{default: company.notes}}
                                        field="editor"
                                        onSubmit={(value) => this.save('notes', value)}
                                        show={<div className="light_bcg p_15" dangerouslySetInnerHTML={{__html: company.notes}}/>} />
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab={`Comments (${company.comments.length})`} key="2">
                            <Comments2
                                privilege={true}
                                comments={company.comments}
                                subject="company"
                                id={company.id}
                                sub={false} />
                        </TabPane>
                        <TabPane tab={`Visits (${company.visits.length})`} key="3">
                            {company.visits.length > 0
                                ? company.visits.map((visit, index) => {
                                    return <div key={index} className="dashboard_block mb_15 p_15">
                                        <div> CONTACT PERSON: &nbsp;
                                            <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'contact', id: visit.contact.id})}>
                                                <Avatar name={visit.contact.name} avatar={visit.contact.photo_name} size="small"/>{visit.contact.name}
                                            </a>
                                        </div>

                                        <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'visit', id: visit.id})}>
                                            <div>
                                                ARRIVAL DATE: <strong>{moment(visit.arrival_date).format(format)} {visit.arrival_time}</strong>
                                            </div>
                                            <div>
                                                ARRIVAL FROM: <strong> {visit.arrival_city} </strong>
                                            </div>
                                            <div>
                                                DEPARTURE DATE: <strong>{moment(visit.departure_date).format(format)} {visit.departure_time}</strong>
                                            </div>
                                            <div>
                                                DEPARTURE CITY: <strong> {visit.departure_city} </strong>
                                            </div>
                                        </a>
                                        <div> RESPONSIBLE PERSON: &nbsp;
                                            <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: visit.user.uid})}>
                                                <Avatar name={visit.user.name} avatar={visit.user.avatar_name} size="small"/>{visit.user.name}
                                            </a>
                                        </div>
                                    </div>
                                })
                                : <i> no visits </i>}
                        </TabPane>
                    </Tabs>
                </div>
                <div style={{width: 300}}>
                    <h3> Contacts: </h3>

                    <ItemsEdit
                        title="Addresses"
                        edit={edit}
                        elements={[{name: 'address'}, {name: 'description', type: 'textarea'}, {name: 'lat', type: 'gmap'}, {name: 'lng', type: 'gmap'}]}
                        items={company.addresses}
                        save={(form) => this.saveItem(form, 'companies_addresses')}
                        delete={(id) => this.deleteItem(id, 'companies_addresses')} />


                    <ItemsEdit
                        title="Emails"
                        edit={edit}
                        elements={[{name: 'email'}, {name: 'description'}]}
                        items={company.emails}
                        save={(form) => this.saveItem(form, 'companies_emails')}
                        delete={(id) => this.deleteItem(id, 'companies_emails')} />

                    <ItemsEdit
                        title="Phones"
                        edit={edit}
                        elements={[{name: 'phone'}, {name: 'description'}]}
                        items={company.phones}
                        save={(form) => this.saveItem(form, 'companies_phones')}
                        delete={(id) => this.deleteItem(id, 'companies_phones')} />

                    <ItemsEdit
                        title="Networks"
                        edit={edit}
                        elements={[{name: 'name'}, {name: 'link'}]}
                        items={company.networks}
                        save={(form) => this.saveItem(form, 'companies_networks')}
                        delete={(id) => this.deleteItem(id, 'companies_networks')} />
                </div>
            </div>
            <div className="drawer_footer">
                {edit && <><Button className="strong" onClick={this.delete}>Delete company</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'company'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyShow);
