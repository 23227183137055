import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Button, DatePicker, Form, Input, message, Radio, Select} from "antd";
import api from "../../modules/api/request";
import FairSelect from "../partials/FairSelect";
import ContactSelect from "../partials/ContactSelect";
import CountriesSelect from "../partials/CountriesSelect";
import CompaniesSelect from "../partials/CompaniesSelect";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import ProductsTypesSelect from "../partials/ProductsTypesSelect";
const {Option} = Select;
const {TextArea} = Input;

class OfferCreate extends React.Component   {

    state = model(this, {
        loading: false,
        source: '',
        form: {
            status: {type: 'select', default: 'undefined'},
            owner: {type: 'select', default: [this.props.user.uid]},
            source: {type: 'select', default: 'undefined'},
            fair: {type: 'select', default: 'undefined'},
            social_media: {},
            source_contact: {type: 'select', default: 'undefined'},
            source_company: {type: 'select', default: 'undefined'},
            equipment_type: {type: 'select', default: 'undefined'},
            company_id: {type: 'select', default: 'undefined'},
            contact_id: {type: 'select', default: 'undefined'},
            comment: {},
        }
    });

    onSubmit = (form, model) => {
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.offerInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.toggleDrawer({drawer: 'offer', id:response.data.id, reload: true});
            t.props.drawerChanged('offer');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    changeSource = (e) => {
        var val = e.target.value;
        if(val === 'company')      {
            this.stateSet({source: val, 'form.source.value': undefined, 'form.fair.value': undefined,
                'form.social_media.value': '', 'form.source_contact.value': undefined});
        }
        else    {
            this.stateSet({source: val, 'form.source_company.value': undefined});
        }
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="offerDrawerForm" onSubmit={form.onSubmit}>

                <Form.Item label="Status">
                    <Select {...form.status} placeholder="Select status" allowClear={true}
                            getPopupContainer={trigger => document.getElementById('offerDrawerForm')}>
                        {this.props.statuses.map((item, index) => {
                            return <Option key={index} value={item.id}> {item.name} </Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Owner">
                    <PersonTreeSelect field={form.owner} parent="offerDrawerForm" />
                </Form.Item>

                <Form.Item label="Source">

                    <Radio.Group onChange={this.changeSource} value={this.state.source} style={{width: '100%'}}>
                        <div className="row marginless">
                            <div style={{width: 15}}>
                                <Radio value="direct" />
                            </div>
                            <div className="col">
                                <Select {...form.source} disabled={this.state.source !== 'direct'} placeholder="Select source"  allowClear={true}
                                        getPopupContainer={trigger => document.getElementById('offerDrawerForm')}>
                                    {this.props.sources.map((item, index) => {
                                        return <Option key={index} value={item}> {item} </Option>
                                    })}
                                </Select>
                                {form.source.value === 'Exibition' &&
                                <FairSelect field={form.fair} parent="offerDrawerForm" />}
                                {form.source.value === 'Social media' &&
                                <Input maxLength={191} placeholder="socila media..." type="text" {...form.social_media} autoComplete="off" />}
                                {this.state.source === 'direct' &&
                                <ContactSelect field={form.source_contact} parent="offerDrawerForm" />}
                            </div>
                        </div>
                        <div>
                            <div className="row marginless">
                                <div style={{width: 15}}>
                                    <Radio value="company" />
                                </div>
                                <div className="col">
                                    <CompaniesSelect disabled={this.state.source !== 'company'} field={form.source_company} parent="offerDrawerForm" />
                                </div>
                            </div>
                        </div>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Equipment type">
                    <ProductsTypesSelect field={form.equipment_type} parent="offerDrawerForm" />
                </Form.Item>

                <Form.Item label="Company">
                    <CompaniesSelect field={form.company_id} parent="offerDrawerForm" />
                </Form.Item>


                <Form.Item label="Contact">
                    <ContactSelect field={form.contact_id} parent="offerDrawerForm" />
                </Form.Item>

                <Form.Item label="Comment" validateStatus={form.comment.error && 'error'} help={form.comment.error}>
                    <TextArea {...form.comment} />
                </Form.Item>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable/>
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="offerDrawerForm" loading={this.state.loading}> Create offer </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'offer'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user,
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferCreate);
