import React from 'react';
import {connect} from 'react-redux';
import {Button, Drawer, Input, Radio, Select, Skeleton, Spin} from "antd";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import ReportsTable from "./ReportsTable";
import GanttTable from "./GanttTable";
const { Option } = Select;

class Reports extends React.Component {

    state = model(this, {
        loading: true,
        status: false,
        data: [],
        filters: {participants: []},
        form: {
            search: {},
            participant: {type: 'select', default: 'undefined'},
            mode: {default: 'list'},
            gantt: {type: 'select', default: 'Day'}
        }
    });

    filtered = () => {
        return this.state.data.filter((item) => {
            if(this.state.form.search.value)       {
                var name = item.name.toLowerCase();
                var search = this.state.form.search.value.toLowerCase();
                if(!name.includes(search))        {
                    return false;
                }
            }
            if(this.state.form.participant.value)       {
                var part = item.participants.filter((part) => {
                    return part.name === this.state.form.participant.value;
                });
                if(part.length === 0) return false;
            }
            return true;
        });
    };

    render = () => {
        return <div>
            <div className="dashboard_title">
                TRIPS REPORTS
            </div>

            {this.props.user.privileges.includes(307) &&
                <div className="text-right mb_15">
                    <Button onClick={() => this.props.toggleDrawer({drawer: 'report'})} className="success" icon="plus">
                        ADD REPORT
                    </Button>
                </div>}



            <div className="dashboard_block">
                <div className="m_15">
                    <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="Search by name" />
                    &nbsp;
                    <Select placeholder="Participant" style={{ width: 220 }} allowClear={true} {...this.state.form.participant}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.participants.map((region, index) => {
                            return <Option key={index} value={region.name}> {region.name} ({region.count}) </Option>
                        })}
                    </Select>

                    {this.state.form.mode.value === 'gantt'
                    && <label>
                        &nbsp; &nbsp; Gantt: &nbsp;
                        <strong>
                            <Select {...this.state.form.gantt} style={{ width: 100 }}>
                                <Option value="Day">Days</Option>
                                <Option value="Week">Weeks</Option>
                                <Option value="Year">Years</Option>
                            </Select>
                        </strong>
                    </label>}

                    <div className="pull-right">
                        <Radio.Group {...this.state.form.mode} buttonStyle="solid">
                            <Radio.Button value="list">List</Radio.Button>
                            <Radio.Button value="gantt">Gantt</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>

                {this.state.loading
                    ? <div className="m_15"><Skeleton active={true} /></div>
                    : this.state.form.mode.value === 'gantt'
                        ? <GanttTable data={this.filtered()} mode={this.state.form.gantt.value} />
                        : <ReportsTable data={this.filtered()} />
                }
            </div>
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.reports).then(function (response) {
            var participants = {};
            response.data.map((item) => {
                item.participants.map((participant) => {
                    if(!participants[participant.name]) {
                        participants[participant.name] ={name: participant.name, count: 1};
                    }
                    else    participants[participant.name].count ++;
                });

            });
            var filters = {participants: Object.values(participants)};
            t.stateSet({loading: false, status: t.props.drawer.status, filters: filters, data: response.data});
        });
    };

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.report
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
