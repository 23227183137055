import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Button, Form, Input, message, Tag, Tooltip, Icon} from "antd";
import api from "../../modules/api/request";
import update from "react-addons-update";


class VideoCreate extends React.Component   {

    state = model(this, {
        loading: false,
        flagName: '',
        form: {
            name: {validate: 'required|max:191'},
            link: {validate: 'required|max:191'},
            tags: {default: []},
            tagInput: {validate: 'max:191'},
        }
    });

    onSubmit = (form, model) => {
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.videoInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Video saved.`);
            t.props.toggleDrawer({drawer: 'video'});
            t.props.drawerChanged('video');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    removeTag = (e, index) => {
        e.preventDefault();
        const newData = update(this.state, {form: {tags: {value: {$splice: [[index, 1]] }}}});
        this.setState(newData);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.addTag();
        }
    };

    addTag = () => {
        var value = this.state.form.tagInput.value;
        const newData = update(this.state, {form: { tags: {value: {$push: [value]}}, tagInput: {value: {$set: ""}}}});
        this.setState(newData);
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="videoDrawerForm" onSubmit={form.onSubmit}>

                <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                    <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Link" validateStatus={form.link.error && 'error'} help={form.link.error}>
                    <Input maxLength={191} type="text" {...form.link} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Tags">
                    <div>
                    {form.tags.value.map((item, index) => {
                        return <Tag key={index} closable onClose={(e) => this.removeTag(e, index)}> {item} </Tag>
                    })}
                    </div>

                    <Input size="small"  onKeyDown={this.handleKeyDown} {...form.tagInput}
                           maxLength={191} placeholder="insert tag" type="text" autoComplete="off"
                           suffix={
                               <Tooltip title="Add tag">
                                   <Icon type="plus-circle" onClick={this.addTag} style={{ color: 'rgba(0,0,0,.45)' }} />
                               </Tooltip>
                           }/>
                </Form.Item>


                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable/>
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="videoDrawerForm" loading={this.state.loading}> Save video </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'video'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoCreate);
