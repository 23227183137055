import React from 'react';
import {Button, DatePicker, Avatar, Form, Input, message, Select, TimePicker, Switch} from "antd";
import model from "../../modules/model/bind";
import FormEditor from "../../modules/editor/FormEditor";
import PersonTreeSelect from "./PersonTreeSelect";
import CompaniesSelect from "./CompaniesSelect";
import CountriesSelect from "./CountriesSelect";
import ContactSelect from "./ContactSelect";
import ProductsTypesSelect from "./ProductsTypesSelect";
const {TextArea} = Input;
const format = 'MMM Do YYYY';
const sqlFrmat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';
const {Option} = Select;

class EditField extends React.Component   {

    state = model(this, {
        editField: false,
        form: {
            name: this.props.form ? this.props.form: {},
        }
    });

    changeEdit = (e) => {
        e.preventDefault();
        this.stateSet({editField: !this.state.editField});
    };

    renderField = () => {
        if(this.props.field === 'input')        {
            return   <Input {...this.state.form.name} autoComplete="off" />
        }
        if(this.props.field === 'time')        {
            return <div className="mb_5"> <TimePicker {...this.state.form.name}  format={timeFormat} /></div>
        }
        else if(this.props.field === 'textarea')        {
            return   <TextArea {...this.state.form.name} autoComplete="off" />
        }
        else if(this.props.field === 'editor')  {
            return <FormEditor value={this.state.form.name.value} getValue={(val) => this.stateSet({'form.name.value': val})} />
        }
        else if(this.props.field === 'date')    {
            return <div className="mb_5"> <DatePicker format={format} {...this.state.form.name} /> </div>
        }
        else if(this.props.field === 'switch')    {
            return <div className="mb_5"> <Switch defaultChecked={this.state.form.name.value === 1} {...this.state.form.name} /> </div>
        }
        else if(this.props.field === 'select')     {
            return <Select {...this.state.form.name} getPopupContainer={trigger => document.getElementById(this.props.parent)}>
                {this.props.options.map((item, index) => {
                    var id, name;
                    if(this.props.idField)      {
                        id = item[this.props.idField];
                        name = item.name;
                    }
                    else    {
                        if(!item.id) {
                            id = item;
                            name = item;
                        }
                        else        {
                            id = item.id;
                            name = item.name;
                        }
                    }
                    return <Option key={index} value={id}>{name}</Option>
                })}
            </Select>
        }
        else if(this.props.field === 'country')     {
            return <CountriesSelect size="small" field={this.state.form.name} parent={this.props.parent} />
        }
        else if(this.props.field === 'company')     {
            return <CompaniesSelect size="small" field={this.state.form.name} parent={this.props.parent} />
        }
        else if(this.props.field === 'persons')     {
            return <PersonTreeSelect size="small" field={this.state.form.name} parent={this.props.parent} />
        }
        else if(this.props.field === 'contacts')     {
            return <ContactSelect size="small" field={this.state.form.name} parent={this.props.parent} />
        }
        else if(this.props.field === 'product_type')     {
            return <ProductsTypesSelect size="small" field={this.state.form.name} parent={this.props.parent} />
        }
    };

    save = (e) => {
        if(e) e.preventDefault();
        if(!this.props.onSubmit) return false;
        if (this.props.extra) {
            return this.props.onSubmit({extra: this.props.extra, value: this.state.form.name.value});
        }
        return this.props.onSubmit(this.state.form.name.value);
    };

    render = () => {
        const form = this.state.form;
        return <div>

                <div className="row">
                    <div className="col">
                        {this.props.title && <div>{this.props.title}</div>}
                    </div>
                    <div className='col text-right'>
                        {this.props.edit && <a href="/" onClick={(e) => this.changeEdit(e)}>
                            {this.state.editField ? 'close' : 'edit'}
                        </a>}
                    </div>
                </div>

            {this.state.editField
                ? <Form onSubmit={(e) => this.save(e)}>
                    <Form.Item validateStatus={form.name.error && 'error'} help={form.name.error}>
                        {this.renderField()}
                        <Button icon="check" disabled={form.name.error} htmlType="submit"> Save </Button>
                    </Form.Item>
                </Form>
                : this.props.show
            }
        </div>
    };
}

export default EditField;
