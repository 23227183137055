import React from 'react';
import Chart from 'react-google-charts';
import api from "../../../modules/api/request";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class DepartmentsGraph extends React.Component {

    parseData = () => {
        var state = [['Name', 'Manager']];
        this.props.data.map((item) => {
            state.push([{v: item.id.toString(), f: this.renderDpartmentBlock(item)}, item.parent===null ? '' : item.parent.toString()])
        });
        return state;
    };

    renderDpartmentBlock = (item) =>      {
        return  `<div class='org_block'>
    <div class='org_header' style="white-space: nowrap" data-id="${item.id}"> ${item.name} </div>
    <div class='org_content'>
        ${this.renderDepartmentEmployee(item.employees)}
    </div> 
</div>`};

    renderAvatar = (employee) => {
        if(employee.avatar_name)        {
            return `<span class="ant-avatar avatar ant-avatar-sm ant-avatar-circle ant-avatar-image">
                <img src="${api.avatar(employee.avatar_name)}">
            </span>`
        }
        return `<span class="ant-avatar ant-avatar-sm avatar ant-avatar-circle">      
                    <span class="ant-avatar-string" style="transform: scale(1) translateX(-50%); padding: 0">
                        <strong>${employee.name[0].toUpperCase()}</strong>
                    </span>
            </span>`
    };

    renderDepartmentEmployee = (employees) =>  {
        var str = '';
        employees.map((employee) => {
            str += `<div class="org_employee" data-uid="${employee.uid}">
<div style="white-space: nowrap">
        
            ${this.renderAvatar(employee)}
  
    <span> ${employee.name} </span>
</div>
<p><i>${employee.position ? employee.position : ''}</i></p>
</div>`});
        return str;
    };

    render = () => {
        let t = this;
        return  <Chart
            width={'100%'}
            chartType="OrgChart"
            chartEvents={[{
                eventName: "ready",
                callback: ({ chartWrapper, google }) => {
                    var x = document.getElementsByClassName("org_employee");
                    for (var i=0; i<x.length; i++) {
                        x[i].onclick = function(e){
                            var uid = this.getAttribute("data-uid");
                            t.props.toggleDrawer({drawer: 'user', id: uid});
                        }
                    }
                    var headers = document.getElementsByClassName("org_header");
                    for (var i=0; i<headers.length; i++) {
                        headers[i].onclick = function(e){
                            var id = this.getAttribute("data-id");
                            t.props.toggleDrawer({drawer: 'department', id: id});
                        }
                    }
                }}]}
            data={this.parseData()}
            options={{
                allowCollapse: true,
                allowHtml: true}}
        />
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsGraph);
