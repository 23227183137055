import React from 'react';
import {connect} from 'react-redux';
import {Button, Drawer, Input, Select, Skeleton, Spin} from "antd";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import CountriesTable from "./VideosTable";
const { Option } = Select;

class Videos extends React.Component {

    state = model(this, {
        loading: true,
        status: false,
        data: [],
        form: {
            search: {},
            tag: {},
        }
    });

    filtered = () => {
        return this.state.data.filter((item) => {
            if(this.state.form.search.value)       {
                var name = item.name.toLowerCase();
                var search = this.state.form.search.value.toLowerCase();
                if(!name.includes(search))        {
                    return false;
                }
            }
            if(this.state.form.tag.value)       {
                var tagSearch = this.state.form.tag.value.toLowerCase();
                var tags = item.tags.filter((tag) => {
                    return tag.name.toLowerCase().includes(tagSearch);
                });
                if(tags.length === 0) return false;
            }
            return true;
        });
    };

    render = () => {
        return <div>
            <div className="dashboard_title">
                VIDEOS
            </div>

            {this.props.user.privileges.includes(504) &&
                <div className="text-right mb_15">
                    <Button onClick={() => this.props.toggleDrawer({drawer: 'video'})} className="success" icon="plus">
                        ADD VIDEO
                    </Button>
                </div>}



            <div className="dashboard_block">
                <div className="m_15">
                    <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="Search by name" />
                    &nbsp;
                    <Input size="small" style={{ width: 200 }} {...this.state.form.tag} placeholder="Search by tag" />
                </div>

                {this.state.loading
                    ? <div className="m_15"><Skeleton active={true} /></div>
                    : <CountriesTable data={this.filtered()} /> }
            </div>
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.videos).then(function (response) {
            t.stateSet({loading: false, status: t.props.drawer.status, data: response.data});
        });
    };

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.video
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Videos);
