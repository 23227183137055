import React from 'react';
import {connect} from "react-redux";
import {TreeSelect} from "antd";
import Avatar from "../../layout/Avatar";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
const TreeNode = TreeSelect.TreeNode;

class EquipmentTreeSelect extends React.Component   {

    state = model(this, {
        data: [],
        loading: false,
    });

    render = () => {
        var filtered = this.state.data.filter((item) => {
            return item.equipment.length > 0;
        });
       return <TreeSelect getPopupContainer={trigger => document.getElementById(this.props.parent)}
                    {...this.props.field}
                    showSearch
                      style={{width: '100%'}}
                  loading={this.state.loading}
                    size="large"
                    dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                    placeholder="Select product"
                    allowClear
                    treeCheckable={true}
                      filterTreeNode={(input, option) =>
                          option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    multiple>
                {filtered.map((item) => {
                    return <TreeNode value={item.name} title={item.name} key={item.name}>
                        {item.equipment.map((product) => {
                            return <TreeNode value={product.id} title={product.name} key={product.id} />
                        })}
                    </TreeNode>
            }   )}
        </TreeSelect>
    };

    componentDidMount() {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.equipmentTree).then(function (response) {
            t.stateSet({data: response.data, loading: false});
        });
    }
}

export default EquipmentTreeSelect;
