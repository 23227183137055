import React from 'react';
import {Button, Form, Input, DatePicker, Select} from "antd";
import update from "react-addons-update";
import {Gmaps, Marker, InfoWindow, Circle} from 'react-gmaps';
import CompaniesSelect from "./CompaniesSelect";
const { TextArea } = Input;
const {Option} = Select;
const format = 'MMM Do YYYY';

class Items extends React.Component   {

    constructor(props)      {
        super(props);

        this.map = null;
        this.item = {};
        props.elements.map((element) => {
            var def = '';
            if(element.default || element.default === null)      {
                def = element.default;
            }
            if(element.default === 'undefined') {
                def = undefined;
            }
            this.item[element.name] = def;
        });
        this.state = {
            mapZoom: 16,
            items: [this.item],
        };
    }


    addItem = (e) => {
        e.preventDefault();
        const newData = update(this.state,{items: {$push: [this.item]}} );
        this.setState(newData);
    };
    deleteItem = (e, index) => {
        const newData = update(this.state, {items: {$splice: [[index, 1]] }} );
        this.setState(newData);
        if(this.props.return) this.props.return(this.state.items);
    };

    change = (e, index, field) => {
        var value = e.target.value;
        const newData = update(this.state,{items: {[index]: {[field]: {$set: value}}}});
        this.setState(newData);
        if(this.props.return) this.props.return(newData.items);
    };
    changeSelect = (value, index, field) => {
        const newData = update(this.state,{items: {[index]: {[field]: {$set: value}}}});
        this.setState(newData);
        if(this.props.return) this.props.return(newData.items);
    };

    onMapCreated = (map) => {
        this.map = map;
    };
    onClickMap = (e, index, field) => {
        var zoom = this.map.getZoom();
        var latLng = {lat: e.latLng.lat(), lng: e.latLng.lng()};
        const newData = update(this.state,{items: {[index]: {[field]: {$set: latLng}}}, mapZoom: {$set: zoom}  });
        this.setState(newData);
        if(this.props.return) this.props.return(this.state.items);
        this.map.panTo(latLng);
    };

    render = () => {
        return <Form.Item label={this.props.title} {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}>
            {this.state.items.map((item, index) => {
                return <div key={index} className="checklist_form_item light_bcg">
                    <div className="row marginless">

                        <div style={{width: 20}}><strong>{index+1}. </strong></div>

                        <div className="col">
                            {this.props.elements.map((element, i) => {
                                var placeholder = element.placeholder ? element.placeholder: element.name;
                                var spread = {key: i, placeholder: placeholder, value: item[element.name]};

                                if(element.type === 'date')     {
                                    return  <DatePicker
                                        {...spread}
                                        className="mb_5"
                                        onChange={(value) => this.changeSelect(value, index, element.name)}
                                        format={format} />
                                }
                                if(element.type === 'gmap')     {
                                    return <div key={i} className="p_15">
                                        <Gmaps
                                            width={'100%'}
                                            height={'300px'}
                                            lat={44.787197}
                                            lng={20.457273}
                                            zoom={this.state.mapZoom}
                                            loadingMessage={'Loading...'}
                                            params={{v: '3.exp', key: 'AIzaSyBEiSYtxrrCDlI6cobIM1bu81stEhA79hs'}}
                                            onMapCreated={(map) => this.map = map}
                                            onClick={(e) => this.onClickMap(e, index, element.name)}
                                        >
                                            <Marker
                                                lat={item[element.name].lat}
                                                lng={item[element.name].lng}
                                            />
                                        </Gmaps>
                                    </div>
                                }
                                if(element.type === 'company')     {
                                    return <CompaniesSelect
                                        return={(value) => this.changeSelect(value, index, element.name)}
                                        parent={element.parent} />
                                }
                                if(element.type === 'select')     {
                                    return <Select {...spread}
                                                   showSearch={true}
                                                   filterOption={(input, option) =>
                                                       option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                   }
                                                   onChange={(value) => this.changeSelect(value, index, element.name)}>
                                        <Option value={undefined}> &nbsp; </Option>
                                        {element.options.map((opt, ind) => {
                                            return <Option key={ind} value={opt.id}> {opt.name} </Option>
                                        })}
                                    </Select>
                                }
                                if(element.type === 'textarea')     {
                                    return <TextArea size="small" {...spread} autosize={{ minRows: 2 }} onChange={(e) => this.change(e, index, element.name)}/>
                                }
                                return <Input size="small" {...spread} onChange={(e) => this.change(e, index, element.name)}/>
                            })}
                        </div>

                        <div style={{width: 20}}>
                            <Button type="link" icon="delete" onClick={(e) => this.deleteItem(e, index)} />
                        </div>

                    </div>
                </div>
            })}

            <Button className="strong" onClick={this.addItem}>
                Add item
            </Button>
        </Form.Item>
    };
}
export default Items;
