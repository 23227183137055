import React from 'react';
import {Alert, Button} from "antd";
import update from "react-addons-update";
import api from "../../api/request";
import {loginUser} from "../userProvider";

class FacebookController extends React.Component   {

    constructor(props)      {
        super(props);
        this.state = {error: '', loading: false};
    }

    onClick = () => {
        let t = this;
        const newData = update(this.state,{loading: {$set: true} });
        this.setState(newData);
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                t.server(response);
            }
            else {
                window.FB.login(function (response) {
                    if (response.status === 'connected') {
                        t.server(response);

                    } else {
                        const newData = update(t.state,{loading: {$set: false}, error: {$set: "Error while connecting"} });
                        t.setState(newData);
                    }
                },{
                    scope: 'email',
                    return_scopes: true
                });
            }
        });
    };

    server = (response) => {
        let t = this;
        var userID = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;
        api.post(api.routes.facebook, {userID: userID, accessToken: accessToken, register: this.props.register}).then(function (response) {
            const newData = update(t.state,{loading: {$set: false}, success: {$set: response.data}, error: {$set: ''} });
            t.setState(newData);
            loginUser(response);

        }).catch(function (error) {
            const newData = update(t.state,{loading: {$set: false}, error: {$set: error} });
            t.setState(newData);
        });
    };

    render = () => {
        return <div className="mb_15">
            <Button type="default" className="facebook_btn" icon="facebook" onClick={this.onClick} loading={this.state.loading} block>
                {this.props.register ? 'Register' : 'Log in'}  with Facebook
            </Button>
            <Alert className={this.state.error ? 'show mt_15' : 'hide mt_15'} message={this.state.error} type="error" closable/>
            <Alert className={this.state.success ? 'show mt_15' : 'hide mt_15'} message={this.state.success} type="success" closable/>
        </div>
    };

    componentDidMount() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId            : '344724099773045',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v4.0'
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
}

export default FacebookController;
