import React from 'react';
import '../../../assets/frappe-gantt.css'
import {Gmaps, Marker, InfoWindow} from "react-gmaps";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class MapTable extends React.Component {

    state = model(this, {
    });

    clicked = () => {
      alert('asdfsdfadsfsdfadsf')
    };

    render = () => {
        return <div>
            <Gmaps
                width={'100%'}
                height={'500px'}
                lat={44.787197}
                lng={20.457273}
                zoom={2}
                loadingMessage={'Loading...'}
                params={{v: '3.exp', key: 'AIzaSyBEiSYtxrrCDlI6cobIM1bu81stEhA79hs'}}
                onMapCreated={(map) => this.map = map}
            >
                {this.props.data.map((item, index) => {
                    return item.addresses.map((item2, index2) => {
                        if(!item2.lat || !item2.lng) return false;
                        return  <InfoWindow
                            lat={item2.lat}
                            lng={item2.lng}
                            onClick={this.clicked}
                            content={`<div>
                                            <strong>${item.name}</strong> <br/> ${item2.address} 
                                        </div>`}
                        />
                    })
                })}
            </Gmaps>
        </div>
    };


    componentDidMount() {

    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(MapTable);
