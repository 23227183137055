import React from 'react';
import {connect} from "react-redux";
import Comments2 from "../../drawers/partials/Comments2";
import api from "../../modules/api/request";
import model from "../../modules/model/bind";
import {Skeleton} from "antd";

class Wall extends React.Component {

    state = model(this, {
        loading: true,
        comments: []
    });

    render = () => {
        return <div className="container-sm">

            <div className="dashboard_title"> WALL </div>

            {this.state.loading
                ? <Skeleton active={true}/>
                : <Comments2
                    privilege={this.props.user.privileges.includes(102)}
                    subject="wall"
                    comments={this.state.comments}
                    sub={true}/>
            }
        </div>
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.wall).then(function (response) {
            console.log(response.data)
            t.stateSet({loading: false, comments: response.data})
        });
    }
}
const mapStateToProps = state =>    ({
    user: state.user
});
export default connect(mapStateToProps)(Wall);
