import React from 'react';
import {Button, message, Modal, Select, Tabs, Timeline, Icon, Input} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import EditField from "../partials/EditField";
import Avatar from "../../layout/Avatar";
import OfferExport from "./OfferExport";
import moment from "moment";
import OfferHistory from "./OfferHistory";
import OfferContent from "./OfferContent";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { confirm } = Modal;
const format = 'MMM Do YYYY, HH:mm:ss';

class OfferShow extends React.Component   {

    state = model(this, {
        tab: 1,
        statuses: ['Focused activity', 'Additional activity', 'Speculation projects', 'LOST']
    });

    save = (field, value) => {
        let t = this;
        api.post(api.routes.offerUpdate, {id: this.props.offer.id, field: field, value: value}).then(function (response) {
            message.success(`Offer updated.`);
            t.props.drawerChanged('offer');
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this offer?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.offerDelete, {data: {id: t.props.offer.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Offer deleted.`);
                        t.props.toggleDrawer({drawer: 'offer'});
                        t.props.drawerChanged('offer');
                    });

                });
            },
            onCancel() {},
        });
    };

    changeStatus = (status) => {
        let t = this;
        confirm({
            title: `Do you want to change status to '${status}' ?`,
            onOk() {
                return new Promise((resolve, reject) => {
                    t.save('qualified', status);
                    setTimeout(resolve, 1);
                });
            },
            onCancel() {},
        });
    };

    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    render = () => {
        var offer = this.props.offer;
        var edit = this.props.user.privileges.includes(606);
        return <div>
            <div style={{position: 'relative'}} id="offerDrawerShow">
                <Tabs defaultActiveKey="1" onChange={(key) => this.stateSet({tab: key})}>

                    <TabPane tab="Info" key="1">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <h3 className="text-center">{offer.code}</h3>

                                <div className="light_bcg p_15 mb_15">
                                    {offer.qualified
                                        ? <div> Status <h3> {offer.qualified} </h3></div>
                                        : <div>
                                            <EditField
                                                title="Status"
                                                edit={edit}
                                                form={{type: "select", default: offer.status}}
                                                field="select"
                                                parent="offerDrawerShow"
                                                options={this.props.statuses}
                                                onSubmit={(value) => this.save('status', value)}
                                                show={this.notSet(offer.status)} />
                                        </div>
                                    }
                                    <div>
                                        {this.state.statuses.map((item, index) => {
                                            return <span key={index}>
                                                <Button
                                                    className="strong mb_5"
                                                    disabled={offer.qualified === item}
                                                    onClick={() => this.changeStatus(item)}>
                                                    {item}
                                                </Button> &nbsp;
                                            </span>
                                        })}

                                        {offer.owner.uid === this.props.user.uid &&
                                        <Button
                                            className="strong mb_5"
                                            disabled={offer.qualified === 'SOLD'}
                                            onClick={() => this.changeStatus('SOLD')}>
                                            SOLD
                                        </Button>}
                                    </div>

                                    {offer.qualified &&
                                    <div className="mt_15">
                                        <EditField
                                            title="%Funded"
                                            edit={edit}
                                            form={{default: offer.funded, validate: 'max:191'}}
                                            field="input"
                                            onSubmit={(value) => this.save('funded', value)}
                                            show={this.notSet(offer.funded)} />

                                        <EditField
                                            title="%Stax"
                                            edit={edit}
                                            form={{default: offer.stax, validate: 'max:191'}}
                                            field="input"
                                            onSubmit={(value) => this.save('stax', value)}
                                            show={this.notSet(offer.stax)} />
                                    </div>}
                                </div>
                                <EditField
                                    title="Owner"
                                    edit={edit}
                                    form={{type: 'select', default: offer.owner ? offer.owner.uid : []}}
                                    field="persons"
                                    onSubmit={(value) => this.save('owner', value)}
                                    parent="offerDrawerShow"
                                    show={offer.owner
                                        ? <h4><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: offer.owner.uid})}>
                                            <Avatar name={offer.owner.name} avatar={offer.owner.avatar_name} />
                                            {offer.owner.name}</a></h4>
                                        : <h3><i>not set</i></h3>}
                                />

                                <EditField
                                    title="Company"
                                    edit={edit}
                                    form={{type: "select", default: offer.company_id}}
                                    field="company"
                                    parent="offerDrawerShow"
                                    onSubmit={(value) => this.save('company_id', value)}
                                    show={offer.company
                                        ? <h4><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: offer.company.id})}>
                                            {offer.company.logo_name && <Avatar name={offer.company.name} avatar={offer.company.logo_name} />}
                                            {offer.company.name}</a>
                                        </h4>
                                        : <h4><i>not set</i></h4>}
                                />

                                {offer.company &&
                                <div>
                                    <div> Country: <h4>{offer.company.country_name}</h4> </div>
                                    <div> Region: <h4>{offer.company.region} {offer.company.region_code}</h4> </div>
                                </div>}

                                <EditField
                                    title="Contact"
                                    edit={edit}
                                    form={{type: 'select', default: offer.contact ? offer.contact.id : 'undefined'}}
                                    field="contacts"
                                    onSubmit={(value) => this.save('contact_id', value)}
                                    parent="offerDrawerShow"
                                    show={offer.contact
                                        ? <h4><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'contact', id: offer.contact.id})}>
                                            <Avatar name={offer.contact.name} avatar={offer.contact.photo_name} />
                                            {offer.contact.prefix} {offer.contact.name} {offer.contact.middlename} {offer.contact.lastname}</a></h4>
                                        : <h3><i>not set</i></h3>}
                                />

                                <EditField
                                    title="Comment"
                                    edit={edit}
                                    form={{default: offer.comment}}
                                    field="textarea"
                                    onSubmit={(value) => this.save('comment', value)}
                                    show={<div className="pre_wrap">{this.notSet(offer.comment)}</div>} />
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab="Content" key="2">
                        {this.state.tab == 2 && <OfferContent offer={this.props.offer} />}
                    </TabPane>


                    <TabPane tab="EXPORT" key="3">
                        {this.state.tab == 3 && <OfferExport offer={this.props.offer} />}
                    </TabPane>

                    <TabPane tab="History" key="4">
                        {this.state.tab == 4 && <OfferHistory offer={this.props.offer} />}
                    </TabPane>
                </Tabs>
            </div>
            <div className="drawer_footer">
                {edit && !offer.qualified && <><Button className="strong" onClick={this.delete}>Delete offer</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'offer'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferShow);
