import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import CompanyShow from "./EquipmentShow";
import EquipmentCreate from "./EquipmentCreate";
import EquipmentShow from "./EquipmentShow";


class EquipmentDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        machine: false,
        create: false,
        types: [],
    });

    render = () => {
        return  <Drawer
                    visible={this.state.status}
                    width="80%"
                    zIndex={997}
                    title="EQUIPMENT"
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'equipment'})}>

            {!this.state.loading
                ? <div>
                    {this.state.machine && <EquipmentShow equipment={this.state.machine} types={this.state.types} />}
                    {this.state.create && <EquipmentCreate types={this.state.types} />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.equipmentSingle + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, machine: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, machine: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, machine: false, create: false});
        }
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.equipmentTypes).then(function (response) {
            t.stateSet({types: response.data});
        });
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.equipment
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentDrawer);
