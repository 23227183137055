export const UPDATE_USER = 'update user';
export const SET_USER = 'set user';

export function updateUser(field, value) {
    return {
        type: UPDATE_USER,
        payload: {
            field: field,
            value: value
        }
    }
}
export function setUser(value) {
    return {
        type: SET_USER,
        payload: value
    }
}