import React from 'react';
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
import {Alert, Button, Form, Input, message, Modal} from "antd";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import {drawerChanged} from "../../store/actions/drawersActions";
import {connect} from "react-redux";


class FolderCreate extends React.Component {

    state = model(this, {
        status: false,
        loading: false,
        form: {
            name: {validate: 'required|max:191'},
            users: {type: 'select', default: []},
        }
    });

    onSubmit = (form, model) => {
        let t = this;
        t.stateSet({loading: true});
        form["parent"] = this.props.folder;
        api.post(api.routes.filesFolderInsert, form).then(function (response) {
            t.formReset({loading: false, status: false});
            message.success(`Folder created.`);
            t.props.drawerChanged('files');
        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <>
            <Button className="strong mb_5" onClick={() => this.stateSet({status: true})} icon="folder">new folder</Button>

            <Modal
                title="New folder"
                zIndex={1102}
                visible={this.state.status}
                onCancel={() => this.stateSet({status: false})}>

                    <Form style={{position: "relative"}} id="foldersDrawerForm" onSubmit={form.onSubmit}>
                        <Form.Item label="Folder name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                            <Input placeholder="name" maxLength={191} type="text" {...form.name} autoComplete="off" />
                        </Form.Item>

                        <Form.Item label="Users allowed" layout="horizontal" extra="Everyone can access if left blank">
                            <PersonTreeSelect field={form.users} parent="foldersDrawerForm" />
                        </Form.Item>

                        <Alert className={form.error ? 'show' : 'hide'} message={form.error} type="error" closable/>

                        <Button className="strong" htmlType="submit" loading={this.state.loading}>Save folder</Button>
                    </Form>

            </Modal>
        </>
    };

}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(FolderCreate);
