import React from 'react';
import FilesContent from "../../../drawers/files/FilesContent";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";

class Files extends React.Component {

    render = () => {
        return <div>
            <div className="dashboard_block">
                <div className="m_15">
                    <FilesContent />
                </div>
            </div>
        </div>
    };
}
const mapStateToProps = state => ({
    drawer: state.drawers.files
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Files);
