import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoutes from './modules/auth/authRoutes';
import Auth from "./modules/auth/middlewares/Auth";
import Projects from "./views/projectManager/projects/Projects";
import Calendar from "./views/projectManager/reminders/Reminders";
import Tasks from "./views/projectManager/tasks/Tasks";
import Page404 from "./layout/Page404";
import Wall from './views/wall/Wall'
import Employees from './views/company/employees/Employees'
import Departments from "./views/company/departments/Departments";
import Contacts from "./views/CRM/contacts/Contacts";
import Countries from "./views/CRM/countries/Countries";
import Companies from "./views/CRM/companies/Companies";
import Visits from "./views/CRM/visits/Visits";
import Reports from "./views/company/reports/Reports";
import Files from "./views/assets/files/Files";
import Videos from "./views/assets/videos/Videos";
import Offers from "./views/sales/offers/Offers";
import Equipment from "./views/equipment/Equipment";
import Guest from "./modules/auth/middlewares/Guest";
import LoginController from "./modules/auth/controllers/LoginController";

function appRouter() {
    return (
        <Router>
            <Switch>
                <AuthRoutes path="/auth" />

                <Auth exact path="/files" component={Files} />
                <Auth exact path="/videos" component={Videos} />

                <Auth exact path="/tasks" component={Tasks} />
                <Auth exact path="/tasks/responsible" component={Tasks} />
                <Auth exact path="/tasks/participant" component={Tasks} />
                <Auth exact path="/tasks/observer" component={Tasks} />
                <Auth exact path="/tasks/all" component={Tasks} />

                <Auth exact path="/projects" component={Projects} />
                <Auth exact path="/projects/participant" component={Projects} />
                <Auth exact path="/projects/observer" component={Projects} />
                <Auth exact path="/projects/all" component={Projects} />

                <Auth exact path="/calendar" component={Calendar} />
                <Auth exact path="/wall" component={Wall} />
                <Auth exact path="/employees" component={Employees} />
                <Auth exact path="/departments" component={Departments} />
                <Auth exact path="/reports" component={Reports} />

                <Auth exact path="/contacts" component={Contacts} />
                <Auth exact path="/companies" component={Companies} />
                <Auth exact path="/countries" component={Countries} />
                <Auth exact path="/visits" component={Visits} />

                <Auth exact path="/offers" component={Offers} />
                <Auth exact path="/qualifying-offers" component={Offers} />

                <Auth exact path="/equipment" component={Equipment} />

                <Route component={Page404} />
            </Switch>
        </Router>
    );
}

export default appRouter;