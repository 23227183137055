import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Avatar, Button, DatePicker, Form, Input, message, Select} from "antd";
import api from "../../modules/api/request";
import image from "../../assets/images/image.png";
import Items from "../partials/Items";
import FormEditor from "../../modules/editor/FormEditor";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import CompaniesSelect from "../partials/CompaniesSelect";
const {Option} = Select;
const format = 'MMM Do YYYY';

class ReportCreate extends React.Component   {

    state = model(this, {
        loading: false,
        flagName: '',
        form: {
            name: {validate: 'required|max:191'},
            participants: {type: 'select', default: []},
            start: {type: 'select', default: null},
            end: {type: 'select', default: null},
            departing: {validate: 'required|max:191'},
            destination: {validate: 'required|max:191'},
            company_id: {type: 'select', default: 'undefined'},
            companies: {default: []},
            summary: {},
            comments: {},
        }
    });

    onSubmit = (form, model) => {
        let t = this;
        if(form.start)          form.start = form.start.format('YYYY-MM-DD');
        if(form.end)            form.end = form.end.format('YYYY-MM-DD');

        api.post(api.routes.reportInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Report saved.`);
            t.props.toggleDrawer({drawer: 'report'});
            t.props.drawerChanged('report');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="reportDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Trip Name/ID" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Participant(s)" layout="horizontal">
                        <PersonTreeSelect field={form.participants} parent="reportDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Start Date" layout="horizontal">
                        <DatePicker {...form.start} format={format} />
                    </Form.Item>

                    <Form.Item label="End Date" layout="horizontal">
                        <DatePicker {...form.end} format={format} />
                    </Form.Item>
                </div>

                <h3 className="text-center"> BUSINESS TRIP OVERVIEW </h3>

                <Form.Item label="Departing from" validateStatus={form.departing.error && 'error'} help={form.departing.error}>
                    <Input maxLength={191} type="text" {...form.departing} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Destination" validateStatus={form.destination.error && 'error'} help={form.destination.error}>
                    <Input maxLength={191} type="text" {...form.destination} autoComplete="off" />
                </Form.Item>

                <Form.Item label="Company">
                    <CompaniesSelect field={form.company_id} parent="reportDrawerForm" />
                </Form.Item>


                <Items title="Companies"
                       elements={[{name: 'company', type: 'company', parent: 'reportDrawerForm'}, {name: 'description', type: 'textarea'}]}
                       return={(items) => this.stateSet({'form.companies.value': items})} />

                <Form.Item label="Summary of completed trip" validateStatus={form.summary.error && 'error'} help={form.summary.error}>
                    <FormEditor getValue={(value) => this.stateSet({['form.summary.value']: value})} />
                </Form.Item>

                <Form.Item label="Additional comments" validateStatus={form.comments.error && 'error'} help={form.comments.error}>
                    <FormEditor getValue={(value) => this.stateSet({['form.comments.value']: value})} />
                </Form.Item>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable/>
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="reportDrawerForm" loading={this.state.loading}> Save report </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'report'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportCreate);
