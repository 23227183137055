import React from 'react';
import {connect} from 'react-redux';
import {Button, Drawer, Input, Select, Skeleton, Spin} from "antd";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import VisitsTable from "./VisitsTable";
import moment from "moment";
const { Option } = Select;

class Visits extends React.Component {

    state = model(this, {
        loading: false,
        status: false,
        data: [],
        filters: {years: []},
        form: {
            search: {},
            year: {type: 'select', default: 'undefined'},
        }
    });

    filtered = () => {
        return this.state.data.filter((item) => {
            if(this.state.form.search.value)       {
                var name = item.contact.name.toLowerCase();
                var lastname = item.contact.lastname.toLowerCase();
                var search = this.state.form.search.value.toLowerCase();
                if(!name.includes(search) && !lastname.includes(search))        {
                    return false;
                }
            }
            if(this.state.form.year.value)       {
                var date = moment(item.arrival_date).format("YYYY");
                if(date !== this.state.form.year.value)     {
                    return false;
                }
            }
            return true;
        });
    };

    render = () => {
        return <div>
            <div className="dashboard_title">
                VISITS
            </div>

            {this.props.user.privileges.includes(410) &&
                <div className="text-right mb_15">
                    <Button onClick={() => this.props.toggleDrawer({drawer: 'visit'})} className="success" icon="plus">
                        ADD VISIT
                    </Button>
                </div>}



            <div className="dashboard_block">
                <div className="m_15">
                    <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="Search by name" />
                    &nbsp;
                    <Select placeholder="Year" style={{ width: 160 }} allowClear={true} {...this.state.form.year}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.years.map((year, index) => {
                            return <Option key={index} value={year.name}> {year.name} ({year.count}) </Option>
                        })}
                    </Select>
                </div>

                {this.state.loading
                    ? <div className="m_15"><Skeleton active={true} /></div>
                    : <VisitsTable data={this.filtered()} /> }
            </div>
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
         api.get(api.routes.visits).then(function (response) {
             var years = {};
             response.data.map((item) => {
                 if(item.arrival_date)       {
                     var date = moment(item.arrival_date).format("YYYY");
                     if(!years[date]) {
                         years[date] ={name: date, count: 1};
                     }
                     else    years[date].count ++;
                 }
             });
             var filters = {years: Object.values(years)};
             t.stateSet({loading: false, status: t.props.drawer.status, filters: filters, data: response.data});
         });
    };

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.visit
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Visits);
