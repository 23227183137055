import React from 'react';
import model from "../../modules/model/bind";
import folder from "../../assets/images/folder.png";
import api from "../../modules/api/request";


class FileIcon extends React.Component {

    state = model(this, {
        active: false,
    });

    onClick = (item) => {
      this.stateSet({active: !this.state.active});
      this.props.selected(item);
    };

    //application/pdf
    //text/plain

    render = () => {
        var item = this.props.item;
        return <div className={this.state.active ? 'active folder' : 'folder'} onClick={() => this.onClick(item)}>
            <div className="folder_img_container">
                <img src={api.image(item)} />
            </div>
            <div className="folder_title"> {item.alias || item.name} </div>
        </div>
    };

}

export default FileIcon;
