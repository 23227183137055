import React from 'react';
import {Avatar, Input, Select, Tooltip} from "antd";
import api from "../../modules/api/request";
import ContentEditable from 'react-contenteditable'
import update from "react-addons-update";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
const {Option} = Select;

class PdfWorkplace extends React.Component   {

    element(index, node)       {
        var block;
        if(node.element === "text") {
            let style = {};
            style.fontSize = node.fontSize ? parseInt(node.fontSize) : 14;
            if(node.textAlign)      {
                style.textAlign = node.textAlign;
            }
            block = <ContentEditable disabled={this.props.noEdit} style={style}
                onChange={(e) => this.handleChange(e, index, 'html')}
                html={node.html} />
            var append = <>
                &nbsp; font: &nbsp;
                <Input type="number" onChange={(e) => this.handleChange(e, index, 'fontSize')}
                       style={{width: 60}} size="small" disabled={this.props.noEdit}
                       value={node.fontSize ? node.fontSize : ""} />

               &nbsp; align: &nbsp;
                <Select style={{width: 100}} size="small" value={node.textAlign ? node.textAlign : "left"}
                        onChange={(val) => this.handleChangeValue(val, index, 'textAlign')}
                        disabled={this.props.noEdit}>
                    <Option value="left"> left </Option>
                    <Option value="center"> center </Option>
                    <Option value="right"> right </Option>
                </Select>
            </>
        }
        else if(node.element === "title") {
            let style = {};
            style.fontSize = node.fontSize ? parseInt(node.fontSize) : 14;
            block = <ContentEditable disabled={this.props.noEdit}
                 style={style}   className="pdf_title pdf_title_padding"
                 onChange={(e) => this.handleChange(e, index, 'html')} html={node.html} />
            var append = <>
                &nbsp; font: &nbsp;
                <Input type="number" onChange={(e) => this.handleChange(e, index, 'fontSize')}
                       style={{width: 60}} size="small" disabled={this.props.noEdit}
                       value={node.fontSize ? node.fontSize : ""} />
            </>
        }
        else if(node.element === "image") {
            let style = {maxWidth: '100%'};
            style.width = node.width ? node.width + "%" : "30%";
            block =  <div className="text-center">
                <img
                    style={style}
                    onClick={(e) => this.handleChangeImg(index)}
                    src={api.gallery(node.src, true)}
                    className="img" />
            </div>
            var append = <>
                &nbsp; width: &nbsp;
                <Input type="number" onChange={(e) => this.handleChange(e, index, 'width')}
                       style={{width: 60}} size="small" disabled={this.props.noEdit}
                       value={node.width ? node.width : ""} />
              %
            </>
        }
        else if(node.element === "table") {
            var length = node.data[0] ? node.data[0].length : 1;
            block = <table className="table">
                {<thead className="pdf_title">
                <tr><td colSpan={length}>
                    <ContentEditable  disabled={this.props.noEdit}
                        onChange={(e) => this.handleChange(e,index, 'header')}
                      html={node.header} />
                </td></tr>
                </thead>}
                <tbody>
                {node.data.map((row, i) => {
                    return  <tr key={i}>
                        {row.map((field, j) => {
                            let style = {};
                            if(node.weight)     {
                                if(node.weight[j]) {
                                    style.width = node.weight[j] + "%";
                                }
                            }
                            return <td style={style} key={j}>
                                {field.type === "text" &&
                                <ContentEditable style={this.props.noEdit ? {} : {border: "1px solid lightgray"}}
                                    disabled={this.props.noEdit}
                                    onChange={(e) => this.handleTableCellChange(e, index, i, j)} html={field.value} />
                                }
                                {field.type === "image" &&
                                    <div className="text-center">
                                    <img style={field.src ? {maxWidth: '100%'} : {maxWidth: 140}}
                                    onClick={(e) => this.handleTableCellChangeImg(index, i, j)}
                                    src={api.gallery(field.src, true)}
                                    className="img" />
                                    </div>
                                }
                            </td>
                        })}
                        {!this.props.noEdit && <td style={{width: 10}}>
                            <button onClick={() => this.deleteTableRow(index, i)}>del</button>
                        </td>}
                    </tr>
                })}
                </tbody>
                {node.data[0] && node.data[0].length > 1 && !this.props.noEdit && <tfoot>
                <tr>
                    {node.data[0].map((row, i) => {
                        let weight = "";
                        if(node.weight)     {
                            if(node.weight[i]) {
                                weight = node.weight[i];
                            }
                        }
                        return <td key={i} className="text-center">
                            <button onClick={() => this.deleteTableColumn(index, i)}>del</button>
                            &nbsp; <Input placeholder="width"
                                type="number" onChange={(e) => this.handleTableWeight(e, index, i)}
                                   style={{width: 70}} size="small" disabled={this.props.noEdit}
                                   value={weight} />%
                        </td>
                    })}
                </tr>
                </tfoot>}
            </table>;
            var append = <>
                &nbsp; table: &nbsp;
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte red">
                    <button onClick={() => this.addTableRow(index, node.data[0].length)}>add row</button>
                </Tooltip>
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte kolonu">
                    <button onClick={() => this.addTableColumn(index)}>add column</button>
                </Tooltip>
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte kolonu">
                    <button onClick={() => this.addTableColumn(index, true)}>add image column</button>
                </Tooltip>
            </>
        }
        let first = false;
        if(index===-1) {
            first = true;
        }
        return <div key={index} className="workplaceBlock" style={this.props.noEdit ? {border: "0 solid transparent"} : {}}>
            <div style={{padding: 5}}>
                {block}
            </div>
            {!this.props.noEdit &&
            <div className="light_bcg" style={{padding: 5}}>
                Add below: &nbsp;
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte TEXT ispod ovog bloka">
                    <button onClick={() => this.addNode(index, 'text')}>text</button>
                </Tooltip>
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte TITLE ispod ovog bloka">
                    <button onClick={() => this.addNode(index, 'title')}>title</button>
                </Tooltip>
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte TABLE ispod ovog bloka">
                    <button onClick={() => this.addNode(index, 'table')}>table</button>
                </Tooltip>
                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Dodajte IMAGE ispod ovog bloka">
                    <button onClick={() => this.addNode(index, 'image')}>img</button>
                </Tooltip>
                {!first && <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Obrisite blok">
                    <button onClick={() => this.deleteNode(index)}>del</button>
                </Tooltip>}
                    {append}

                <Tooltip customCss={`white-space: nowrap;`} placement="right" content="Pocnite blok na novoj strani">
                    <button className="pull-right"
                        onClick={() => this.handleChangeValue(node.break ? false : true, index, 'break')}>
                        {node.break && "remove"} on new page
                    </button>
                </Tooltip>
            </div>}
        </div>
    }

    handleChangeImg = (index) => {
        if(this.props.noEdit) return false;
        let t  = this;
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
            let t = this;
            var file = files[0];
            const newData = update(t.props, {structure: {[index]: {src:  {$set: file.name}}}});
            t.props.callback(newData.structure);
        }});
    };



    handleChange = (e, index, element) => {
        var val = e.target.value;
        if(element === 'fontSize' || element === 'width')  {
            if(val > 100)   return false;
        }
        val = val.replace(/<br>/g, '@@@br@@@').replace(/<div>/g, '@@@div@@@')
            .replace(/<\/div>/g, '@@@closediv@@@');
        val = val.replace(/(<([^>]+)>)/gi, "")
            .replace(/@@@br@@@/g, '<br>').replace(/@@@div@@@/g, '<div>')
        .replace(/@@@closediv@@@/g, '</div>');
        console.log(val)
        const newData = update(this.props, {structure: {[index]: {[element]:  {$set: val}}}});
        this.props.callback(newData.structure);
    };
    handleChangeValue = (val, index, element) => {
        const newData = update(this.props, {structure: {[index]: {[element]:  {$set: val}}}});
        this.props.callback(newData.structure);
    };
    handleTableWeight = (e, index, pos) => {
        var val = e.target.value;
        let weight = this.props.structure[index].weight;
        if(!weight)       {
            weight = {}
        }
        weight[pos] = val;
        const newData = update(this.props, {structure: {[index]: {weight:  {$set: weight}}}});
        this.props.callback(newData.structure);
    }
    handleTableCellChange = (e, index, tr, td) => {
        var val = e.target.value;
        val = val.replace(/<br>/g, '@@@br@@@').replace(/<div>/g, '@@@div@@@')
            .replace(/<\/div>/g, '@@@closediv@@@');
        val = val.replace(/(<([^>]+)>)/gi, "")
            .replace(/@@@br@@@/g, '<br>').replace(/@@@div@@@/g, '<div>')
            .replace(/@@@closediv@@@/g, '</div>');
        const newData = update(this.props, {structure: {[index]: {data: {[tr]: {[td]: {value: {$set: val}}}}}}});
        this.props.callback(newData.structure);
    };
    handleTableCellChangeImg = (index, tr, td) => {
        if(this.props.noEdit) return false;
        let t  = this;
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                let t = this;
                var file = files[0];
                const newData = update(t.props, {structure: {[index]: {data: {[tr]: {[td]: {src: {$set: file.name}}}}}}});
                t.props.callback(newData.structure);
            }});
    };

    addNode = (index, node) =>      {
        var el;
        if(node === 'text')     {
            el = {element: 'text', html: ''};
        }
        else if(node === 'title')     {
            el = {element: 'title', html: 'TITLE'};
        }
        else if(node === 'image')     {
            el = {element: 'image', src: '', width: '30'};
        }
        else if(node === 'table')     {
            el = {element: 'table', header: "Title",
                data: [[{type: "text", "value": ""}, {type: "text", "value": ""}], [{type: "text", "value": ""}, {type: "text", "value": ""}]]};
        }
        const newData = update(this.props, {structure: {$splice: [[index+1, 0, el]]}});
        this.props.callback(newData.structure);
    };
    deleteNode = (index)  =>     {
        const newData = update(this.props, {structure: {$splice: [[index, 1]]}});
        this.props.callback(newData.structure);
    };

    addTableRow = (index, length) =>       {
        const newData = update(this.props, {structure: {[index]: {data: {$push: [new Array(length).fill({type: "text", "value": ""})]}}}});
        this.props.callback(newData.structure);
    };

    deleteTableRow = (index, tr) =>       {
        const newData = update(this.props, {structure: {[index]: {data: {$splice: [[tr, 1]]}}}});
        this.props.callback(newData.structure);
    };

    addTableColumn = (index, image=false) =>       {
        let filter = this.props.structure[index].data.filter((item) => {
            if(image)       {
                return item.push({type: 'image', src: ''})
            }
            return item.push({type: "text", "value": ""});
        });
        const newData = update(this.props, {structure: {[index]: {data: {$set: filter}}}});
        this.props.callback(newData.structure);
    };


    deleteTableColumn = (index, i) =>  {
         let filter = this.props.structure[index].data.filter((item) => {
            return item.splice(i, 1);
        });
        const newData = update(this.props, {structure: {[index]: {data: {$set: filter}}}});
        this.props.callback(newData.structure);
    };

    render = () => {
        return <div className="workplace">
            <div>
                {!this.props.noEdit && this.element(-1, {})}
                {this.props.structure.map((node, index) => {
                    return this.element(index, node)
                })}
            </div>
        </div>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(PdfWorkplace);
