import React from 'react';
import { Route, Switch } from "react-router-dom";
import LoginController from './controllers/LoginController';
import RegisterController from './controllers/RegisterController';
import PasswordController from './controllers/PasswordController';
import Guest from "./middlewares/Guest";
import routes from "./routes";

function authRoutes() {
    return (
        <Route
            render={() => (
                <>
                    <Switch>
                        <Guest path={routes.login} exact component={LoginController} />
                        <Guest path={routes.register} exact component={RegisterController}/>
                        <Guest path={routes.password} exact component={PasswordController}/>
                    </Switch>
                </>
            )}
        />
    );
}

export default authRoutes;
