import React from 'react';
import {Document, Image, Page, Text, StyleSheet, View, Font} from "@react-pdf/renderer";
import logotype from "../../assets/images/logotype.png";
import {Table, TableHeader, TableCell, TableBody, DataTableCell} from "@david.kucsai/react-pdf-table";
import roboto from "../../assets/Roboto-Regular.ttf"
import api from "../../modules/api/request";

Font.register({
    family: 'Roboto',
    src: roboto
});
const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontFamily: 'Roboto'
    },
    view: {
        marginBottom: 20,
        fontSize: 14
    },
    image: {
        maxWidth: '100%',
    },
    tableCell: {
        padding: 8
    },
    fixed: {
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

    },
    title: {
        padding: 6,
      color: 'white',
      backgroundColor: '#ED1C24',
        textAlign: "center"
    },
    pagination: {
        marginTop: 5,
        fontSize: 11
    },
});

class PdfRenderer extends React.Component   {

    node(index, node)       {
        var block;
        if(node.element === "text") {
            let style = {};
            if(node.fontSize)       {
                style.fontSize = node.fontSize;
            }
            if(node.textAlign)       {
                style = {...style, textAlign: node.textAlign};
            }
            block = <Text style={style} wrap>{this.prewrap(node.html)}</Text>
        }
        if(node.element === "title") {
            let style = styles.title;
            if(node.fontSize)       {
                style = {...style, fontSize: node.fontSize};
            }
            block = <Text style={style} wrap>{this.prewrap(node.html)}</Text>
        }
        else if(node.element === "image") {
            block = this.image(node)
        }
        else if(node.element === "table") {
            var body =  <TableBody>
                            {node.data.length > 0 && node.data[0].map((row, i) => {
                                var weight = 1;
                                if(node.weight)     {
                                    if(node.weight[i])  weight = node.weight[i]/100;
                                }
                                if(row.type === "image") {
                                    return <DataTableCell key={i} getContent={(r) => this.image(r[i])} />
                                }
                                return <DataTableCell key={i}
                                  getContent={(r) => <Text style={styles.tableCell}>{this.prewrap(r[i].value)}</Text>} />
                            })}
                        </TableBody>;
            if(node.header)     {
                block = <Table data={node.data}>
                    <TableHeader>
                        <TableCell>
                            <Text style={styles.title}>{node.header}</Text>
                        </TableCell>
                    </TableHeader>
                    {body}
                </Table>
            }
            else    {
                block = <Table data={node.data}>
                    <TableHeader includeBottomBorder={false} />
                    {body}
                </Table>
            }
        }
        if(node.break)  {
            return <View break key={index} style={styles.view}>{block}</View>
        }
        return <View key={index} style={styles.view}>{block}</View>
    };

    image = (node) => {
        let style = {maxWidth: '100%'}
        style.width = node.width ? node.width + "%" : "50%"
        return <View style={{display: "flex", alignItems: "center"}}>
            {/*<Image src="logotype.png" style={style} />*/}
            <Image src={api.gallery(node.src, true)} style={style} />
        </View>
    };

    prewrap = (value) =>  {
        if(!value) return "";
        value = value.replace(/&nbsp;/g, " ").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'")
        return value.replace(/<div>/gi,"\n").replace(/<\/div>/gi,"").replace(/<br>/gi,"\n");
    };

    render = () => {
        return <Document style={styles.document}>
            <Page size="A4" wrap style={styles.page}>

                <View  render={({ pageNumber, totalPages }) => (
                    <View style={styles.fixed}>
                        <Text> {this.props.title} </Text>
                        <Text style={styles.pagination} render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} of ${totalPages}`
                        )} />
                        <Image src={logotype} />
                    </View>
                )} fixed />

                {this.props.structure.map((node, index) => {
                    return this.node(index, node)
                })}

            </Page>
        </Document>
    };
}

export default PdfRenderer;
