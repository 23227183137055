import React from 'react';
import RegisterView from '../views/registerView';
import model from '../../model/bind';
import api from '../../api/request';

class RegisterController extends React.Component   {

    state = model(this, {
        form: {
            email: {validate: 'required|email|max:191'},
            password: {validate: 'required|min:6|max:191'},
            name: {validate: 'required|max:191'}
        }
    });

    onSubmit = (form, model) => {
        api.post(api.routes.register, form).then(function (response) {
            model.success(response.data);
            model.loader(false);

        }).catch(function (error) {
            model.error(error);
            model.loader(false);
        });
    };

    render = () => {
        return <RegisterView {...this.state.form} />
    }
}

export default RegisterController;
