import React from 'react';
import {Select, Avatar} from "antd";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
const {Option} = Select;

class CountriesSelect extends React.Component   {

    state = model(this, {
        countries: [],
        loading: false,
    });

    render = () => {
       return <Select loading={this.state.loading} placeholder="Select country" {...this.props.field}
                      allowClear={true} showSearch={true}
                  filterOption={(input, option) =>
                      option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={trigger => document.getElementById(this.props.parent)}>
            {this.state.countries.map((item, index) => {
                var flag = item.flag_name ? <Avatar size="small" src={api.avatar(item.flag_name)} /> : '';
                var region = item.region ? '('+ item.region +')' : '';
                return <Option key={index} value={item.id}>
                    {flag} {item.name} {region}
                </Option>
            })}
        </Select>
    };

    componentDidMount() {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.countries).then(function (response) {
            t.stateSet({countries: response.data, loading: false});
        });
    }
}

export default CountriesSelect;
