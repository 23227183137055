import React from 'react';
import {Drawer, Skeleton} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import OfferCreate from "./OfferCreate";
import OfferShow from "./OfferShow";


class OfferDrawer extends React.Component   {

    state = model(this, {
        loading: true,
        status: false,
        offer: false,
        create: false,
        regions: [{name: "Europe", code: "EU"}, {name: "Asia and Pacific", code: "APAC"},
            {name: "Middle East & North Africa", code: "MENA"},{name: "Latin America", code: "LA"},
            {name: "North America", code: "NA"},{name: "Commonwealth of Independent States", code: "CIS"}, {name: "Africa", code: "AF"}],
        statuses: [{name: "Draft", id: "draft"}, {name: "Offer sent", id: "sent"}, {name: "Follow-up", id: "follow-up"},
            {name: "Negotiation", id: "negotiation"}, {name: "Another project", id: "another project"},
            {name: "Delayed", id: "delayed"}, {name: "Postponed", id: "postponed"}],
        sources: ["Website", "Email", "Returnig", "Partner", "Exibition", "Social media", "Commercial visit"]
    });

    render = () => {
        return  <Drawer
                    visible={this.state.status}
                    width="90%"
                    zIndex={997}
                    title="OFFER"
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'offer'})}>

            {!this.state.loading
                ? <div>
                    {this.state.offer &&
                    <OfferShow
                        offer={this.state.offer}
                        regions={this.state.regions}
                        statuses={this.state.statuses}
                        sources={this.state.sources}
                        />}
                    {this.state.create &&
                    <OfferCreate
                        regions={this.state.regions}
                        statuses={this.state.statuses}
                        sources={this.state.sources}
                    />}
                </div>
                : <Skeleton active />
            }
        </Drawer>
    };

    load = () => {
        if(this.props.drawer.id)  {
            let t = this;
            t.stateSet({status: t.props.drawer.status, loading: true});
            api.get(api.routes.offer + '/' + this.props.drawer.id).then(function (response) {
                t.stateSet({loading: false, offer: response.data, create: false});
            });
        }
        else if(this.props.drawer.status)       {
            this.stateSet({status: this.props.drawer.status, loading: false, offer: false, create: true});
        }
        else        {
            this.stateSet({status: this.props.drawer.status, loading: false, offer: false, create: false});
        }
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.reload)       {
            this.props.toggleDrawer({drawer: 'offer', id: this.props.drawer.id});
        }
        else if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.offer
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferDrawer);
