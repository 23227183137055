import React from 'react';
import {Table} from "antd";
import Avatar from "../../../layout/Avatar"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import TasksTable from "../tasks/TasksTable";

class ProjectsTable extends React.Component   {

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e:e, drawer: 'project', project: record.id})}>
                    {text} {record.role && (!record.owner_accept || !record.user_accept) && <i className="main"> pending... </i>}
                </a>
            }

        },
        {
            title: 'Created by',
            dataIndex: 'owner',
            key: 'owner',
            render: (text, record) => {
                return record.users.map((user) => {
                    if(user.pivot.role === 1) {
                        return <div key={user.uid}>
                           <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                                <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
                           </a>
                        </div>;
                    }
                })
            }
        },
        {
            title: 'Members',
            dataIndex: '',
            key: 'members',
            render: (text, record) => {
                return <strong> {record.users.length} </strong>
            }
        },
    ];
    render = () => {
        return <Table
                pagination={false}
                dataSource={this.props.data}
                columns={this.columns}
                expandedRowRender={record => {
                    return <TasksTable last="Project" data={record.tasks} inset={true} />
                }}
                rowClassName={(record) => {
                    if(!record.tasks)   return 'hide-drop';
                    if(record.tasks.length === 0) return 'hide-drop';
                }}
                size="small"
                rowKey="id" />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTable);
