import React from 'react';
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import UserDrawer from "../drawers/user/UserDrawer"
import TaskDrawer from "../drawers/tasks/TaskDrawer";
import ProjectDrawer from "../drawers/projects/ProjectDrawer";
import RemindersDrawer from "../drawers/reminders/RemindersDrawer";
import FilesModal from "../drawers/files/FilesModal";
import model from "../modules/model/bind";
import {Button} from "antd";
import {toggleDrawer} from "../store/actions/drawersActions";
import {connect} from "react-redux";
import ContactDrawer from "../drawers/contact/ContactDrawer";
import CountryDrawer from "../drawers/country/CountryDrawer";
import CompanyDrawer from "../drawers/company/CompanyDrawer";
import VisitShow from "../drawers/visit/VisitShow";
import VisitDrawer from "../drawers/visit/VisitDrawer";
import ReportDrawer from "../drawers/report/ReportDrawer";
import VideoDrawer from "../drawers/video/VideoDrawer";
import OfferDrawer from "../drawers/offer/OfferDrawer";
import PdfDrawer from "../drawers/PDF/PdfDrawer";
import EquipmentDrawer from "../drawers/equipment/EquipmentDrawer";


class Layout extends React.Component   {

    state = model(this, {
        popup: false,
        toggleMenu: false
    });

    render = () => {
        return <div>
            <TaskDrawer />
            <ProjectDrawer />
            <UserDrawer />
            <RemindersDrawer />
            <FilesModal />
            <ContactDrawer />
            <CountryDrawer />
            <CompanyDrawer />
            <VisitDrawer />
            <ReportDrawer />
            <VideoDrawer />
            <OfferDrawer />
            <PdfDrawer />
            <EquipmentDrawer />

            <div className="popup_modal" style={{display: this.state.popup ? 'block' : 'none'}}>
                <div className="popup_overlay" onClick={() => this.stateSet({popup: false})} />
                <Button className="strong popup_close" icon="close" onClick={() => this.stateSet({popup: false})}  />
                {this.state.popup && <img src={this.state.popup}  />}
            </div>

            <Header />

            <LeftMenu open={this.state.toggleMenu} />
            {this.state.toggleMenu &&
            <div className="dashboard_menu_overlay" onClick={() => this.stateSet({toggleMenu: false})} />}

            <div className="dashboard_content">
                <div className="container-fluid">
                    <this.props.component pathname={this.props.history.location.pathname} />
                </div>
            </div>

            <Button type="primary" shape="circle" onClick={() => this.stateSet({toggleMenu: !this.state.toggleMenu})}
                    className="menu_toggle_btn responsive_show"
                    icon="menu" />
        </div>
    };

    componentDidMount() {
        let t = this;
        document.addEventListener("click", function (e) {
            if(e.target.tagName === 'IMG')        {
                var parent = e.target.parentElement;
                if(parent.classList.contains('popup'))  {
                    e.preventDefault();
                    t.stateSet({popup: parent.getAttribute("href")});
                    return false;
                }
            }
            if(e.target.tagName === 'A')        {
                if(e.target.getAttribute('data-project'))       {
                    e.preventDefault();
                    t.props.toggleDrawer({drawer: 'project', project: e.target.getAttribute('data-project')});
                }
                else if(e.target.getAttribute('data-task'))       {
                    e.preventDefault();
                    t.props.toggleDrawer({drawer: 'task', task: e.target.getAttribute('data-task')});
                }
                else if(e.target.getAttribute('data-contact'))       {
                    e.preventDefault();
                    t.props.toggleDrawer({drawer: 'contact', id: e.target.getAttribute('data-contact')});
                }
                else if(e.target.getAttribute('data-visit'))       {
                    e.preventDefault();
                    t.props.toggleDrawer({drawer: 'visit', id: e.target.getAttribute('data-visit')});
                }
                else if(e.target.getAttribute('data-offer'))       {
                    e.preventDefault();
                    t.props.toggleDrawer({drawer: 'offer', id: e.target.getAttribute('data-offer')});
                }
            }
        });
    }
}
const mapStateToProps = state =>    ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
