import React from 'react';
import {Select, Avatar} from "antd";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
const {Option} = Select;

class FairSelect extends React.Component   {

    state = model(this, {
        fairs: [],
        loading: false,
    });

    render = () => {
       return <Select loading={this.state.loading} placeholder="Select fair" {...this.props.field}
                      allowClear={true} showSearch={true}
                  filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={trigger => document.getElementById(this.props.parent)}>
            {this.state.fairs.map((item, index) => {
                return <Option key={index} value={item.id}>
                    {item.name}
                </Option>
            })}
        </Select>
    };

    componentDidMount() {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.fairs).then(function (response) {
            t.stateSet({fairs: response.data, loading: false});
        });
    }
}

export default FairSelect;
