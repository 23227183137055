import React from 'react';
import {Modal, Spin} from "antd";
import api from "../../../modules/api/request";
import EmployeesTable from "./EmployeesTable";
import EmployeesSettings from "./EmployeesSettings";
import model from "../../../modules/model/bind"
import {connect} from "react-redux";

class Employees extends React.Component {

    state = model(this, {
        loading: true,
        uid: false,
        employees: []
    });

    render = () => {
        return <div>
            <Modal
                visible={this.state.uid !== false}
                onCancel={() => this.stateSet({uid: false})}>
                    <EmployeesSettings
                        uid={this.state.uid}
                        stateSetParent={this.stateSet}
                    />
            </Modal>

            <div className="dashboard_title">
                EMPLOYEES
            </div>

            <div className="dashboard_block">
                <Spin spinning={this.state.loading}>

                    <EmployeesTable
                        data={this.state.employees}
                        eStateSetParent={this.eStateSet}/>

                </Spin>
            </div>
        </div>
    };

    loadEmployees = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.employees).then(function (response) {
            t.stateSet({loading: false, employees: response.data});
        })
    };

    componentDidMount() {
        this.loadEmployees();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
}
const mapStateToProps = state => ({

});
export default connect(mapStateToProps)(Employees);
