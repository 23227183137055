import React from 'react';
import model from "../../modules/model/bind";
import {Button, Form, Input, message} from "antd";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import Avatar from "../../layout/Avatar";
import api from "../../modules/api/request";
import {updateUser} from "../../store/actions/userActions";
import EditField from "../partials/EditField";

class UserShow extends React.Component   {

    state = model(this, {
        user: this.props.user,
        edit: this.props.user.uid === this.props.mUser.uid
    });

    save = (field, value) => {
        let t = this;
        api.post(api.routes.profileUpdate, {field: field, value: value}).then(function (response) {
            t.props.drawerChanged('user');
            t.props.updateUser(field, value);
            message.success(`Profile updated.`);
        });
    };

    changeAvatar = (e) => {
        e.preventDefault();
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
            let t = this;
            var file = files[0];
            api.post(api.routes.profileUpdate, {field: 'avatar', value: file.id}).then(function (response) {
                t.props.drawerChanged('user');
                t.props.updateUser('avatar_name', file.name);
                message.success(`Avatar changed.`);
            });
        }});
    };

    render = () => {
        var user = this.state.user;
        return <div className="row marginless">
            <div style={{width: 100}}>
                {this.state.edit
                    ? <div>
                        <a href="/" onClick={this.changeAvatar}>
                            <Avatar name={user.name} avatar={user.avatar_name} size={90} />
                        </a>
                        <i>Click to change</i>
                    </div>
                    : <Avatar name={user.name} avatar={user.avatar_name} size={90} />}
            </div>

            <div className="col">

                <EditField
                    title="Name"
                    edit={this.state.edit}
                    default={user.name}
                    form={{validate: "required|max:191", default: user.name}}
                    field="input"
                    onSubmit={(value) => this.save('name', value)}
                    show={<h4>{user.name}</h4>} />


                <EditField
                    title="Email"
                    edit={this.state.edit}
                    default={user.uemail}
                    form={{validate: "required|email|max:191", default: user.uemail}}
                    field="input"
                    onSubmit={(value) => this.save('uemail', value)}
                    show={<h4>{user.uemail}</h4>} />

                <EditField
                    title="Phone"
                    edit={this.state.edit}
                    default={user.phone}
                    form={{validate: "max:26", default: user.phone}}
                    field="input"
                    onSubmit={(value) => this.save('phone', value)}
                    show={<h4>{user.phone ? user.phone : 'not set'}</h4>} />

                <EditField
                    title="Address"
                    edit={this.state.edit}
                    default={user.address}
                    form={{validate: "max:191", default: user.address}}
                    field="input"
                    onSubmit={(value) => this.save('address', value)}
                    show={<h4>{user.address ? user.address : 'not set'}</h4>} />

                <EditField
                    title="Position"
                    edit={this.state.edit}
                    default={user.position}
                    form={{validate: "max:191", default: user.position}}
                    field="input"
                    onSubmit={(value) => this.save('position', value)}
                    show={<h4>{user.position ? user.position : 'not set'}</h4>} />
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    mUser: state.user,
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    updateUser: updateUser,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(UserShow);
