import React from 'react';
import LoginView from '../views/loginView';
import model from '../../model/bind';
import api from '../../api/request';
import {loginUser} from "../userProvider";

class LoginController extends React.Component   {

    state = model(this, {
        form:   {
            email: {validate: 'required|email|max:191'},
            password: {validate: 'required|min:6|max:191'},
            remember: {}
        },
        test1: 'aasdfs',
        test2: 'a2222asdfs',
    });

    onSubmit = (form, model) => {
        api.post(api.routes.login, form).then(function (response) {
            model.success(response.data);
            model.loader(false);
            loginUser(response);

        }).catch(function (error) {
            model.error(error);
            model.loader(false);
        });
    };

    render = () => {
        return <LoginView {...this.state.form} />
    }
}

export default LoginController;
