import React from 'react';
import {Button, Drawer, Skeleton} from "antd";
import update from "react-addons-update";
import RemindersTask from "./RemindersTask";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
import {toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import moment from "moment";
const dateFormat = 'MMM Do YYYY';

class RemindersDrawer extends React.Component   {

    state = model(this, {
        status: false,
        loading: true,
        date: false,
        reminders: false,
        tasks: false
    });

    addReminder = () => {
        const obj = {date: this.state.date};
        const newData = update(this.state,{reminders: {$push: [obj]} });
        this.setState(newData);
    };

    updateReminder = (index, reminder) => {
        const newData = update(this.state,{reminders: {[index]: {$set: reminder}} });
        this.setState(newData);
    };

    removeReminder = (index) => {
        const newData = update(this.state,{reminders: {$splice: [[index, 1]]} });
        this.setState(newData);
    };

    render = () => {
        return  <Drawer
            title="REMINDERS"
            width="50%"
            placement="right"
            zIndex="103"
            closable={false}
            onClose={() => this.props.toggleDrawer({drawer: 'reminders'})}
            visible={this.state.status}>

            <Skeleton active loading={this.state.loading}>
                {this.state.reminders &&
                    <div>
                        <h3 className="mb_15">{moment(this.state.date).format(dateFormat)}</h3>

                        {this.state.reminders.map((item, index) => {
                             return  <RemindersTask
                                     item={item}
                                     index={index}
                                     key={index}
                                     zIndex={990}
                                     removeReminder={this.removeReminder}
                                     updateReminder={this.updateReminder}
                                 />
                            })}

                        {this.state.tasks.map((item, index) => {
                            return  <div className="light_bcg p_15 mb_15" key={index}>
                                <h3><span className="info"> TASK ENDS: </span>
                                    <i><a href="/" className="black"
                                          onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'task', task: item.id})}>
                                        {item.name}
                                    </a></i></h3>
                            </div>
                        })}
                        <div className="drawer_footer">
                            <Button className="strong" onClick={this.addReminder}>Add reminder</Button> &nbsp;
                        </div>
                    </div>
                }
            </Skeleton>
        </Drawer>
    };

    load = () => {
        if(!this.props.drawer.date) {
            this.stateSet({loading: false, date: false, reminders: false, tasks: false, status: false});
            return;
        }

        let t = this;
        t.stateSet({loading: true, status: t.props.drawer.status});
        api.get(api.routes.reminders + '/' + this.props.drawer.date).then(function (response) {
            t.stateSet({loading: false, reminders: response.data.reminders, tasks: response.data.tasks,
                date: t.props.drawer.date});
        });
    };

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)      {
            this.load();
        }
    }
}
const mapStateToProps = state =>    ({
    drawer: state.drawers.reminders
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(RemindersDrawer);
