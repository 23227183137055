import update from "react-addons-update";

export default function(state = {
        task: {status: false, changed: false},
        project: {status: false, changed: false},
        reminders: {status: false, changed: false},
        user: {status: false, changed: false},
        files: {status: false, changed: false},
        notifications: {status: false, changed: false},
        settings: {status: false, changed: false},
        department: {status: false, changed: false},
        contact: {status: false, changed: false},
        country: {status: false, changed: false},
        company: {status: false, changed: false},
        visit: {status: false, changed: false},
        report: {status: false, changed: false},
        video: {status: false, changed: false},
        offer: {status: false, changed: false},
        pdf: {status: false, changed: false},
         equipment: {status: false, changed: false},
    }, {type, payload})      {
    switch(type)        {
        case 'TOGGLE DRAWER':
            if(payload.e) payload.e.preventDefault();
            payload.status = !state[payload.drawer].status;
            payload.changed = state[payload.drawer].changed;
            return update(state, {[payload.drawer]: {$set: payload}});
        case 'DRAWER CHANGED':
            return update(state, {[payload]: {changed: {$set: !state[payload].changed}}});
        default:
            return state;
    }
}