import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Icon, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import AuthView from './authView';
import routes from "../routes";

function passwordView({onSubmit, email, error, success, loading})   {
    return (
        <AuthView>
            <form action="#" onSubmit={onSubmit}>
                <Form.Item validateStatus={email.error ? 'error' : 'success'} help={email.error}>
                    <Input maxLength={191} prefix={<Icon type="mail" />} type="email" {...email} placeholder="email" />
                </Form.Item>

                <div>
                    <Button type="primary" htmlType="submit" loading={loading} className="mb_15" block>
                        Send reset link to my email
                    </Button>
                    <Alert className={error ? 'show' : 'hide'} message={error} type="error" closable/>
                    <Alert className={success ? 'show' : 'hide'} message={success} type="success" closable/>
                </div>

                <Link to={routes.login}>Login page</Link>
            </form>
        </AuthView>
    )
}

passwordView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    email: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
};

export default passwordView;
