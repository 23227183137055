import React from 'react';
import { connect } from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import routes from "../routes";

const Guest = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        rest.user === false
            ? <Component {...props} />
            : <Redirect to={routes.dashboard} />
    )} />
);

const mapStateToProps = state =>    ({
    user: state.user
});

export default connect(mapStateToProps)(Guest);
