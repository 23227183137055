import React from 'react';
import {Select, Avatar} from "antd";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";
const {Option} = Select;

class CompaniesSelect extends React.Component   {

    state = model(this, {
        companies: [],
        loading: false,
    });

    render = () => {
        var spread = this.props.field;
        if(this.props.return) spread = {onChange: (val) => this.props.return(val)}
       return <Select
           disabled={this.props.disabled}
           loading={this.state.loading}
           placeholder="Select company"
           {...spread}
           allowClear={true}
           showSearch={true}
           filterOption={false}
           onSearch={(input) => this.load(input)}
            getPopupContainer={trigger => document.getElementById(this.props.parent)}>

           {this.state.companies.map((item, index) => {
                var logo = item.logo_name ? <Avatar size="small" src={api.avatar(item.logo_name)} /> : '';
                var region = item.region ? '('+ item.region + ')' : '';
                return <Option key={index} value={item.id}>
                    {logo} {item.name} {region}
                </Option>
            })}

        </Select>
    };

    load = (search = "") => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.companies, {params: {search: search}}).then(function (response) {
            t.stateSet({companies: response.data.data, loading: false});
        });
    };

    componentDidMount() {
       this.load()
    }
}

export default CompaniesSelect;
