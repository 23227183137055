import React from 'react';
import {Button, Drawer} from "antd";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {toggleDrawer} from "../../store/actions/drawersActions";
import {PDFViewer} from "@react-pdf/renderer";
import PdfRenderer from "./PdfRenderer";
import PdfWorkplace from "./PdfWorkplace";


class PdfDrawer extends React.Component   {

    state = model(this, {
        title: "",
        status: false,
        structure: []
    });

    render = () => {
        let title = "PDF EDITOR"
        if(this.props.drawer.save)      {
            title = <Button type="primary" size="small" className="strong"
                    onClick={() => this.props.drawer.save('structure', this.state.structure)}>
                SAVE CHANGES
            </Button>
        }
        return  <Drawer
                    className="pdf_drawer"
                    visible={this.state.status}
                    width="100%"
                    zIndex={999}
                    title={title}
                    placement="right"
                    closable={true}
                    onClose={() => this.props.toggleDrawer({drawer: 'pdf'})}>

            <div className="row" style={{height: '100%'}}>
                <div className="col-md-6" style={{height: '100%'}}>



                    <PdfWorkplace
                        structure={this.state.structure}
                        callback={(structure) => this.stateSet({structure: structure})} />

                </div>
                <div className="col-md-6" style={{height: '100%'}}>

                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        <PdfRenderer title={this.state.title} structure={this.state.structure} />
                    </PDFViewer>

                </div>
            </div>


        </Drawer>
    };


    load = () => {
        const title = this.props.drawer.title ? this.props.drawer.title : 'Stax Technologies';
        const structure = this.props.drawer.structure ? this.props.drawer.structure : [];
        this.stateSet({status: this.props.drawer.status, title: title, structure: structure});
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer !== prevProps.drawer)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.pdf
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(PdfDrawer);
