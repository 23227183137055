
export function toggleDrawer(value) {
    return {
        type: 'TOGGLE DRAWER',
        payload: value
    }
}
export function drawerChanged(value) {
    return {
        type: 'DRAWER CHANGED',
        payload: value
    }
}
