import React from 'react';

function Page404(props) {
  return (
    <div>
      Page not found!
    </div>
  );
}

export default Page404;
