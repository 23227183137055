import React from 'react';
import {Button, Form, Icon, Input, message, Modal, Select, Tag, Tooltip} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import EditField from "../partials/EditField";
import moment from "moment";
import Avatar from "../../layout/Avatar";
import update from "react-addons-update";
const { confirm } = Modal;
const sqlFrmat = 'YYYY-MM-DD';
const format = 'MMM Do YYYY';

class VideoShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit,
        tags: this.props.video.tags,
        form: {
            tagInput: {validate: 'max:191'},
        }
    });

    save = (field, value) => {
        let t = this;
        api.post(api.routes.videoUpdate, {id: this.props.video.id, field: field, value: value}).then(function (response) {
            message.success(`Video updated.`);
            t.props.drawerChanged('video');
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this video?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.videoDelete, {data: {id: t.props.video.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Video deleted.`);
                        t.props.toggleDrawer({drawer: 'video'});
                        t.props.drawerChanged('video');
                    });

                });
            },
            onCancel() {},
        });
    };

    removeTag = (e, index) => {
        e.preventDefault();
        var id = this.state.tags[index].id;
        let t = this;
        api.delete(api.routes.videoTagDelete, {data: {id: id, video: t.props.video.id}}).then(function (response) {
            message.success(`Tag deleted.`);
            const newData = update(t.state, {tags: {$splice: [[index, 1]] }});
            t.setState(newData);
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.addTag();
        }
    };

    addTag = () => {
        var value = this.state.form.tagInput.value;
        let t = this;
        api.post(api.routes.videoTagInsert, {tag: value, video: t.props.video.id}).then(function (response) {
            message.success(`Tag inserted.`);
            const newData = update(t.state, {tags: {$push: [response.data]}, form: {tagInput: {value: {$set: ""}}}});
            t.setState(newData);
        });

    };


    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    render = () => {
        let video = this.props.video;
        console.log(video)
        var edit = this.props.user.privileges.includes(504) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT VIDEO'}
                </Button>
            </div>

            <div style={{position: 'relative'}} id="videoDrawerShow">

                <iframe
                    width="320"
                    height="200"
                    src={'//www.youtube.com/embed/' + video.link.split("=").pop()}
                    frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />

                <EditField
                        title="Name"
                        edit={edit}
                        form={{validate: "required|max:191", default: video.name}}
                        field="input"
                        onSubmit={(value) => this.save('name', value)}
                        show={<h4>{video.name}</h4>} />

                    <EditField
                        title="Link"
                        edit={edit}
                        form={{validate: "required|max:191", default: video.link}}
                        field="input"
                        onSubmit={(value) => this.save('link', value)}
                        show={<h4>{video.link} </h4>} />


                <Form.Item label="Tags">
                    <div>
                        {this.state.tags.map((item, index) => {
                            return <Tag key={index} closable={edit} onClose={(e) => this.removeTag(e, index)}> {item.name} </Tag>
                        })}
                    </div>

                    {edit &&
                    <Input size="small"  onKeyDown={this.handleKeyDown} {...this.state.form.tagInput}
                       maxLength={191} placeholder="insert tag" type="text" autoComplete="off"
                       suffix={
                           <Tooltip title="Add tag">
                               <Icon type="plus-circle" onClick={this.addTag} style={{ color: 'rgba(0,0,0,.45)' }} />
                           </Tooltip>
                       }/>}
                </Form.Item>


            </div>
            <div className="drawer_footer">
                {edit && <><Button className="strong" onClick={this.delete}>Delete video</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'video'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoShow);
