import React from 'react';
import {connect} from "react-redux";
import { Button, Drawer, Skeleton} from "antd";
import {toggleDrawer} from "../../store/actions/drawersActions";
import api from "../../modules/api/request";
import model from "../../modules/model/bind";
import UserShow from "./UserShow"

class UserDrawer extends React.Component   {

    state = model(this, {
        loading: false,
        status: false,
        user: false
    });

    render = () => {
        return  <Drawer visible={this.state.status}
                        width="50%"
                        title="PROFILE"
                        placement="right"
                        zIndex={998}
                        closable={true}
                        onClose={() => this.props.toggleDrawer({drawer: 'user'})}>

                {!this.state.loading
                    ? <UserShow user={this.state.user} />
                    : <Skeleton active />
                }

            <div className="drawer_footer">
                <Button className="strong" onClick={() => this.props.toggleDrawer({drawer: 'user'})}>
                    Close
                </Button>
            </div>

        </Drawer>
    };

    load = () => {
        if(!this.props.drawer.id)        {
            this.stateSet({status: this.props.drawer.status, user: false, loading: false});
            return;
        }
        let t = this;
        t.stateSet({status: t.props.drawer.status, loading: true});
        api.get(api.routes.user +"/" + this.props.drawer.id).then(function (response) {
            t.stateSet({user: response.data, loading: false});
        });
    };

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.id !== prevProps.drawer.id)       {
            this.load();
        }
        else if (this.props.drawer.changed !== prevProps.drawer.changed) {
            this.load();
        }
    }
}

const mapStateToProps = state =>    ({
    user: state.user,
    drawer: state.drawers.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDrawer);
