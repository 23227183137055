import axios from 'axios';
import store from '../../store/store'

axios.interceptors.request.use(function (config) {
    if(store.getState().user)       {
        var jwt = store.getState().user.jwt;
        config.headers['Authorization'] = 'Bearer ' + jwt;
    }
    return config;
}, function (error) {
    // Do something with request error
    // return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;

}, function (error) {
    var message = '';
    if(error.response)      {
        if(error.response.status === 404)       {
            message = 'Page not found!';
        }
        else if(error.response.status === 401)       {
            // TODO logout user if unothorized
        }
        else if(error.response.status === 402)       {
            // TODO unothorized PRIVILEGE
        }
        else if(error.response.status === 422)       {
            if (typeof error.response.data === 'string' || error.response.data instanceof String)   {
                message = error.response.data;
            }
            else        {
                for (var key in error.response.data) {
                    if (error.response.data.hasOwnProperty(key)) {
                        for (var key2 in error.response.data[key]) {
                            if (error.response.data[key].hasOwnProperty(key2)) {
                                message += error.response.data[key][key2] + "\r\n";
                            }
                        }
                    }
                }
            }
        }
        else        {
            message = 'Error with server';
        }
    }
    else        {
        message = 'Error with server';
    }
    return Promise.reject(message);
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    var url = 'https://test.centralm.rs/api';
    var imgUrl = 'https://test.centralm.rs';
} else {
    var url = 'https://test.centralm.rs/api';
    var imgUrl = 'https://test.centralm.rs';
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    url: url,
    gallery: function(name, big = false) {
        if(!name)       {
            return imgUrl + '/images/000_image.png';
        }
        if(big)     {
            return imgUrl + '/images/orig/' + name;
        }
        return imgUrl + '/images/thumb/' + name;
    },
    image: function(item, big = false) {
        if(!item.name)       {
            return imgUrl + '/files/000_file.png';
        }
        if(item.type.startsWith('image'))        {
            if(big)     {
                return imgUrl + '/images/orig/' + item.name;
            }
            return imgUrl + '/images/thumb/' + item.name;
        }
        else if(item.type === 'application/pdf')        {
            return imgUrl + '/files/000_pdf.png';
        }
        else        {
            return imgUrl + '/files/000_file.png';
        }
    },
    avatar: function(name)      {
        return imgUrl + '/images/thumb/' + name;
    },
    routes: {
        login: url + '/auth/login',
        register: url + '/auth/register',
        password: url + '/auth/password',
        google: url + '/auth/google',
        facebook: url + '/auth/facebook',

        data: url + '/user/data',
        employees: url + '/employees',
        employee: url + '/employee',

        user: url + '/user',
        profileUpdate: url + '/profile/update',
        employeeActive: url + '/employee/active',
        employeeDelete: url + '/employee/delete',
        employeePrivileges: url + '/employee/privileges',

        department: url + '/department',
        departmentsTree: url + '/departments/tree',
        departmentUserAdd: url + '/department/user/add',
        departmentUserDelete: url + '/department/user/delete',
        departmentUpdate: url + '/department/update',
        departmentInsert: url + '/department/insert',
        departmentDelete: url + '/department/delete',

        reminders: url + '/reminders',
        reminderUpdate: url + '/reminder/update',
        reminderDelete: url + '/reminder/delete',

        projects: url + '/projects',
        project: url + '/project',
        projectProtected: url + '/project/protected',
        projectInsert: url + '/project/insert',
        projectDelete: url + '/project/delete',
        projectUpdate: url + '/project/update',
        projectUserRequest: url + '/project/user/request',
        projectUserJoin: url + '/project/user/join',
        projectUserDelete: url + '/project/user/delete',
        projectUserAccept: url + '/project/user/accept',
        projectUserAdd: url + '/project/user/add',


        taskInsert: url + '/task/insert',
        tasks: url + '/tasks',
        task: url + '/task',
        taskProtected: url + '/task/protected',
        taskUpdate: url + '/task/update',
        taskDelete: url + '/task/delete',
        taskUserRequest: url + '/task/user/request',
        taskUserJoin: url + '/task/user/join',
        taskUserDelete: url + '/task/user/delete',
        taskUserAccept: url + '/task/user/accept',
        taskUsersAdd: url + '/task/users/add',

        filesFolders: url + '/files/folders',
        filesFoldersAll: url + '/files/folders/all',
        filesFolder: url + '/files/folder',
        filesFolderInsert: url + '/files/folder/insert',
        filesFolderUpdate: url + '/files/folder/update',
        filesFolderUserAdd: url + '/files/folder/user/add',
        filesFolderUserDelete: url + '/files/folder/user/delete',
        filesFolderDelete: url + '/files/folder/delete',
        filesUpload: url + '/files/upload',
        filesDelete: url + '/files/delete',
        filesMove: url + '/files/move',

        comments: url + '/comments',
        wall: url + '/wall',
        commentInsert: url + '/comment/insert',
        commentDelete: url + '/comment/delete',

        checklistFieldUpdate: url + '/checklist/field/update',
        checklistFieldInsert: url + '/checklist/field/insert',
        checklistFieldDelete: url + '/checklist/field/delete',
        checklistStatusUpdate: url + '/checklist/status/update',

        notifications: url + '/notifications',
        notificationsSeen: url + '/notifications/seen',
        notificationsDelete: url + '/notifications/delete',

        cronJobs: url + '/cron/jobs',
        cronJobUpdate: url + '/cron/job/update',
        cronJobDelete: url + '/cron/job/delete',


        fairs: url + '/fairs',
        contacts: url + '/contacts',
        contactsFilters: url + '/contacts/filters',
        contact: url + '/contact',
        contactInsert: url + '/contact/insert',
        contactDelete: url + '/contact/delete',
        contactUpdate: url + '/contact/update',
        contactItemDelete: url + '/contact/item/delete',
        contactItemUpdate: url + '/contact/item/update',

        countryInsert: url + '/country/insert',
        countries: url + '/countries',
        regions: url + '/regions',
        country: url + '/country',
        countryDelete: url + '/country/delete',
        countryUpdate: url + '/country/update',

        companies: url + '/companies',
        companiesFilters: url + '/companies/filters',
        companiesParents: url + '/companies/parents',
        company: url + '/company',
        companyInsert: url + '/company/insert',
        companyDelete: url + '/company/delete',
        companyUpdate: url + '/company/update',
        companyItemDelete: url + '/company/item/delete',
        companyItemUpdate: url + '/company/item/update',

        visitInsert: url + '/visit/insert',
        visitDelete: url + '/visit/delete',
        visit: url + '/visit',
        visits: url + '/visits',
        visitUpdate: url + '/visit/update',

        reportInsert: url + '/report/insert',
        reports: url + '/reports',
        report: url + '/report',
        reportUpdate: url + '/report/update',
        reportDelete: url + '/report/delete',

        videoInsert: url + '/video/insert',
        videos: url + '/videos',
        video: url + '/video',
        videoUpdate: url + '/video/update',
        videoDelete: url + '/video/delete',
        videoTagDelete: url + '/video/tag/delete',
        videoTagInsert: url + '/video/tag/insert',

        offerInsert: url + '/offer/insert',
        offers: url + '/offers',
        offer: url + '/offer',
        offerDelete: url + '/offer/delete',
        offerUpdate: url + '/offer/update',

        offerHistory: url + '/offer/history',
        offerHistoryInsert: url + '/offer/history/insert',

        offerEquipment: url + '/offer/equipment',
        offerEquipmentInsert: url + '/offer/equipment/insert',
        offerEquipmentUpdate: url + '/offer/equipment/update',
        offerEquipmentSingleUpdate: url + '/offer/equipment/single/update',
        offerEquipmentDelete: url + '/offer/equipment/delete',

        offerProductSectionInsert: url + '/offer/product/section/insert',

        offerProductSectionUpdate: url + '/offer/product/section/update',
        offerProductSectionName: url + '/offer/product/section/name',


        equipment: url + '/equipment',
        equipmentSingle: url + '/equipment/single',
        equipmentUpdate: url + '/equipment/update',
        equipmentInsert: url + '/equipment/insert',
        equipmentDelete: url + '/equipment/delete',
        equipmentDuplicate: url + '/equipment/duplicate',
        equipmentTypes: url + '/equipment/types',
        equipmentTree: url + '/equipment/tree',
    }
}