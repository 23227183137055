import React from 'react';
import {connect} from 'react-redux';
import {Table} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar from "../../../layout/Avatar";
import moment from "moment";
const timeFormat = 'HH:mm';
const format = 'MMM Do YYYY';

class VisitsTable extends React.Component {

    columns = [
        {title: 'Photo', dataIndex: 'photo', key: 'photo', width: 60,
            render: (text, record) => <Avatar avatar={record.contact.photo_name} name={record.contact.name} size="large"  />},

        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'visit', id: record.id})}>
                    {record.contact.prefix} {record.contact.name} {record.contact.middlename} {record.contact.lastname}
                </a>
            }
        },

        {title: 'Responsible person', dataIndex: 'responsible', key: 'responsible',
            render: (text, record) => {
            if(!record.user) return false;
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: record.user.uid})}>
                    <Avatar avatar={record.user.avatar_name} name={record.user.name} size="small" /> {record.user.name}
                </a>
            }
        },

        {title: 'From', dataIndex: 'from', key: 'from',
            render: (text, record) => {
                if(!record.country) return false;
                return <div> {record.country.name} <div> ({record.country.region}) </div> </div>
            }},

        {title: 'Arrival', dataIndex: 'arrival', key: 'arrival',
            render: (text, record) => {
                var date = record.arrival_date ? moment(record.arrival_date).format(format) : '';
                var time = record.arrival_time ? moment(record.arrival_time, "HH:mm:ss").format(timeFormat) : '';
                return <div> {date} <div> {time} </div> </div>
        }},
        {title: 'Departure', dataIndex: 'departure', key: 'departure',
            render: (text, record) => {
                var date = record.departure_date ? moment(record.departure_date).format(format) : '';
                var time = record.departure_time ? moment(record.departure_time, "HH:mm:ss").format(timeFormat) : '';
                return <div> {date} <div> {time} </div> </div>
            }},
    ];

    render = () => {
        return <Table
            className="td_1_100"
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            pagination={false} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(VisitsTable);
