import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Table, Icon} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import Avatar from "../../../layout/Avatar";

class CompaniesTable extends React.Component {

    columns = [
        {title: 'Logo', dataIndex: 'logo', key: 'logo', width: 110,
            render: (text, record) => <Avatar shape="square" avatar={record.logo_name} name={record.name} size="large"  />},

        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: record.id})}>
                    {text}
                </a>
            }
        },

        {title: 'Role', dataIndex: 'role', key: 'role',
            render: (text, record) => {
                return <div> {text} <div> {record.status} </div> </div>
            }
        },

        {title: 'Address', dataIndex: 'address', key: 'address',
            render: (text, record) => {
                return record.addresses.map((address, index) => {
                    return <div key={index}> {address.address} </div>
                });
            }
        },

        {title: 'Country', dataIndex: 'country', key: 'country',
            render: (text, record) => {
            var region = (record.country_region) ? '(' + record.country_region + ')' : '';
            return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: record.country_id})}>
                {record.country_name} {region}
                </a>
            }
        },
    ];

    render = () => {
        return <Table
            dataSource={this.props.data.data}
            columns={this.columns}
            size="small"
            defaultExpandAllRows

            rowClassName={(record) => {
                if(!record.children)   return 'hide-drop';
                if(record.children.length === 0) return 'hide-drop';
            }}
            rowKey="id"
            onChange={(obj) => this.props.changePage(obj.current)}
            pagination={{pageSize: this.props.data.per_page, current: this.props.data.current_page, total: this.props.data.total}} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
