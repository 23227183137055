import React from 'react';
import {Button, Form, Input, Dropdown, Menu, Icon, InputNumber} from "antd";
import update from "react-addons-update";
import api from "../../modules/api/request";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";

class Checklist extends React.Component   {

    state = {
        checklist: this.props.default,
    };

    changeChecklist = (e, index, field) => {
        var value = e.target.value;
        const newData = update(this.state,{checklist: {[index]: {[field]: {$set: value}}}});
        this.setState(newData);
        if(this.props.setChecklist) this.props.setChecklist(this.state.checklist);
    };
    addChecklist = (e) => {
        e.preventDefault();
        const newData = update(this.state,{checklist: {$push: [{value: "", price: "", files: []}]}} );
        this.setState(newData);
    };
    addFile = (index) => {
        const newData = update(this.state,{checklist: {[index]: {files: {$push: [{id: "", name: "", description: ""}]}}}});
        this.setState(newData);
    };
    changeFile = (index, fileIndex) => {
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                const newData = update(this.state,{checklist: {[index]: {files: {[fileIndex]: { id: {$set: files[0].id}, name: {$set: files[0].name}, type: {$set: files[0].type}  }}}}});
                this.setState(newData);
                if(this.props.setChecklist) this.props.setChecklist(this.state.checklist);
        }});
    };
    changeFileDescription = (e, index, fileIndex) => {
        var value = e.target.value;
        const newData = update(this.state,{checklist: {[index]: {files: {[fileIndex]: { description: {$set: value}  }}}}});
        this.setState(newData);
        if(this.props.setChecklist) this.props.setChecklist(this.state.checklist);
    };
    deleteFile = (e, index, fileIndex) => {
        const newData = update(this.state, {checklist: {[index]: {files: {$splice: [[fileIndex, 1]] }}}});
        this.setState(newData);
        if(this.props.setChecklist) this.props.setChecklist(this.state.checklist);
    };
    deleteChecklist = (e, index) => {
        if(this.props.delete)      {
            this.props.delete(this.state.checklist[0].id);
            return;
        }
        const newData = update(this.state, {checklist: {$splice: [[index, 1]] }} );
        this.setState(newData);
        if(this.props.setChecklist) this.props.setChecklist(this.state.checklist);
    };

    render = () => {
        return <Form.Item label={!this.props.update && "Checklist"} layout="horizontal">
                {this.state.checklist.map((item, index) => {
                        return <div key={index} className="checklist_form_item">
                            <div className="row marginless">

                                {!this.props.update && <div style={{width: 20}}><strong>{index+1}. </strong></div>}

                                <div className="col">
                                    <Input size="small" placeholder="title" style={{maxWidth: 400}} value={item.value} onChange={(e) => this.changeChecklist(e, index, 'value')} /> <br />
                                    <Input type="number" placeholder="price" size="small" style={{maxWidth: 150}} value={item.price} onChange={(e) => this.changeChecklist(e, index, 'price')} /> &nbsp;

                                    <div className="mt_15">
                                        {item.files.map((file, fileIndex) => {
                                            return <div key={fileIndex}>
                                                <img src={api.image(file)} width={30} onClick={() => this.changeFile(index, fileIndex)} /> &nbsp;
                                                <Input size="small" placeholder="File description" style={{maxWidth: 400}}
                                                       value={file.description} onChange={(e) => this.changeFileDescription(e, index, fileIndex)} />
                                                &nbsp; <Button type="link" icon="delete" onClick={(e) => this.deleteFile(e, index, fileIndex)} />
                                            </div>
                                        })}
                                    </div>
                                    <Button onClick={() => this.addFile(index)}>Add file</Button> &nbsp;
                                    {this.props.update &&
                                    <Button className="success" onClick={(e) => this.props.update(this.state.checklist)}>Save</Button> }
                                </div>

                                <div style={{width: 20}}>
                                    <Button type="link" icon="delete" onClick={(e) => this.deleteChecklist(e, index)} />
                                </div>

                            </div>
                        </div>
                    })}

            {!this.props.update && <Button className="strong" onClick={this.addChecklist}>
                Add field
            </Button>}
        </Form.Item>
    };

    componentDidMount() {

    }
}
const mapStateToProps = state =>    ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
