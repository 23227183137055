import React from 'react';
import {connect} from 'react-redux';
import {Alert, Button, Form, Input, message, Modal, Table} from "antd";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import model from "../../modules/model/bind";
import api from "../../modules/api/request";


class EquipmentTable extends React.Component {

    state = model(this, {
        status: false,
        loading: false,
        form: {
            id: {},
            name: {validate: 'required'},
            code: {validate: 'required|max:191'},
        }
    });

    onSubmit = (form, model) => {
        let t = this;
        t.stateSet({loading: true});
        api.post(api.routes.equipmentDuplicate, form).then(function (response) {
            t.formReset({loading: false, status: false});
            message.success(`Equipment created.`);
            t.props.drawerChanged('equipment');
        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    }

    columns = [
        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'equipment', id: record.id})}>
                    {text}
                </a>
            }
        },
        {title: 'Code', dataIndex: 'code', key: 'code',
            render: (text, record) => {
                return <div>
                    {text}
                </div>
            }
        },
        {title: 'Type', dataIndex: 'type_name', key: 'type_name',
            render: (text, record) => {
                return <div>
                    {text}
                </div>
            }
        },
        {title: 'Duplicate', dataIndex: 'duplicate', key: 'duplicate', width: 150,
            render: (text, record) => {
                return <div>
                    <Button onClick={() => this.stateSet({status: true, 'form.id.value': record.id})}>
                        Duplicate
                    </Button>
                </div>
            }
        }
    ];

    render = () => {
        var form = this.state.form;
        return <>
            <Modal
                title="New equipment"
                zIndex={1102}
                visible={this.state.status}
                onCancel={() => this.stateSet({status: false})}>

                <Form style={{position: "relative"}} id="equipmentDuplicateDrawerForm" onSubmit={form.onSubmit}>
                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input placeholder="name" required={true} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Code" validateStatus={form.code.error && 'error'} help={form.code.error}>
                        <Input placeholder="Code" maxLength={191} type="text" {...form.code} autoComplete="off" />
                    </Form.Item>

                    <Alert className={form.error ? 'show' : 'hide'} message={form.error} type="error" closable/>

                    <Button className="strong" htmlType="submit" loading={this.state.loading}>Save equipment</Button>
                </Form>
            </Modal>

            <Table
                className=""
                dataSource={this.props.data}
                columns={this.columns}
                size="small"
                rowKey="id"
                pagination={false} />
            </>
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTable);
