import React from 'react';
import PasswordView from '../views/passwordView';
import model from '../../model/bind';
import api from '../../api/request';
import update from "react-addons-update";

class PasswordController extends React.Component   {

    state = model(this, {
        form: {
            email: {validate: 'required|email|max:191'}
        }
    });

    onSubmit = (form, model) => {
        let t = this;
        api.post(api.routes.password, form).then(function (response) {
            model.loader(false);
            model.success(response.data);
            const newData = update(t.state, {form: {email: {value: {$set: ''}} }});
            t.setState(newData);

        }).catch(function (error) {
            model.error(error);
            model.loader(false);
        });
    };

    render = () => {
        return <PasswordView {...this.state.form} />
    }
}

export default PasswordController;
