import React from 'react';
import { Avatar } from "antd";
import api from "../modules/api/request";


export default function({avatar, name, shape, size="small"}) {
    return (
        avatar
            ? <Avatar size={size} src={api.avatar(avatar)} className="avatar transparent" shape={shape ? shape : "circle"} />
            :  <Avatar size={size} className="avatar" shape={shape ? shape : "circle"}>
                <strong>{name[0].toUpperCase()}</strong>
        </Avatar>
    );
}


