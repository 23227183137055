import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Icon, Button, Alert} from 'antd';
import { Link } from 'react-router-dom';
import AuthView from "./authView";
import Google from "../controllers/GoogleController";
import Facebook from "../controllers/FacebookController";
import routes from "../routes";


function registerView({onSubmit, email, name, password, loading, error, success})    {
    return (
        <AuthView>
            <Form>
                <Form.Item validateStatus={email.error ? 'error' : 'success'} help={email.error}>
                    <Input maxLength={191} prefix={<Icon type="mail" />} type="email" {...email} placeholder="Email" />
                </Form.Item>

                <Form.Item validateStatus={name.error ? 'error' : 'success'} help={name.error}>
                    <Input prefix={<Icon type="user" />} {...name} placeholder="Name" />
                </Form.Item>

                <Form.Item validateStatus={password.error ? 'error' : 'success'} help={password.error}>
                    <Input.Password prefix={<Icon type="lock" />} {...password} placeholder="Password" />
                </Form.Item>

                <div className="mb_15">
                    <Button type="primary" htmlType="submit" loading={loading} onClick={onSubmit} className="mb_15" block> Register </Button>
                    <Alert className={error ? 'show' : 'hide'} message={error} type="error" closable/>
                    <Alert className={success ? 'show' : 'hide'} message={success} type="success" closable/>
                </div>
                <Google register={true} />
                <Facebook register={true} />
                <Link to={routes.login}>Already have account?</Link>
            </Form>
        </AuthView>
    )
}
registerView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    email: PropTypes.object.isRequired,
    name: PropTypes.object.isRequired,
    password: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired
};

export default registerView;
