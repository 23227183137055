import React from 'react';
import {Button} from "antd";
import update from "react-addons-update";
import Tools from "./Tools";

class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            style: {height: 42},
            placeholder: "add comment..."
        };
        this.editable = React.createRef();
    }

    cancel = () =>  {
        const newData = update(this.state,{style: {height: {$set: 44}}, active: {$set: false}, placeholder: {$set: "add comment..."}});
        this.setState(newData);
    };

    save = () =>  {
        var html = this.editable.current.innerHTML;
        this.props.onSave(html, this.props.parent);
        this.cancel();
    };

    activate = () => {
        if(this.state.active) return false;
        const newData = update(this.state,{style: {height: {$set: "auto"}}, active: {$set: true}, placeholder: {$set: ""}});
        this.setState(newData);
    };

    render = () => {
        return <div>
            <div className="editor_editable" style={this.state.style} onClick={this.activate}>
                <div contentEditable={this.state.active} className="mb_15"
                     ref={this.editable}
                     dangerouslySetInnerHTML={{__html: this.state.placeholder}} />

                <Tools />
            </div>

            <div className={this.state.active ? 'mt_15 show' : 'mt_15 hide'}>
                <Button type="primary" onClick={this.save}>Save</Button> &nbsp;
                <Button onClick={this.cancel}>Cancel</Button>
            </div>
        </div>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.active)       {
            this.editable.current.focus();
        }
    }
}

export default Editor;
