import React from 'react';
import model from "../../modules/model/bind";
import {Alert, Button, DatePicker, Form, Input, message, Select, Radio} from "antd";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import api from "../../modules/api/request";
import {toggleDrawer, drawerChanged} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import Checklist from "./Checklist";
import FormEditor from "../../modules/editor/FormEditor";
const { Option } = Select;
const format = 'MMM Do YYYY';

class TaskEdit extends React.Component   {

    state = model(this, {
        loading: false,
        projects: [],
        tasks: [],
        start: 'date',
        form: {
            name: {validate: 'required|max:191'},
            description: {validate: 'max:2000'},
            start: {type: 'select', default: null},
            end: {type: 'select', default: null},
            depend: {type: 'select'},
            owner: {type: 'select', default: [this.props.user.uid]},
            responsible: {type: 'select', default: []},
            participants: {type: 'select', default: []},
            observers: {type: 'select', default: []},
            project: {type: 'select'},
            checklist: {default: []},
            temp: {}
        }
    });

    onSubmit = (form, model) => {
        if(form.end)        {
            form.end = form.end.format('YYYY-MM-DD');
        }
        if(form.start)        {
            form.start = form.start.format('YYYY-MM-DD');
        }
        let t = this;
        t.stateSet({loading: true});
        api.post(api.routes.taskInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Task created.`);
            t.props.drawerChanged('task');
            t.props.toggleDrawer({drawer: 'task'});
        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    setChecklist = (checklist) => {
        this.stateSet({'form.checklist.value': checklist});
    };

    setDescription = (value) => {
        this.stateSet({'form.description.value': value});
    };

    setDepend = (e) => {
        var val = e.target.value;
        if(val === 'date')      {
            this.stateSet({start: val, 'form.depend.value': ''});
        }
        else    {
            this.stateSet({start: val, 'form.start.value': null});
        }
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 4 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                style={{position: "relative"}} id="tasksDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Description" validateStatus={form.description.error && 'error'} help={form.description.error}>
                        <FormEditor getValue={this.setDescription} />
                    </Form.Item>
                </div>


                <div className="light_bcg p_15 mb_15">
                    <Checklist setChecklist={this.setChecklist} default={form.checklist.value} />
                </div>

                <div className="p_15">
                    <Form.Item label="Start Date" layout="horizontal">
                        <Radio.Group onChange={this.setDepend} value={this.state.start}>
                            <div>
                                <Radio value="date" />
                                <DatePicker disabled={this.state.start !== 'date'} {...form.start} format={format} />
                            </div>
                            <div>
                                <label>
                                    <Radio value="depend" />
                                    or depend on task: &nbsp;
                                </label>
                                <Select style={{ width: 220 }} disabled={this.state.start !== 'depend'} {...form.depend} getPopupContainer={trigger => document.getElementById("tasksDrawerForm")}>
                                    {this.state.tasks.map((item) => {
                                        return <Option key={item.id} value={item.id}> {item.name} </Option>
                                    })}
                                </Select>
                            </div>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="End Date" layout="horizontal">
                        <DatePicker {...form.end} format={format} />
                    </Form.Item>

                    <Form.Item label="Created By" layout="horizontal">
                        <PersonTreeSelect field={form.owner} parent="tasksDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Responsible Person" layout="horizontal">
                        <PersonTreeSelect field={form.responsible} parent="tasksDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Participants" layout="horizontal">
                        <PersonTreeSelect field={form.participants} parent="tasksDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Observers">
                        <PersonTreeSelect field={form.observers} parent="tasksDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Add to Project">
                        <Select style={{ maxWidth: 320 }} {...form.project} getPopupContainer={trigger => document.getElementById("tasksDrawerForm")}>
                            {this.state.projects.map((item) => {
                                return <Option key={item.name} value={item.name}> {item.name} </Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Alert className={form.error ? 'show' : 'hide'} message={form.error} type="error" closable/>
                </div>
            </Form>
            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="tasksDrawerForm" loading={this.state.loading}> Save task </Button> &nbsp;
                <Button className="strong" onClick={()=>this.stateSet({'form.temp.value': 1})} htmlType="submit" form="tasksDrawerForm" loading={this.state.loading}>
                    Save task and template
                </Button> &nbsp;
                <Button className="strong" onClick={() => this.props.toggleDrawer({drawer: 'task'})}> Close </Button>
            </div>
        </div>
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.projects).then(function (response) {
            t.stateSet({projects: response.data});
        });
        api.get(api.routes.tasks).then(function (response) {
            t.stateSet({tasks: response.data});
        });
        if(this.props.template)     {
            console.log(this.props.template)
        }
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.task
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskEdit);
