import { combineReducers, createStore } from 'redux';

import user from './reducers/userReducer';
import drawers from './reducers/drawersReducer';
import departments from './reducers/departmentsReducer';


const store = createStore(combineReducers({
    user: user,
    drawers: drawers,
    departments: departments
}));

export default store;