import React from 'react';
import {connect} from 'react-redux';
import {Table, Tag, Icon, Tooltip, Button, message} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";

class VideosTable extends React.Component {

    saveSelection = (e) => {
        var prev = e.target.previousSibling;
        if (document.selection) { // IE
            var range = document.body.createTextRange();
            range.moveToElementText(prev);
            range.select();
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(prev);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
        document.execCommand("copy");
        message.success("Link copied to clipboard");
    };

    columns = [
        {title: 'Thumb', dataIndex: 'thumb', key: 'thumb',  width: 160,
            render: (text, record) => {
                var arr = record.link.split("=");
                var link = '//www.youtube.com/embed/' + arr.pop();
                return <iframe width="260" height="150" src={link} frameBorder="0"
                               allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                               allowFullScreen showinfo="0" controls="0" autohide="1" />
            }
        },
        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <div>
                    <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'video', id: record.id})}>
                        <h4>{text}</h4>
                    </a>
                    <div>
                        <span style={{marginRight: 10}}>{record.link}</span>
                        <Button size="small" onClick={this.saveSelection} style={{height: 'auto'}}>
                            <Icon type="copy" />
                        </Button>
                    </div>
                </div>
            }
        },

        {title: 'Tags', dataIndex: 'tags', key: 'tags', width: "40%",
            render: (text, record) => {
                return record.tags.map((tag, index) => {
                    return <Tag key={index}> {tag.name} </Tag>
                })
        }},

    ];

    render = () => {
        return <Table
            className=""
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            pagination={1} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosTable);
