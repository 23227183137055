import React from 'react';
import {connect} from "react-redux";
import {TreeSelect} from "antd";
import Avatar from "../../layout/Avatar";
import PropTypes from "prop-types";
const TreeNode = TreeSelect.TreeNode;

class PersonTreeSelect extends React.Component   {

    dropdownUser = (user) => {
        return <div><Avatar avatar={user.avatar_name} name={user.name} /> {user.name}</div>
    };

    render = () => {
        var filtered = this.props.departments.filter((item) => {
            return item.employees.length > 0;
        });
       return <TreeSelect getPopupContainer={trigger => document.getElementById(this.props.parent)}
                    {...this.props.field}
                    showSearch
                    size="large"
                    dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                    placeholder="Select person"
                    allowClear
                    treeCheckable={true}
                      filterTreeNode={(input, option) =>
                          option.props.title.props &&
                          option.props.title.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    multiple>
                {filtered.map((item) => {
                    return <TreeNode value={item.name} title={item.name} key={item.name}>
                        {item.employees.map((employee) => {
                            return <TreeNode value={employee.uid} title={this.dropdownUser(employee)} key={employee.uid} />
                        })}
                    </TreeNode>
            }   )}
        </TreeSelect>
    };

}
PersonTreeSelect.propTypes = {
    field: PropTypes.object.isRequired,
    parent: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    departments: state.departments
});
export default connect(mapStateToProps)(PersonTreeSelect);
