import React from 'react';
import {Button, message, Modal} from "antd";
import api from "../../modules/api/request";
import Avatar from "../../layout/Avatar";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import EditField from "./EditField";
const { confirm } = Modal;

class Users extends React.Component   {

    render = () => {
        return <div>
            {this.props.users.map((user, index) => {
            return <div key={index} className="mb_15">
                <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: user.uid})}>
                    <Avatar avatar={user.avatar_name} name={user.name} size="small" /> {user.name}
                </a>
                {this.props.edit && <Button
                    type="link" className="pull-right" icon="close" size="small"
                    onClick={(e) => this.props.deleteUser(user.uid)} />}
            </div>
        })}

            <EditField
                edit={this.props.edit}
                field="persons"
                form={{type: 'select', default: []}}
                onSubmit={(value) => this.props.addUsers(value)}
                parent={this.props.parent} />
        </div>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    drawerChanged: drawerChanged,
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
