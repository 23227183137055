import React from 'react';
import {Button, message, Modal, Select} from "antd";
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import EditField from "../partials/EditField";
import moment from "moment";
import Avatar from "../../layout/Avatar";
import pdf from "../../assets/images/pdf.png"
import ItemsEdit from "../partials/ItemsEdit";
const { confirm } = Modal;
const sqlFrmat = 'YYYY-MM-DD';
const format = 'MMM Do YYYY';

class ReportShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit
    });

    save = (field, value) => {
        let t = this;
        api.post(api.routes.reportUpdate, {id: this.props.report.id, field: field, value: value}).then(function (response) {
            message.success(`Report updated.`);
            t.props.drawerChanged('report');
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this report?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.reportDelete, {data: {id: t.props.report.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Report deleted.`);
                        t.props.toggleDrawer({drawer: 'report'});
                        t.props.drawerChanged('report');
                    });

                });
            },
            onCancel() {},
        });
    };

    saveItem = (form, table) => {
        var id = form.id;
        delete form.id;
        let t = this;
        api.post(api.routes.contactItemUpdate, {id: id, form: form, table: table, contact: this.props.contact.id}).then(function (response) {
            message.success(`Item updated.`);
            t.props.drawerChanged('contact');
        });
    };

    deleteItem = (id, table) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this item?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.contactItemDelete, {data: {id: id, table: table}}).then(function (response) {
                        setTimeout(resolve, 10);
                        t.props.drawerChanged('contact');
                        message.success(`Item deleted.`);
                    });
                });
            },
            onCancel() {},
        });
    };


    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    pdf = () => {
        let report = this.props.report;
        var participants = "";
        report.participants.map((item) => {
            participants += item.name + "\n"
        });
        var structure = [
            {element: 'table', weight: 0.3, data: [
                    ["TRIP NAME / ID", report.name],
                    ["PARTICIPANT(S)", participants],
                    ["START DATE", report.start ? moment(report.start).format(format) : ''],
                    ["END DATE", report.end ? moment(report.end).format(format) : '']
                ]}
        ];
        var companies = [];
        report.companies.map((item) => {
            var a = item.name ? item.name : '';
            var d = item.pivot.description ? item.pivot.description : '';
            companies.push(['COMPANY', a + "\n" +d])
        });
        if(companies.length > 0)     {
            structure.push({element: 'table', header: 'COMPANIES', weight: 0.3, data: companies});
        }
        structure.push({element: 'table', header: 'BUSINESS TRIP OVERVIEW', weight: 0.3, data: [
                ["DEPARTING FROM", report.departing ? report.departing : ''],
                ["DESTINATION", report.destination ? report.destination : ''],
                ["\n\n\n\n\nSUMMARY OF \n COMPLETED TRIP\n\n\n\n\n", report.summary ? report.summary : ''],
                ["\n\n\n\n\nADDITIONAL COMMENTS\n\n\n\n\n", report.comments ? report.comments : '']
            ]});
        this.props.toggleDrawer({drawer: 'pdf', title: 'TRIP REPORT', structure: structure})
    };

    render = () => {
        let report = this.props.report;
        var edit = this.props.user.privileges.includes(307) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={this.pdf}>
                   <img src={pdf} width={15} style={{marginTop: -4}} /> &nbsp; PDF
                </Button>
                &nbsp;
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT REPORT'}
                </Button>
            </div>

            <div style={{position: 'relative'}} id="reportDrawerShow">
                <div className="marginless row">

                    <div className="col">
                        <div className="light_bcg p_15 mb_15">
                            <EditField
                                title="Name"
                                edit={edit}
                                form={{validate: "required|max:191", default: report.name}}
                                field="input"
                                onSubmit={(value) => this.save('name', value)}
                                show={<h4>{report.name}</h4>} />

                            <div className="mb_15">
                                <EditField
                                    title="Participants"
                                    edit={edit}
                                    form={{type: 'select', default: report.participants.map((item) => {return item.uid})}}
                                    field="persons"
                                    onSubmit={(value) => this.save('participants', value)}
                                    parent="reportDrawerShow"
                                    show={report.participants.length > 0
                                        ? report.participants.map((item, index) => {
                                            return <h3 key={index}>
                                                <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: item.uid})}>
                                                <Avatar name={item.name} avatar={item.avatar_name} />{item.name}
                                                </a>
                                            </h3>}
                                            )
                                        : <h3><i>not set</i></h3>}
                                />
                            </div>

                            <EditField
                                title="Start date"
                                edit={edit}
                                form={{type: 'select', default: report.start ? moment(report.start) : null}}
                                field="date"
                                onSubmit={(value) => this.save('start', value ? value.format(sqlFrmat) : value)}
                                show={<h4>{report.start ? moment(report.start).format(format) : 'not set'}</h4>} />

                            <EditField
                                title="End date"
                                edit={edit}
                                form={{type: 'select', default: report.end ? moment(report.end) : null}}
                                field="date"
                                onSubmit={(value) => this.save('end', value ? value.format(sqlFrmat) : value)}
                                show={<h4>{report.end ? moment(report.end).format(format) : 'not set'}</h4>} />
                        </div>

                        <h3> BUSINESS TRIP OVERVIEW </h3>

                        <EditField
                            title="Departing from"
                            edit={edit}
                            form={{validate: "required|max:191", default: report.departing}}
                            field="input"
                            onSubmit={(value) => this.save('departing', value)}
                            show={<h4>{report.departing}</h4>} />


                        <EditField
                            title="Destination"
                            edit={edit}
                            form={{validate: "required|max:191", default: report.destination}}
                            field="input"
                            onSubmit={(value) => this.save('destination', value)}
                            show={<h4>{report.destination}</h4>} />




                        <div>
                            <div className="mb_5"> Companies </div>
                        {report.companies.map((item, index) => {
                            return  <div key={index} className="mb_5">
                                <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: item.id})}>
                                    {item.logo_name && <Avatar name={item.name} avatar={item.logo_name} />}
                                    <strong>{item.name}</strong>
                                </a>
                                <div className="pre_wrap">{item.pivot.description}</div>
                            </div>
                        })}
                        </div>


                        <div className="mb_15">
                            <EditField
                                title="Summary of completed trip"
                                edit={edit}
                                form={{default: report.summary}}
                                field="editor"
                                onSubmit={(value) => this.save('summary', value)}
                                show={<div className="light_bcg p_15" dangerouslySetInnerHTML={{__html: report.summary}}/>} />
                        </div>

                        <EditField
                            title="Additional comments"
                            edit={edit}
                            form={{default: report.comments}}
                            field="editor"
                            onSubmit={(value) => this.save('comments', value)}
                            show={<div className="light_bcg p_15" dangerouslySetInnerHTML={{__html: report.comments}}/>} />
                    </div>
                </div>
            </div>
            <div className="drawer_footer">
                {edit && <><Button className="strong" onClick={this.delete}>Delete report</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'country'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportShow);
