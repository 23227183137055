import React from "react";
import AppRouter from "./appRouter";
import {Spin} from "antd";
import api from "./modules/api/request";
import {setUser} from "./store/actions/userActions";
import {setDepartments} from "./store/actions/departmentsActions";
import {connect} from "react-redux";
import update from "react-addons-update";

class App extends React.Component   {

    state = {
        loading: true
    };

    render = () => {
        return <div>
            {this.props.user
                ? this.state.loading
                    ? <div className="app_loader">
                        <Spin tip="Loading Data..."/>
                    </div>
                    : <AppRouter/>
                : <AppRouter/>
            }
        </div>
    };

    componentDidMount() {
        console.log(this.props.user)
        if(!this.props.user) return false;
        let t = this;
        api.get(api.routes.data).then(function (response) {
            var user = response.data.user;
            user.privileges = response.data.privileges;
            user.jwt = t.props.user.jwt;
            t.props.setUser(user);
            const newData = update(t.state,{loading: {$set: false} });
            t.setState(newData);
        });
        api.get(api.routes.departmentsTree).then(function (response) {
            t.props.setDepartments(response.data);
        });
    }
}
const mapStateToProps = state =>    ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser,
    setDepartments: setDepartments,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);