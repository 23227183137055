import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {Button, Checkbox, message, Modal, Skeleton, Tabs} from "antd";
import {toggleDrawer, drawerChanged} from "../../store/actions/drawersActions";
import api from "../../modules/api/request";
import Checklist from "./Checklist";
import update from "react-addons-update";
import Comments2 from "../partials/Comments2";
const { TabPane } = Tabs;
const { confirm } = Modal;

class TaskProtected extends React.Component   {

    state = model(this, {
        checklist: [],
        comments: false
    });

    checklistStatus = (e, id) => {
        var val = e.target.checked ? 1 : null;
        let t = this;
        api.post(api.routes.checklistStatusUpdate, {id: id, value: val}).then(function (response) {
            message.success(`Checklist updated.`);
        });
    };

    addChecklist = () => {
        const newData = update(this.state,{checklist: {$push: [{value: "", edit: true,  price: "", files: []}]}} );
        this.setState(newData);
    };

    deleteChecklist = (id) => {
        if(!id)     {
            this.stateSet({checklist: this.state.checklist.filter((item) => {
                return !!item.id;
            })});
            return;
        }
        let t = this;
        confirm({
            title: 'Do you want to delete this checklist item?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.checklistFieldDelete, {data: {id: id}}).then(function (response) {
                        setTimeout(resolve, 10);
                        t.props.drawerChanged('task');
                        message.success(`Checklist item deleted.`);
                    });
                });
            },
            onCancel() {},
        });
    };

    updateChecklist = (item) => {
        if(!item[0].value)     {
            message.error(`Insert checklist title.`);
            return false;
        }
        let t = this;
        var route = api.routes.checklistFieldUpdate;
            if(!item[0].id)        {
                route = api.routes.checklistFieldInsert;
            }
        api.post(route, {field: item[0], task: this.props.id}).then(function (response) {
            t.props.drawerChanged('task');
            message.success(`Checklist updated.`);
        });
    };

    checklistToggleEdit = (e, index) => {
        e.preventDefault();
        const newData = update(this.state, {checklist: {[index]: {edit: {$set: !this.state.checklist[index].edit }}}});
        this.setState(newData);
    };

    render = () => {
        var total = 0;
        return <Tabs defaultActiveKey="1">
            <TabPane tab="Checklist" key="1">
                {this.state.checklist.length > 0
                    ?

                    <div className="p_15 mb_15">
                        {this.state.checklist.map((item, index) => {
                            if(item.price) total += parseInt(item.price);
                            return <div key={index}>
                                {((this.props.role === 1 || this.props.role === 4) && item.id) && <div className="text-right">
                                    <a href="/" onClick={(e) => this.checklistToggleEdit(e, index)}>
                                        {item.edit ? 'close' : 'edit'}
                                    </a>
                                </div>}
                                {!item.edit
                                ? <div className="checklist_item">
                                    <div className="mb_15">
                                        <label>{index+1}. &nbsp;
                                            <Checkbox defaultChecked={item.checked} disabled={this.props.role === 3} onChange={(e) => this.checklistStatus(e, item.id)} />
                                            &nbsp; {item.value}

                                        </label>
                                    </div>
                                        {item.price && <div className="mb_15">Price: <strong> {item.price} </strong></div>}
                                    {item.files.length > 0 &&
                                    item.files.map((file) => {
                                        file.description = file.pivot.description;
                                        return <div key={file.id} className="mb_5">

                                            {file.type.startsWith('image')
                                                ? <a href={api.image(file, true)}  className="popup" target="_blank">
                                                    <img src={api.image(file)} width="40" />
                                                </a>
                                                : <a href={api.url + '/files/' + file.name} target="_blank">
                                                    <img src={api.image(file)} width="40" />
                                                </a>
                                            }
                                            &nbsp; <i>{file.pivot.description}</i>
                                        </div>
                                    })}
                                </div>
                                : <Checklist default={[item]} delete={this.deleteChecklist} update={this.updateChecklist}  />}
                            </div>
                        })}
                        <div className="row">
                            <div className="col">
                                {(this.props.role === 1 || this.props.role === 4) &&
                                <Button onClick={this.addChecklist}>Add field</Button>}
                            </div>
                            <div className="col">
                                <h3 className="text-right">TOTAL PRICE: {total}</h3>
                            </div>
                        </div>
                </div>
                    : <div>
                        <div className="text-center"> Checklist not set </div>
                        {(this.props.role === 1 || this.props.role === 4) &&
                        <Button onClick={this.addChecklist}>Add field</Button>}
                    </div>}
            </TabPane>
            <TabPane tab="Comments" key="2">
                {this.state.comments === false
                    ? <Skeleton active={true}/>
                    : <Comments2
                        privilege={this.props.role !== 3}
                        subject="task"
                        comments={this.state.comments}
                        id={this.props.id}
                        sub={true}/>
                }
            </TabPane>
        </Tabs>
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.taskProtected + '/' + this.props.id).then(function (response) {
            t.stateSet({checklist: response.data.checklist, comments: response.data.comments});
        })
    }

}

const mapStateToProps = state =>    ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskProtected);
