import React from 'react';
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Alert, Avatar, Button, DatePicker, Form, Input, message, Select} from "antd";
import api from "../../modules/api/request";
import image from "../../assets/images/image.png";
import Items from "../partials/Items";
import FormEditor from "../../modules/editor/FormEditor";
import CountriesSelect from "../partials/CountriesSelect";
const {Option} = Select;
const format = 'MMM Do YYYY';

class CompanyCreate extends React.Component   {

    state = model(this, {
        loading: false,
        logoName: '',
        companies: [],
        form: {
            logo: {},
            name: {validate: 'required|max:191'},
            type: {type: 'select', default: 'Independent'},
            parent: {type: 'select', default: 'undefined'},
            role: {type: 'select', default: 'undefined'},
            status: {type: 'select', default: 'Regular'},
            country_id: {type: 'select', default: 'undefined'},
            addresses: {default: []},
            emails: {default: []},
            phones: {default: []},
            networks: {default: []},
            notes: {},
        }
    });

    changeLogo = (e) => {
        e.preventDefault();
        let t  = this;
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
                let t = this;
                var file = files[0];
                t.stateSet({logoName: file.name, 'form.logo.value': file.id});
            }});
    };

    onSubmit = (form, model) => {
        this.stateSet({loading: true});
        let t = this;
        api.post(api.routes.companyInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Saved.`);
            t.props.toggleDrawer({drawer: 'company'});
            t.props.drawerChanged('company');

        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 6 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="companyDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Logo">
                        <a href="/" onClick={this.changeLogo}>
                            <Avatar size={90} shape="square" src={this.state.logoName ? api.avatar(this.state.logoName) : image} className="avatar" />
                        </a>
                        <i>Click image to change</i>
                    </Form.Item>

                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Parent company">
                        <Select {...form.parent} allowClear={true} showSearch={true} placeholder="Select company"
                                filterOption={(input, option) =>
                                    option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={trigger => document.getElementById('companyDrawerForm')}>
                            {this.state.companies.map((item, index) => {
                                var logo = item.logo_name ? <Avatar size="small" src={api.avatar(item.logo_name)} /> : '';
                                var region = item.region ? '('+ item.region +')' : '';
                                return <Option key={index} value={item.id}>
                                    {logo} {item.name} {region}
                                </Option>
                            })}
                        </Select>
                    </Form.Item>


                    <Form.Item label="Role">
                        <Select {...form.role} allowClear={true} placeholder="Select role" style={{width: 160}} getPopupContainer={trigger => document.getElementById('companyDrawerForm')}>
                            {this.props.roles.map((item, index) => {
                                return <Option key={index} value={item}> {item} </Option>
                            })}
                        </Select>
                    </Form.Item>


                    <Form.Item label="Status">
                        <Select placeholder="Select status" {...form.status} style={{width: 160}} getPopupContainer={trigger => document.getElementById('companyDrawerForm')}>
                            <Option value="Regular"> Regular </Option>
                            <Option value="VIP"> VIP </Option>
                        </Select>
                    </Form.Item>
                </div>


                <Form.Item label="Country">
                    <CountriesSelect field={form.country_id} parent="companyDrawerForm" />
                </Form.Item>

                <Items title="Address"
                       elements={[{name: 'address'}, {name: 'position', type: 'gmap'}, {name: 'description', type: 'textarea'}]}
                    return={(items) => this.stateSet({'form.addresses.value': items})} />

                <Items title="E-mails"  elements={[{name: 'email'}, {name: 'description', type: 'textarea'}]}
                       return={(items) => this.stateSet({'form.emails.value': items})} />

                <Items title="Phones"  elements={[{name: 'phone'}, {name: 'description', type: 'textarea'}]}
                       return={(items) => this.stateSet({'form.phones.value': items})} />

                <Items title="Social networks"  elements={[{name: 'name'}, {name: 'link'}]}
                       return={(items) => this.stateSet({'form.networks.value': items})} />


                <Form.Item label="General notes" validateStatus={form.notes.error && 'error'} help={form.notes.error}>
                    <FormEditor getValue={(value) => this.stateSet({['form.notes.value']: value})} />
                </Form.Item>

                <Alert className={form.error ? 'show mt_15' : 'hide mt_15'} message={form.error} type="error" closable />
            </Form>

            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="companyDrawerForm" loading={this.state.loading}> Save company </Button> &nbsp;
                <Button className="strong" onClick={()=>this.props.toggleDrawer({drawer: 'company'})}>Close</Button>
            </div>
        </div>
    };

    componentDidMount() {
        let t = this;
        api.get(api.routes.companiesParents).then(function (response) {
            t.stateSet({companies: response.data});
        });
    }
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);
