import update from "react-addons-update";

function bind(context, state)     {

    /*
        API passed in onSubmit to component onSubmit
     */
    var api = {
        loader: (status) => loader(status),
        error: (message) => error(message),
        success: (message) => success(message)
    };
    const loader = (status) => {
        const newData = update(context.state, {form: {loading: {$set: status} }});
        context.setState(newData);
    };
    const error = (message) => {
        const newData = update(context.state, {form: {error: {$set: message} }});
        context.setState(newData);
    };
    const success = (message) => {
        const newData = update(context.state, {form: {success: {$set: message}, error: {$set: ''} }});
        context.setState(newData);
    };
    context.formReset = (extra) => {
        var res = {success: {$set: ''}, error: {$set: ''} , loading: {$set: false}};
        for (var property in context.state.form) {
            if (context.state.form.hasOwnProperty(property)) {
               if(['success', 'error', 'loading', 'onSubmit'].includes(property))   continue;
                res[property] = {};
                var def = context.state.form[property].default;
                if(def === null)        {}
                else if(def === 'undefined') def = undefined;
                else if(!def)  def = '';
                if(extra && extra.form && extra.form[property])         {
                    def = extra.form[property];
                }
                res[property].error = {$set: ''};
                res[property].value = {$set: def};
            }
        }
        var newData;
        if(extra)       {
            var add = compileQuery(extra);
            add.form = res;
            newData = update(context.state, add);
        }
        else        {
            newData = update(context.state, {form: res });
        }
        context.setState(newData);
    };


    const onChange = (e) =>      {
        var value = e.target.value;
        var name = e.target.name;
        if(e.target.type === 'checkbox' && !e.target.checked)        {
            value = '';
        }
        var notValid = fieldNotValid(name, value);
        if(notValid)         {
            const newData = update(context.state, {form: {[name]: {value: {$set: value}, error: {$set: notValid}} }});
            context.setState(newData);
        }
        else {
            const newData = update(context.state, {form: {[name]: {value: {$set: value}, error: {$set: ''}}}});
            context.setState(newData);
        }
    };

    /*
        ANTD onChange for select and treeselect
     */
    const onChangeSelect = (value, name) =>      {
        //if(context.state.form[name].single) value = [value.pop()];
        const newData = update(context.state, {form: {[name]: {value: {$set: value} }}});
        context.setState(newData);
    };

    function fieldNotValid(property, value) {
        if(!context.state.form[property].validate)   {
            return false;
        }
        for(var i=0; i<context.state.form[property].validate.length; i++)     {
            var ruleArr = context.state.form[property].validate[i].split(':');
            if(ruleArr[0] === 'required')       {
                if(value === '') {
                    return 'Cant be empty.';
                }
            }
            else if(ruleArr[0] === 'min')       {
                if(value.length < ruleArr[1])       {
                    return `Must be at least ${ruleArr[1]} characters long.`;
                }
            }
            else if(ruleArr[0] === 'max')       {
                if(value.length > ruleArr[1])       {
                    return `Cant be more than ${ruleArr[1]} characters long.`;
                }
            }
            else if(ruleArr[0] === 'confirm')       {
                if(context.state.form[ruleArr[1]].value !== value)       {
                    return ruleArr[1] + ` does not match.`;
                }
            }
            else if(ruleArr[0] === 'email')      {
                // eslint-disable-next-line no-useless-escape
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(value))  {
                    return 'Must be valid email.';
                }
            }
        }
        return false;
    }

    /*
        Check form vaildation and pass to onSubmit of component
     */
    const onSubmit = (e) =>     {
        e.preventDefault();
        var passed = true;
        var errors = {};
        var form = {};
        for (var property in context.state.form)    {
            if (context.state.form.hasOwnProperty(property)) {
                if(context.state.form[property].value || context.state.form[property].value === '')        {
                    form[property] = context.state.form[property].value;
                }
                if(context.state.form[property].error && context.state.form[property].error !== '')   {
                    passed = false;
                }
                if(context.state.form[property].value === '')        {
                    if(context.state.form[property].validate && context.state.form[property].validate.indexOf('required') !== -1)        {
                        var notValid = fieldNotValid(property, '');
                        errors[property] = {error: {$set: notValid}};
                        passed = false;
                    }
                }
            }
        }
        if(Object.keys(errors).length > 0)       {
            const newData = update(context.state, {form: errors});
            context.setState(newData);
        }
        if(passed)   {
            if(context.onSubmit)        {
                loader(true);
                context.onSubmit(form, api);
            }
        }
    };

    /*
        Extend setter for this.state
     */
    context.stateSet = (object) => {
        var sett = compileQuery(object);
        const newData = update(context.state, sett);
        context.setState(newData);
    };

    const compileQuery = (object) => {
        var sett = {};
        for (var property in object)      {
            if (object.hasOwnProperty(property)) {
                var propArr = property.split(".");
                if(propArr.length === 1)    {
                    sett[propArr[0]] = {$set: object[property]};
                }
                if(propArr.length === 2)    {
                    if(sett[propArr[0]])        {
                        sett[propArr[0]][propArr[1]] = {$set: object[property]};
                    }
                    sett[propArr[0]] = {[propArr[1]]: {$set: object[property]}};
                }
                if(propArr.length === 3)    {
                    if(sett[propArr[0]])        {
                        sett[propArr[0]][propArr[1]] = {[propArr[2]]: {$set: object[property]}};
                    }
                    else        {
                        sett[propArr[0]] = {[propArr[1]]: {[propArr[2]]: {$set: object[property]}}};
                    }

                }
            }
        }
        return sett;
    };

    context.eStateSet = (e, object) => {
        e.preventDefault();
        context.stateSet(object);
    };

    for (var property in state)  {
        if (state.hasOwnProperty(property)) {
            if(property.startsWith('form'))     {

                for (var formProperty in state[property])      {
                    if (state[property].hasOwnProperty(formProperty)) {
                        state[property][formProperty].name = formProperty;
                        state[property][formProperty].error = '';

                        if(!state[property][formProperty].value)       {
                            var def = state[property][formProperty].default;
                            if(def === null)        {}
                            else if(def === 'undefined') def = undefined;
                            else if(!def)  def = '';
                            state[property][formProperty].value = def;
                        }
                        if(state[property][formProperty].validate)     {
                            state[property][formProperty].validate = state[property][formProperty].validate.split("|");
                        }
                        if(state[property][formProperty].type === 'select')  {
                            let p = formProperty;
                            state[property][formProperty].onChange = (val) => onChangeSelect(val, p);
                            continue;
                        }
                        state[property][formProperty].onChange = (e) => onChange(e);
                    }
                }
                state[property].onSubmit = onSubmit;
                state[property].error = '';
                state[property].success = '';
                state[property].loading = false;
            }
        }
    }
    return state;
}

export default bind;
