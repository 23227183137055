import React from 'react';
import api from "../../modules/api/request";
import {connect} from "react-redux";
import model from "../../modules/model/bind";
import {drawerChanged, toggleDrawer} from "../../store/actions/drawersActions";
import {Button, message, Modal, Select, Tabs, Timeline} from "antd";
import EditField from "../partials/EditField";
import Avatar from "../../layout/Avatar";
import moment from "moment";
import ItemsEdit from "../partials/ItemsEdit";
import Comments2 from "../partials/Comments2";
import pdf from "../../assets/images/pdf.png";
const { confirm } = Modal;
const format = 'MMM Do YYYY';
const sqlFrmat = 'YYYY-MM-DD';
const { TabPane } = Tabs;

class ContactShow extends React.Component   {

    state = model(this, {
        edit: this.props.edit
    });

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this contact?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.contactDelete, {data: {id: t.props.contact.id}}).then(function (response) {
                        setTimeout(resolve, 1);
                        message.success(`Contact deleted.`);
                        t.props.toggleDrawer({drawer: 'contact'});
                        t.props.drawerChanged('contact');
                    });

                });
            },
            onCancel() {},
        });
    };

    changePhoto = (e) => {
        e.preventDefault();
        this.props.toggleDrawer({drawer: 'files', callback: (files) => {
            var file = files[0];
            this.save('photo', file.id);
        }});
    };

    save = (field, value) => {
        let t = this;
        api.post(api.routes.contactUpdate, {id: this.props.contact.id, field: field, value: value}).then(function (response) {
            message.success(`Contact updated.`);
            t.props.drawerChanged('contact');
        });
    };

    saveItem = (form, table) => {
        var id = form.id;
        delete form.id;
        let t = this;
        api.post(api.routes.contactItemUpdate, {id: id, form: form, table: table, contact: this.props.contact.id}).then(function (response) {
            message.success(`Item updated.`);
            t.props.drawerChanged('contact');
        });
    };

    deleteItem = (id, table) => {
        let t = this;
        confirm({
            title: 'Do you want to delete this item?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.contactItemDelete, {data: {id: id, table: table}}).then(function (response) {
                        setTimeout(resolve, 10);
                        t.props.drawerChanged('contact');
                        message.success(`Item deleted.`);
                    });
                });
            },
            onCancel() {},
        });
    };

    notSet = (value) => {
        return <h4>{value ? value : <i> not set </i>}</h4>
    };

    pdf = () => {
        var contact = this.props.contact;
        let name = (contact.prefix || '') + ' ' + (contact.name || '') + ' ' + (contact.middlename || '') + ' ' + (contact.lastname || '');
        var structure = [
            {element: 'table', weight: 0.3, data: [
                    [{type: "text", value: "Name"}, {type: "text", value: name}],
                    [{type: "text", value: "Birthday"}, {type: "text", value: contact.birthday ? moment(contact.birthday).format(format) : ''}],
                    [{type: "text", value: "Religion"}, {type: "text", value: contact.religion ? contact.religion : ''}],
                    [{type: "text", value: "Role"}, {type: "text", value: contact.role ? contact.role : ''}],
                    [{type: "text", value: "Status"}, {type: "text", value: contact.status ? contact.status : ''}],
                    [{type: "text", value: "Company"}, {type: "text", value: contact.company ? contact.company.name : ''}],
                    [{type: "text", value: "Position (job title)"}, {type: "text", value: contact.position ? contact.position : ''}],
                    [{type: "text", value: "Notes"}, {type: "text", value: contact.notes ? contact.notes : ''}],
                    [{type: "text", value: "Responsible person"}, {type: "text", value: contact.user ? contact.user.name : ''}],
                    [{type: "text", value: "Street"}, {type: "text", value: contact.street ? contact.street : ''}],
                    [{type: "text", value: "City"}, {type: "text", value: contact.city ? contact.city : ''}],
                    [{type: "text", value: "Postal code"}, {type: "text", value: contact.postal ? contact.postal : ''}],
                    [{type: "text", value: "Country"}, {type: "text", value: contact.country ? contact.country.name : ''}],
                ]}
        ];
        var phones = [];
        contact.phones.map((item) => {
            var a = item.phone ? item.phone : '';
            var d = item.description ? item.description : '';
            phones.push([{type: "text", value: 'phone'}, {type: "text", value: a + "\n" +d}])
        });
        if(phones.length > 0)     {
            structure.push({element: 'table', header: 'Phones', data: phones});
        }
        var emails = [];
        contact.emails.map((item) => {
            var a = item.email ? item.email : '';
            var d = item.description ? item.description : '';
            emails.push([{type: "text", value: 'email'}, {type: "text", value: a + "\n" +d}])
        });
        if(emails.length > 0)     {
            structure.push({element: 'table', header: 'Emails', data: emails});
        }
        var networks = [];
        contact.networks.map((item) => {
            var a = item.name ? item.name : '';
            var d = item.link ? item.link : '';
            networks.push([{type: "text", value: 'network'}, {type: "text", value: a + "\n" +d}])
        });
        if(networks.length > 0)     {
            structure.push({element: 'table', header: 'Networks', data: networks});
        }

        this.props.toggleDrawer({drawer: 'pdf', title: contact.name, structure: structure})
    };

    render = () => {
        var contact = this.props.contact;
        var edit = this.props.user.privileges.includes(406) && this.state.edit;
        return <div>
            <div className="text-right">
                <Button className="strong" onClick={this.pdf}>
                    <img src={pdf} width={15} style={{marginTop: -4}} /> &nbsp; PDF
                </Button>
                &nbsp;
                <Button className="strong" onClick={() => this.stateSet({edit: !this.state.edit})}>
                    {edit ? 'CANCEL EDIT' : 'EDIT CONTACT'}
                </Button>
            </div>

            <div className="row marginless"  style={{position: 'relative'}} id="contactDrawerShow">
                <div className="col">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Info" key="1">
                            <div className="row marginless">
                                <div style={{width: 90}}>
                                    {edit
                                        ? <div>
                                            <a href="/" onClick={this.changePhoto}>
                                                <Avatar name={contact.name} avatar={contact.photo_name} size={80} />
                                            </a>
                                            <i style={{fontSize: 12}}>Click to change</i>
                                        </div>
                                        : <Avatar name={contact.name} avatar={contact.photo_name} size={80} />}
                                </div>
                                <div className="col">
                                    <EditField
                                        title="Verification"
                                        edit={edit}
                                        form={{type: "select", default: contact.verified}}
                                        field="switch"
                                        parent="contactDrawerShow"
                                        onSubmit={(value) => this.save('verified', value)}
                                        show={contact.verified ? <h4 className="green">Verified</h4> : <h4 className="red">Unverified</h4>} />

                                    <EditField
                                        title="Prefix"
                                        edit={edit}
                                        form={{type: "select", default: contact.prefix}}
                                        field="select"
                                        parent="contactDrawerShow"
                                        options={this.props.prefixes}
                                        onSubmit={(value) => this.save('prefix', value)}
                                        show={this.notSet(contact.prefix)} />

                                    <EditField
                                        title="Name"
                                        edit={edit}
                                        form={{validate: "required|max:191", default: contact.name}}
                                        field="input"
                                        onSubmit={(value) => this.save('name', value)}
                                        show={<h4>{contact.name}</h4>} />

                                    <EditField
                                        title="Middle Name"
                                        edit={edit}
                                        form={{validate: "required|max:191", default: contact.middlename}}
                                        field="input"
                                        onSubmit={(value) => this.save('middlename', value)}
                                        show={this.notSet(contact.middlename)} />

                                    <EditField
                                        title="Lastname"
                                        edit={edit}
                                        form={{validate: "required|max:191", default: contact.lastname}}
                                        field="input"
                                        onSubmit={(value) => this.save('lastname', value)}
                                        show={<h4>{contact.lastname}</h4>} />

                                    <EditField
                                        title="Birthday"
                                        edit={edit}
                                        form={{type: "select", default: contact.birthday ? moment(contact.birthday) : null}}
                                        field="date"
                                        onSubmit={(value) => this.save('birthday', value ? value.format(sqlFrmat) : value)}
                                        show={<h4>{contact.birthday ? moment(contact.birthday).format(format) : 'not set'}</h4>} />

                                    <EditField
                                        title="Religion"
                                        edit={edit}
                                        form={{type: "select", default: contact.religion}}
                                        field="select"
                                        options={this.props.religions}
                                        parent="contactDrawerShow"
                                        onSubmit={(value) => this.save('religion', value)}
                                        show={this.notSet(contact.religion)} />

                                    <EditField
                                        title="Role"
                                        edit={edit}
                                        form={{type: "select", default: contact.role}}
                                        field="select"
                                        options={this.props.roles}
                                        parent="contactDrawerShow"
                                        onSubmit={(value) => this.save('role', value)}
                                        show={this.notSet(contact.role)} />

                                    <EditField
                                        title="Status"
                                        edit={edit}
                                        form={{type: "select", default: contact.status}}
                                        field="select"
                                        options={['Regular', 'VIP']}
                                        parent="contactDrawerShow"
                                        onSubmit={(value) => this.save('status', value)}
                                        show={this.notSet(contact.status)} />

                                    <EditField
                                        title="Company"
                                        edit={edit}
                                        form={{type: "select", default: contact.company_id}}
                                        field="company"
                                        parent="contactDrawerShow"
                                        onSubmit={(value) => this.save('company_id', value)}
                                        show={contact.company
                                            ? <h4><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: contact.company.id})}>
                                                {contact.company.logo_name && <Avatar name={contact.company.name} avatar={contact.company.logo_name} />}
                                                {contact.company.name}</a>
                                            </h4>
                                            : <h4><i>not set</i></h4>}
                                    />

                                    <EditField
                                        title="Position (job title)"
                                        edit={edit}
                                        form={{validate: "required|max:191", default: contact.position}}
                                        field="input"
                                        onSubmit={(value) => this.save('position', value)}
                                        show={this.notSet(contact.position)} />

                                    <EditField
                                        title="General notes"
                                        edit={edit}
                                        form={{default: contact.notes}}
                                        field="editor"
                                        onSubmit={(value) => this.save('notes', value)}
                                        show={<div className="light_bcg p_15" dangerouslySetInnerHTML={{__html: contact.notes}}/>} />
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab={`Comments (${contact.comments.length})`} key="2">
                            <Comments2
                                privilege={true}
                                comments={contact.comments}
                                subject="contact"
                                id={contact.id}
                                sub={false} />
                        </TabPane>
                        <TabPane tab={`Visits (${contact.visits.length})`} key="3">
                            {contact.visits.length > 0
                               ? contact.visits.map((visit, index) => {
                                return <div key={index} className="dashboard_block mb_15 p_15">
                                    <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'visit', id: visit.id})}>
                                        <div>
                                            ARRIVAL DATE: <strong>{moment(visit.arrival_date).format(format)} {visit.arrival_time}</strong>
                                        </div>
                                        <div>
                                            ARRIVAL FROM: <strong> {visit.arrival_city} </strong>
                                        </div>
                                        <div>
                                            DEPARTURE DATE: <strong>{moment(visit.departure_date).format(format)} {visit.departure_time}</strong>
                                        </div>
                                        <div>
                                            DEPARTURE CITY: <strong> {visit.departure_city} </strong>
                                        </div>
                                    </a>
                                    <div> RESPONSIBLE PERSON: &nbsp;
                                        <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: visit.user.uid})}>
                                           <Avatar name={visit.user.name} avatar={visit.user.avatar_name} size="small"/>{visit.user.name}
                                        </a>
                                    </div>
                                </div>
                            })
                            : <i> no visits </i>}
                        </TabPane>
                        <TabPane tab={`Fairs (${contact.fairs.length})`} key="4">
                            {contact.fairs.length > 0
                                ? contact.fairs.map((fair, index) => {
                                    return <div key={index} className="dashboard_block mb_15 p_15">
                                      <div>
                                            FAIR NAME: <strong>{fair.fair_name}</strong>
                                        </div>
                                        <div className="pre_wrap">{fair.description}</div>
                                    </div>
                                })
                                : <i> no visited fairs </i>}
                        </TabPane>
                    </Tabs>
                </div>
                <div style={{width: 300}}>
                    <h3> Contacts: </h3>

                    <EditField
                        title="Responsible person"
                        edit={edit}
                        form={{type: 'select', default: contact.user ? contact.user.uid : []}}
                        field="persons"
                        onSubmit={(value) => this.save('responsible', value)}
                        parent="contactDrawerShow"
                        show={contact.user
                            ? <h3><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: contact.user.uid})}>
                                <Avatar name={contact.user.name} avatar={contact.user.avatar_name} />
                                {contact.user.name}</a></h3>
                            : <h3><i>not set</i></h3>}
                    />

                    <EditField
                        title="First contact"
                        edit={edit}
                        form={{type: "select", default: contact.contact}}
                        field="select"
                        options={this.props.contacts}
                        parent="contactDrawerShow"
                        onSubmit={(value) => this.save('contact', value)}
                        show={this.notSet(contact.contact)} />

                    <EditField
                        title="Street"
                        edit={edit}
                        form={{validate: "max:191", default: contact.street}}
                        field="input"
                        onSubmit={(value) => this.save('street', value)}
                        show={this.notSet(contact.street)} />

                    <EditField
                        title="City"
                        edit={edit}
                        form={{validate: "max:191", default: contact.city}}
                        field="input"
                        onSubmit={(value) => this.save('city', value)}
                        show={this.notSet(contact.city)} />

                    <EditField
                        title="Postal code"
                        edit={edit}
                        form={{validate: "max:191", default: contact.postal}}
                        field="input"
                        onSubmit={(value) => this.save('postal', value)}
                        show={this.notSet(contact.postal)} />

                    <EditField
                        title="Country"
                        edit={edit}
                        form={{type: "select", default: contact.country_id}}
                        field="country"
                        parent="contactDrawerShow"
                        onSubmit={(value) => this.save('country_id', value)}
                        show={contact.country
                            ? <h3><a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'country', id: contact.country.id})}>
                                {contact.country.flag_name && <Avatar name={contact.country.name} avatar={contact.country.flag_name} />}
                                {contact.country.name}
                            </a></h3>
                            : <h3><i>not set</i></h3>} />


                    <ItemsEdit
                        title="Emails"
                        edit={edit}
                        elements={[{name: 'email'}, {name: 'description'}]}
                        items={contact.emails}
                        save={(form) => this.saveItem(form, 'contacts_emails')}
                        delete={(id) => this.deleteItem(id, 'contacts_emails')} />

                    <ItemsEdit
                        title="Phones"
                        edit={edit}
                        elements={[{name: 'phone'}, {name: 'description'}]}
                        items={contact.phones}
                        save={(form) => this.saveItem(form, 'contacts_phones')}
                        delete={(id) => this.deleteItem(id, 'contacts_phones')} />

                    <ItemsEdit
                        title="Networks"
                        edit={edit}
                        elements={[{name: 'name'}, {name: 'link'}]}
                        items={contact.networks}
                        save={(form) => this.saveItem(form, 'contacts_networks')}
                        delete={(id) => this.deleteItem(id, 'contacts_networks')} />
                </div>

            </div>


            <div className="drawer_footer">
                {edit && <><Button className="strong" onClick={this.delete}>Delete contact</Button>&nbsp;</>}
                <Button className="strong" onClick={()=> this.props.toggleDrawer({drawer: 'contact'})}>Close</Button>
            </div>
        </div>
    };
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactShow);
