import React from 'react';
import {connect} from 'react-redux';
import {Button, Drawer, Input, Select, Skeleton, Spin} from "antd";
import api from "../../../modules/api/request";
import model from "../../../modules/model/bind"
import {toggleDrawer} from "../../../store/actions/drawersActions";
import CountriesTable from "./CountriesTable";
const { Option } = Select;

class Countries extends React.Component {

    state = model(this, {
        loading: true,
        status: false,
        data: [],
        filters: {regions: []},
        form: {
            search: {},
            region: {type: 'select', default: 'undefined'},
        }
    });

    filtered = () => {
        return this.state.data.filter((item) => {
            if(this.state.form.search.value)       {
                var name = item.name.toLowerCase();
                var search = this.state.form.search.value.toLowerCase();
                if(!name.includes(search))        {
                    return false;
                }
            }
            if(this.state.form.region.value)       {
                if(item.region !== this.state.form.region.value)     {
                    return false;
                }
            }
            return true;
        });
    };

    render = () => {
        return <div>
            <div className="dashboard_title">
                COUNTRIES
            </div>

            {this.props.user.privileges.includes(408) &&
                <div className="text-right mb_15">
                    <Button onClick={() => this.props.toggleDrawer({drawer: 'country'})} className="success" icon="plus">
                        ADD COUNTRY
                    </Button>
                </div>}



            <div className="dashboard_block">
                <div className="m_15">
                    <Input size="small" style={{ width: 200 }} {...this.state.form.search} placeholder="Search by name" />
                    &nbsp;
                    <Select placeholder="Region" style={{ width: 160 }} allowClear={true} {...this.state.form.region}>
                        <Option value={undefined}> All </Option>
                        {this.state.filters.regions.map((region, index) => {
                            return <Option key={index} value={region.name}> {region.name} ({region.count}) </Option>
                        })}
                    </Select>
                </div>

                {this.state.loading
                    ? <div className="m_15"><Skeleton active={true} /></div>
                    : <CountriesTable data={this.filtered()} /> }
            </div>
        </div>
    };

    load = () => {
        let t = this;
        t.stateSet({loading: true});
        api.get(api.routes.countries).then(function (response) {
            var regions = {};
            response.data.map((item) => {
                if(item.region)       {
                    if(!regions[item.region]) {
                        regions[item.region] ={name: item.region, count: 1};
                    }
                    else    regions[item.region].count ++;
                }
            });
            var filters = {regions: Object.values(regions)};
            t.stateSet({loading: false, status: t.props.drawer.status, filters: filters, data: response.data});
        });
    };

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.changed !== prevProps.drawer.changed)      {
            this.load();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.country
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Countries);
