import React from 'react';
import {connect} from 'react-redux';
import {Table} from "antd";
import {toggleDrawer} from "../../../store/actions/drawersActions";
import moment from "moment";
import Avatar from "../../../layout/Avatar";
const format = 'MMM Do YYYY';

class ReportsTable extends React.Component {

    columns = [
        {title: 'Name', dataIndex: 'name', key: 'name',
            render: (text, record) => {
                return <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'report', id: record.id})}>
                    {text}
                </a>
            }
        },

        {title: 'Participants', dataIndex: 'participants', key: 'participants',
            render: (text, record) => {
                return <div>
                    {record.participants.map((item) => {
                       return <div key={item.uid} style={{margin: '5px 0'}}>
                           <a href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'user', id: item.uid})}>
                               <Avatar avatar={item.avatar_name} name={item.name} size="small" /> {item.name}
                           </a>
                       </div>
                    })}
                </div>
            }},

        {title: 'Trip', dataIndex: 'trip', key: 'trip',
            render: (text, record) => {
                return <div>
                    <div>From:  <strong> {record.departing} </strong> </div>
                    To: <strong> {record.destination} </strong>
                </div>
            }},
        {title: 'Date', dataIndex: 'date', key: 'date',
            render: (text, record) => {
                var start = record.start ? moment.utc(record.start).format(format) : <i>not set</i>;
                var end = record.end ? moment.utc(record.end).format(format) : <i>not set</i>;
                return <div>
                    <div>From:  <strong> {start} </strong> </div>
                    To: <strong> {end} </strong>
                </div>
            }},

        {title: 'Companies', dataIndex: 'companies', key: 'companies',
            render: (text, record) => {
                if(!record.companies) return false;
                return <div>
                    {record.companies.map((item, index) => {
                        return  <div>
                            <a key={index} href="/" className="black" onClick={(e) => this.props.toggleDrawer({e: e, drawer: 'company', id: item.id})}>
                                {item.logo_name && <Avatar name={item.name} avatar={item.logo_name} />}
                            {item.name}</a>
                        </div>
                    })}
                </div>
            }},
    ];

    render = () => {
        return <Table
            className=""
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            rowKey="id"
            pagination={false} />
    };
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);
