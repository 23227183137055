import React from 'react';
import model from "../../modules/model/bind";
import {Alert, Button, Form, Input, message} from "antd";
import PersonTreeSelect from "../partials/PersonTreeSelect";
import api from "../../modules/api/request";
import {toggleDrawer, drawerChanged} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import FormEditor from "../../modules/editor/FormEditor";
const format = 'MMM Do YYYY';

class ProjectCreate extends React.Component   {

    state = model(this, {
        loading: false,
        form: {
            name: {validate: 'required|max:191'},
            description: {validate: 'max:2000'},
            owner: {type: 'select', default: [this.props.user.uid]},
            participants: {type: 'select', default: []},
            observers: {type: 'select', default: []},
        }
    });

    onSubmit = (form, model) => {
        let t = this;
        t.stateSet({loading: true});
        api.post(api.routes.projectInsert, form).then(function (response) {
            t.stateSet({loading: false});
            message.success(`Project created.`);
            t.props.drawerChanged('project');
            t.props.toggleDrawer({drawer: 'project'});
        }).catch(function (error) {
            t.stateSet({loading: false});
            model.error(error);
        });
    };

    setDescription = (value) => {
        this.stateSet({'form.description.value': value});
    };

    render = () => {
        var form = this.state.form;
        return <div>
            <Form {...{labelCol: {sm: { span: 24 }, md: { span: 4 }}, wrapperCol: {sm: { span: 24 }, md: { span: 14 }}}}
                  style={{position: "relative"}} id="projectsDrawerForm" onSubmit={form.onSubmit}>

                <div className="light_bcg p_15 mb_15">
                    <Form.Item label="Name" validateStatus={form.name.error && 'error'} help={form.name.error}>
                        <Input maxLength={191} type="text" {...form.name} autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="Description" validateStatus={form.description.error && 'error'} help={form.description.error}>
                        <FormEditor getValue={this.setDescription} />
                    </Form.Item>
                </div>

                <div className="p_15">
                    <Form.Item label="Created By" layout="horizontal">
                        <PersonTreeSelect field={form.owner} parent="projectsDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Participants" layout="horizontal">
                        <PersonTreeSelect field={form.participants} parent="projectsDrawerForm" />
                    </Form.Item>

                    <Form.Item label="Observers">
                        <PersonTreeSelect field={form.observers} parent="projectsDrawerForm" />
                    </Form.Item>

                    <Alert className={form.error ? 'show' : 'hide'} message={form.error} type="error" closable/>
                </div>
            </Form>
            <div className="drawer_footer">
                <Button className="strong" htmlType="submit" form="projectsDrawerForm" loading={this.state.loading}> Save project </Button> &nbsp;
                <Button className="strong" onClick={() => this.props.toggleDrawer({drawer: 'project'})}> Close </Button>
            </div>
        </div>
    };
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    toggleDrawer: toggleDrawer,
    drawerChanged: drawerChanged
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreate);
