import React from 'react';
import {Checkbox, message, Button, Modal, Spin, Switch} from "antd";
import privileges from "../../../store/privileges"
import api from "../../../modules/api/request";
import update from "react-addons-update";
import {updateUser} from "../../../store/actions/userActions";
import {connect} from "react-redux";
import model from "../../../modules/model/bind"
const { confirm } = Modal;

class EmployeesSettings extends React.Component {

    state = model(this, {
        loading: true,
        employee: {},
        privileges: []
    });

    savePrivileges = (e) => {
        let t = this;
        confirm({
            title: 'Update employee privileges?',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.put(api.routes.employeePrivileges, {uid: t.state.employee.uid, privileges: t.state.privileges}).then(function (response) {
                        message.success(`Privileges updated.`);
                        if(t.state.employee.uid === t.props.user.uid)       {
                            t.props.updateUser('privileges', t.state.privileges);
                        }
                        setTimeout(resolve, 1);
                    });
                });
            }
        });
    };

    delete = (e) => {
        let t = this;
        confirm({
            title: 'Are you sure you want to delete employee?',
            content: 'All data will be deleted, better use "deactivate" instead of deleting.',
            onOk() {
                return new Promise((resolve, reject) => {
                    api.delete(api.routes.employeeDelete, {data: {uid: t.state.employee.uid}}).then(function (response) {
                        message.success(`User deleted.`);
                        setTimeout(resolve, 1);
                        t.props.stateSetParent({uid: false});
                    });
                });
            }
        });
    };

    activate = (e, status) => {
        let t = this;
        var active = status ? 'activate' : 'deactivate';
        status = status ? 1 : null;
        confirm({
            title: `Are you sure you want to ${active} employee account?`,
            onOk() {
                return new Promise((resolve, reject) => {
                    api.post(api.routes.employeeActive, {uid: t.state.employee.uid, active: status}).then(function (response) {
                        message.success(`User ${active}d.`);
                        setTimeout(resolve, 1);
                        t.stateSet({'employee.active': status});
                    });
                });
            }
        });
    };

    toggleSwitch = (e, privileges) => {
        var arr = [], newState;
        privileges.map((item) => {
            arr.push(item.code);
        });
        if(e)        {
            newState = [...this.state.privileges, ...arr];
            const newData = update(this.state,{privileges: {$set: newState} });
            this.setState(newData);
        }
        else    {
            newState = this.state.privileges.filter(function(el) {
                return !arr.includes(el);
            } );
            const newData = update(this.state,{privileges: {$set: newState} });
            this.setState(newData);
        }
    };

    checked = (privileges) => {
        var checked = true;
        privileges.map((item) => {
            if(!this.state.privileges.includes(item.code))  {
                checked = false;
                return false;
            }
        });
        return checked;
    };

    render = () => {
        return  <Spin spinning={this.state.loading}>

            <h3>{this.state.employee.name}</h3>

            {/* TODO dodati position i department */}

            <Checkbox.Group style={{display: 'block'}} onChange={(value) => this.stateSet({privileges: value})} value={this.state.privileges}>
                {privileges.map((item) => {
                    return (item.privileges.length > 0)
                        && <div key={item.id} className="light_bcg p_15 mb_15">
                        <h3>
                            {item.page}
                            <Switch size="small"
                                    checked={this.checked(item.privileges)}
                                    className="pull-right"
                                    onChange={(e) => this.toggleSwitch(e, item.privileges)}>
                                toggle all
                            </Switch>
                        </h3>
                        {item.privileges.map((sub) => {
                            return <div key={sub.code}>
                                <Checkbox value={sub.code}> {sub.title} </Checkbox>
                            </div>
                        })}
                    </div>
                })}
            </Checkbox.Group>

            <br/>
            <br/>
            <br/>

            <div style={{position: "absolute", bottom: 0, padding: 10}}>
                <Button loading={this.state.privilegesLoading} className="success" onClick={this.savePrivileges}>
                    Save privileges
                </Button> &nbsp;
                <Button onClick={this.delete}>Delete account</Button> &nbsp;
                {this.state.employee.active
                    ?  <Button onClick={(e) => this.activate(e, false)}>Deactivate account</Button>
                    :  <Button onClick={(e) => this.activate(e, true)}>Activate account</Button>}
            </div>
        </Spin>

    };

    load = () => {
        this.stateSet({loading: true, employee: {}, privileges: []});
        let t = this;
        api.post(api.routes.employee, {uid: this.props.uid}).then(function (response) {
            t.stateSet({loading: false, employee: response.data.user, privileges: response.data.privileges});
        });
    };

    componentDidMount() {
        if(this.props.uid !== false)        {
            this.load();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.uid !== false && this.props.uid !== prevProps.uid)      {
            this.load();
        }
    }

}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    updateUser: updateUser
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeesSettings);
